{
  "common": {
    "resending": "Resending...",
    "loading": "Loading...",
    "loadingData": "Loading data...",
    "saving": "Saving...",
    "adding": "Adding...",
    "sending": "Sending...",
    "deleting": "Deleting...",
    "solving": "Solving...",
    "removing": "Removing...",
    "creating": "Creating...",
    "calling": "Calling...",
    "activating": "Activating...",
    "deactivating": "Deactivating...",
    "generating": "Generating...",
    "downloading": "Downloading...",
    "uploading": "Uploading...",
    "upgrading": "Upgrading...",
    "checking": "Checking...",
    "updating": "Updating...",
    "submitting": "Submitting...",
    "closing": "Closing...",
    "reopening": "Reopening...",
    "duplicating": "Duplicating...",
    "canceling": "Canceling...",
    "scheduling": "Scheduling...",
    "continuing": "Continuing...",
    "processing": "Processing...",
    "retrying": "Retrying...",
    "create": "Create",
    "submit": "Submit",
    "unknown": "Unknown",
    "tryAgain": "Try again",
    "notAvailable": "N/A",
    "never": "Never",
    "forever": "Forever",
    "Y": "Yes",
    "N": "No",
    "all": "All",
    "any": "Any",
    "add": "Add",
    "send": "Send",
    "sendMms": "Send MMS",
    "learnMore": "Learn more",
    "settings": "Settings",
    "watchTutorial": "Watch tutorial",
    "download": "Download",
    "downloadPdf": "Download PDF",
    "downloadFile": "Download file",
    "upload": "Upload",
    "rename": "Rename",
    "editName": "Edit name",
    "update": "Update",
    "viewDetails": "View details",
    "viewReport": "View report",
    "archive": "Archive",
    "archived": "Archived",
    "saveAsTemplate": "Save as template",
    "duplicate": "Duplicate",
    "cost": "Cost",
    "costWithColon": "Cost:",
    "csv": "Comma-separated values (.csv)",
    "xlsx": "Microsoft Excel workbook (.xlsx)",
    "expand": "Expand",
    "collapse": "Collapse",
    "status": "Status | Statuses",
    "phone": "Phone",
    "email": "Email",
    "supportEmailAddress": "support{'@'}textmagic.com",
    "phones": "Phones",
    "emails": "Emails",
    "save": "Save",
    "apply": "Apply",
    "discard": "Discard",
    "createAnother": "Add another after saving",
    "address": "Address",
    "country": "Country",
    "destinationCountry": "Destination country | Destination countries",
    "price": "Price",
    "minute": "Minute",
    "min": "min",
    "search": "Search",
    "searchFields": "Search fields",
    "showMore": "Show more",
    "hideMore": "Hide more",
    "showLess": "Show less",
    "hide": "Hide",
    "cancel": "Cancel",
    "back": "Back",
    "edit": "Edit",
    "or": "or",
    "delete": "Delete",
    "remove": "Remove",
    "solve": "Solve",
    "fileName": "File name",
    "fileSize": "File size",
    "deleteFile": "Delete file",
    "change": "Change",
    "noResults": "No results",
    "more": "More",
    "actions": "Actions",
    "success": "Success",
    "services": "services",
    "response": "response",
    "schedule": "Schedule",
    "clearAll": "Clear all",
    "selectAll": "Select all",
    "deselectAll": "Deselect all",
    "clearSelection": "Clear selection",
    "recipients": "Recipients",
    "recipientsWithColon": "Recipients:",
    "permissionDenied": "You are not allowed to perform this action. Please check your account permissions.",
    "pleaseRetryLaterOrContactSupport": "Please try again later or contact us at {supportEmail}.",
    "unexpectedPleaseRetryLaterOrContactSupport": "An unexpected error has been occurred. Please try again later or contact support.",
    "internalError": "Internal error",
    "confirmations": {
      "defaultTitle": "Are you sure?",
      "delete": "Do you want to delete {entity}?",
      "leavePageUploadingAttachments": "Some files are not finished uploading. If you continue, the upload will be canceled. Are you sure you want to continue?"
    },
    "sorting": {
      "asc": "Ascending",
      "desc": "Descending"
    },
    "from": "From",
    "to": "To",
    "preview": "Preview",
    "none": "None",
    "copy": "Copy",
    "copied": "Copied",
    "filters": "Filters",
    "import": "Import",
    "logout": "Log out",
    "block": "Block",
    "unblock": "Unblock",
    "close": "Close",
    "addBlockedNumber": "Add blocked number",
    "viewContact": "View contact",
    "addToContacts": "Add to contacts",
    "deleteCall": "Delete call",
    "sendSMS": "Send SMS",
    "disabled": "Disabled",
    "enabled": "Enabled",
    "sharedBy": "Shared by",
    "verified": "Verified",
    "notVerified": "Not verified",
    "verify": "Verify",
    "verifyNow": "Verify now",
    "verifying": "Verifying",
    "addNumber": "Add number",
    "12hFormat": "12-hour clock",
    "24hFormat": "24-hour clock",
    "on": "On",
    "off": "Off",
    "buyNumber": "Buy number",
    "history": "History",
    "addCustomField": "Add new custom field",
    "browseFiles": "Browse files",
    "browseFilesWithDots": "Browse files...",
    "completeWithDots": "complete...",
    "sorryTheLinkToThisAudioIsIncorrect": "Sorry, the link to this audio is incorrect.",
    "pause": "Pause",
    "resume": "Resume",
    "continue": "Continue",
    "next": "Next",
    "previous": "Previous",
    "outOf": "{count} out of {total}",
    "saveChanges": "Save changes",
    "discardChanges": "Discard changes",
    "resent": "Resent",
    "resend": "Resend",
    "emoji": "Emoji",
    "pickYourEmoji": "Pick your emoji",
    "dateCreated": "Date created",
    "lastUpdated": "Last updated",
    "createdBy": "Created by",
    "can": "Can",
    "cannot": "Cannot",
    "reset": "Reset",
    "valid": "Valid",
    "invalid": "Invalid",
    "finish": "Finish",
    "move": "Move",
    "moving": "Moving...",
    "copying": "Copying...",
    "person": "{value} person | {value} people",
    "active": "Active",
    "inactive": "Inactive",
    "waitingTime": "Waiting time",
    "show": "Show",
    "decline": "Decline",
    "accept": "Accept",
    "profile": "Profile",
    "deactivate": "Deactivate",
    "pleaseWait": "Please wait...",
    "reopen": "Reopen",
    "saveAndContinue": "Save and continue",
    "who": "who",
    "what": "what",
    "monthly": "monthly",
    "quarterly": "quarterly",
    "how": "how",
    "field": "Field",
    "description": "Description",
    "documentation": "Documentation",
    "createdAt": "Created at",
    "totalCost": "Total cost",
    "networkError": "Network error. Could not connect to the server!",
    "ellipsis": "…",
    "goBack": "Go back",
    "password": "Password",
    "helpAndSupport": "Help & support",
    "whatsNew": "What’s new",
    "soon": "soon",
    "comingSoon": "Coming soon",
    "beta": "BETA",
    "insert": "Insert",
    "copyText": "Copy text",
    "link": "Link",
    "retry": "Retry",
    "shareWithSubAccounts": "Share with sub-accounts",
    "viewChat": "View chat",
    "chat": "Chat",
    "newSms": "New SMS",
    "backToApp": "Back to Textmagic",
    "saved": "Saved",
    "leavePageUnsavedChanges": "You have unsaved changes. If you continue, your changes will be discarded. Are you sure you want to continue?",
    "getStarted": "Get started",
    "default": "Default",
    "contactUs": "Contact us",
    "contactUsInline": "contact us",
    "contactSupport": "Contact support",
    "contactSupportInline": "contact support",
    "textmagic": "Textmagic",
    "clear": "Clear",
    "allSettings": "All settings",
    "replace": "Replace",
    "copyLink": "Copy link",
    "openInNewTab": "Open in new tab",
    "added": "Added",
    "addedOn": "Added on",
    "viewMore": "View more",
    "required": "Required",
    "received": "Received",
    "pending": "Pending",
    "call": "Call",
    "details": "Details",
    "buyCredit": "Buy credit",
    "unlink": "Unlink",
    "free": "Free",
    "andTemplate": "{first} and {second}",
    "orTemplate": "{first} or {second}",
    "sms": "SMS",
    "senderId": "Sender ID",
    "restore": "Restore",
    "manage": "Manage",
    "downgrade": "Downgrade",
    "upgrade": "Upgrade",
    "videoTutorial": "video tutorial",
    "supportArticle": "support article",
    "dropFilesHere": "Drop files here",
    "and": "and",
    "addAnother": "Add another",
    "url": "URL",
    "you": "You",
    "addFile": "Add file",
    "channels": "Channels",
    "new": "New",
    "reconnect": "Reconnect",
    "reload": "Reload",
    "reloading": "Reloading...",
    "appliedFilter": "{count} applied filter | {count} applied filters",
    "system": "System",
    "unset": "Unset",
    "custom": "Custom",
    "undo": "Undo",
    "redo": "Redo",
    "refreshing": "Refreshing..."
  },
  "units": {
    "datetime": {
      "second": "second | seconds",
      "minute": "minute | minutes",
      "hour": "hour | hours",
      "day": "day | days",
      "week": "week | weeks",
      "month": "month | months",
      "quarter": "quarter | quarters",
      "year": "year | years",
      "period": {
        "days": "{count} day | {count} days",
        "hours": "{count} hour | {count} hours",
        "minutes": "{count} minute | {count} minutes",
        "weeks": "{count} week | {count} weeks",
        "months": "{count} month | {count} months"
      },
      "ranges": {
        "ALL_TIME": "All time",
        "LAST": "In the last",
        "NEXT": "Due in next",
        "CUSTOM": "Custom range",
        "THIS_DAY": "Today",
        "LAST_DAY": "Yesterday",
        "LAST_7_DAYS": "Last 7 days",
        "LAST_30_DAYS": "Last 30 days",
        "LAST_90_DAYS": "Last 90 days",
        "THIS_MONTH": "This month",
        "LAST_MONTH": "Last month",
        "LAST_THREE_MONTH": "Last three month",
        "THIS_YEAR": "This year",
        "LAST_YEAR": "Last year",
        "NOW_OVERDUE": "Now overdue",
        "NO_DATE": "No due date",
        "TIME_FROM_NOW": "Time from now",
        "overdueMoreThen": "Overdue more than",
        "from": "From",
        "to": "To"
      },
      "holiday": "no holidays | {count} holiday | {count} holidays",
      "timeFormat": {
        "h": "12 hours",
        "H": "24 hours"
      },
      "hoursIntervalType": {
        "am": "AM",
        "pm": "PM"
      },
      "utc": "UTC",
      "weekDays": {
        "1": "Monday",
        "2": "Tuesday",
        "3": "Wednesday",
        "4": "Thursday",
        "5": "Friday",
        "6": "Saturday",
        "7": "Sunday"
      },
      "weekday": "weekday",
      "weekend": "weekend day",
      "weekDaysShort": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      "months": {
        "1": "January",
        "2": "February",
        "3": "March",
        "4": "April",
        "5": "May",
        "6": "June",
        "7": "July",
        "8": "August",
        "9": "September",
        "10": "October",
        "11": "November",
        "12": "December"
      },
      "monthsShort": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      "from": "from",
      "till": "till",
      "dateCannotBeLessThen": "\"To\" date cannot be less than \"from\" date",
      "dateCannotBeMoreThen": "\"To\" date cannot be more than current date",
      "formatDistance": {
        "lessThanXSeconds": {
          "one": "less than a second",
          "other": "less than {count} seconds"
        },
        "xSeconds": {
          "one": "1 second",
          "other": "{count} seconds"
        },
        "halfAMinute": "half a minute",
        "lessThanXMinutes": {
          "one": "less than a minute",
          "other": "less than {count} minutes"
        },
        "xMinutes": {
          "one": "1 minute",
          "oneShort": "1 min",
          "other": "{count} minutes",
          "otherShort": "{count} min"
        },
        "aboutXHours": {
          "one": "about 1 hour",
          "other": "about {count} hours"
        },
        "xHours": {
          "one": "1 hour",
          "oneShort": "1 h",
          "other": "{count} hours",
          "otherShort": "{count} h"
        },
        "xDays": {
          "one": "1 day",
          "other": "{count} days"
        },
        "aboutXWeeks": {
          "one": "about 1 week",
          "other": "about {count} weeks"
        },
        "xWeeks": {
          "one": "1 week",
          "other": "{count} weeks"
        },
        "aboutXMonths": {
          "one": "about 1 month",
          "other": "about {count} months"
        },
        "xMonths": {
          "one": "1 month",
          "other": "{count} months"
        },
        "aboutXYears": {
          "one": "about 1 year",
          "other": "about {count} years"
        },
        "xYears": {
          "one": "1 year",
          "other": "{count} years"
        },
        "overXYears": {
          "one": "over 1 year",
          "other": "over {count} years"
        },
        "almostXYears": {
          "one": "almost 1 year",
          "other": "almost {count} years"
        }
      }
    },
    "message": "message|messages",
    "recipient": "recipient|recipients",
    "row": "row|rows",
    "px": "px"
  },
  "status": {
    "undefined": "Undefined",
    "unknown": "Unknown",
    "user": {
      "closed": "Closed",
      "active": "Active",
      "trial": "Trial",
      "subInvited": "Invited",
      "confirm": "Invited",
      "oauthRequest": "Oauth registration request",
      "suspend": "Suspended",
      "pfraud": "Possible fraud",
      "fraud": "Fraud",
      "manualApprove": "Waiting for manual activation",
      "wizard": "Wizard",
      "personalUse": "Personal use",
      "neverLoggedIn": "Never logged in"
    },
    "account": {
      "user": "User",
      "admin": "Admin",
      "superAdmin": "Parent superuser",
      "accountOwner": "Account owner"
    },
    "invoice": {
      "paid": "Paid",
      "pending": "Pending"
    },
    "sms": {
      "none": "None",
      "queue": "Queue",
      "scheduledQueue": "Scheduled queue",
      "sendingError": "Error",
      "enroute": "Enroute",
      "acked": "Acked",
      "delivered": "Delivered",
      "buffered": "Buffered",
      "failed": "Failed",
      "unknown": "Unknown",
      "rejected": "Rejected",
      "bulkInsert": "Bulk insert",
      "scheduledSuspend": "Scheduled suspend",
      "queueSuspend": "Queue suspend",
      "bulkDelivered": "{percent} delivered",
      "sent": "Sent",
      "read": "Read"
    },
    "session": {
      "none": "None",
      "email": "Email to SMS",
      "e2s": "E2S",
      "webApp": "Web App",
      "messenger": "Textmagic Messenger",
      "list": "Distribution List",
      "api": "API",
      "widget": "Widget",
      "mobile": "Mobile",
      "automation": "Automation rules",
      "iosMobile": "iOS App",
      "androidMobile": "Android App",
      "smsChat": "SMS Chat",
      "survey": "SMS Survey"
    },
    "smsRejection": {
      "byocFreeTrialHasExpired": "Your BYOC (Bring your own CPaaS) free trial has expired. Please {chooseAPlan} to continue using this service.",
      "byocNumberNotSupportSms": {
        "text": "The BYOC number you selected doesn't support SMS. Please {connect} a different number with SMS capabilities to send messages.",
        "connect": "connect"
      },
      "byocNumberDeactivated": "The sender's number was deactivated before the message was sent.",
      "chooseAPlan": "choose a plan",
      "missingNumber": "To activate sending to this country please {buyNumber}.",
      "failedNumber": "To activate sending to this country please {buyNumber}.",
      "contactUnsubscribed": "This contact has unsubscribed from your lists.",
      "contactBlocked": "This contact has been blocked.",
      "disabledVoiceNumber": "Voice message must be sent from a voice enabled number.",
      "suspiciousSession": "Suspicious session.",
      "restrictedCountry": "Your account does not have permission to send messages to this country. Please {contactSupport} for more details.",
      "supplierRestriction": "The message was rejected by the supplier. Please {contactSupport} for more information.",
      "mmsRestriction": "MMS message must be sent from a USA toll-free number.",
      "invalidNumber": "The recipient number is invalid or missing",
      "sendingDisabledOnAccount": "Message rejected by Textmagic. Please {contactSupport} for more details.",
      "smsSurveyRestriction": "The SMS Survey feature is not supported in this country.",
      "urlShortener": "Messages that contain publicly available URL shorteners are rejected. Please avoid using short links in your messages.",
      "undetected": "Unknown reject reason. Please {contactSupport}.",
      "tollFreeUnverified": "Please {complete} to send messages from this number.",
      "tollFreeUnverifiedComplete": "complete the toll-free verification",
      "regultionAu": "Messages to Australia can only be sent from an Australian {dedicateVirtualNumber} or an alphanumeric {senderId}. Please check your {senderSettings} and try again.",
      "regultionGb": "Messages to the United Kingdom can only be sent from a UK {dedicateVirtualNumber} or an alphanumeric {senderId}. Please check your {senderSettings} and try again.",
      "tenDlcRequired": "Please {complete} to send messages from this number.",
      "completeTenDLc": "complete the 10DLC registration",
      "dedicateVirtualNumber": "dedicated virtual number",
      "senderId": "Sender ID",
      "senderSettings": "sender settings",
      "buyNumber": "buy a number",
      "cpaasAuthIssue": "There is an authorization issue with your connected CPaaS provider. Please {update} the provider’s login credentials.",
      "updateCpass": "update",
      "cpaasPhoneIssue": "The linked number is unavailable for use in Textmagic. Please check with {yourProvider} to resolve any issues.",
      "yourProvider": "your provider",
      "connectProvider": "To activate sending to this country, please {action} using the BYOC feature.",
      "connectProviderAction": "connect a CPaaS provider",
      "mmsFormatNotSupported": "The file format in the message is not supported.",
      "byocProviderReason": {
        "text": "The {label} CPaaS provider has rejected your message. Please check the {logs} on their end.",
        "logs": "log details"
      },
      "support": "Please contact support at {supportEmail}"
    },
    "automationRule": {
      "completed": "Completed",
      "lowBalance": "Low Balance",
      "error": "Error"
    },
    "tenDlcPhoneNumber": {
      "approved": "approved",
      "pending": "pending",
      "declined": "declined",
      "failed": "failed"
    },
    "tenDlcCampaigns": {
      "draft": "draft",
      "pending": "pending",
      "inReview": "in review",
      "actionRequired": "action required",
      "active": "active",
      "pendingCancellation": "pending cancellation",
      "canceled": "canceled",
      "suspended": "suspended",
      "terminated": "terminated"
    },
    "tenDlcBrandStatus": {
      "draft": "draft",
      "pending": "pending",
      "inReview": "in review",
      "verified": "verified",
      "actionRequired": "action required"
    }
  },
  "currency": {
    "USD": "USD",
    "EUR": "EUR"
  },
  "pageTabTitle": {
    "withoutColon": "Textmagic",
    "withColon": "{title} - Textmagic",
    "user": {
      "gettingStarted": "Getting started",
      "compose": {
        "compose": "Compose",
        "message": "New text message",
        "campaign": "New text message",
        "email": "New email",
        "sentSmsDetails": "Outbound SMS: {id}",
        "sentSmsBulkDetailsSummary": "SMS session: {id}",
        "scheduledDetails": "Scheduled message: {id}"
      },
      "contacts": {
        "contacts": "Contacts",
        "imports": "Imports",
        "import": "Import contacts",
        "importDetails": "Import results",
        "blocked": "Blocked",
        "unsubscribed": "Unsubscribed",
        "lists": "Lists",
        "settings": {
          "all": "Contacts settings",
          "unsubscribe-rules": "Unsubscribe rules",
          "sharing": "Contacts sharing",
          "custom-fields": "Custom fields",
          "navigation": "Navigation"
        }
      },
      "history": {
        "history": "History",
        "messageDetails": "Message details",
        "sentSms": "Outbound SMS",
        "sentSmsDetails": "Outbound SMS: {sentSmsId}",
        "sentSmsBulkDetailsSummary": "SMS session: {sessionId}",
        "receivedSms": "Inbound SMS",
        "receivedSmsDetails": "Inbound SMS: {receivedSmsId}",
        "forwardedCalls": "Forwarded calls",
        "outboundCalls": "Outbound calls",
        "inboundCalls": "Inbound calls",
        "inboundCallDetails": "Inbound call: {inboundCallId}",
        "outboundCallDetails": "Outbound call: {outboundCallId}",
        "forwardedCallDetails": "Forwarded call: {forwardedCallId}",
        "outboundEmail": "Outbound email: {id}"
      },
      "templates": "Templates",
      "services": {
        "tenDlcCampaignDetails": "10DLC: Campaign details",
        "forms": {
          "forms": "Forms"
        },
        "downloads": "Downloads",
        "buyNumber": {
          "number": "Buy a virtual number",
          "callForwarding": "Call forwarding: {phone}",
          "callForwardingNoPhone": "Call forwarding"
        }
      },
      "accounts": {
        "invoices": "Invoices",
        "subAccounts": {
          "active": "Sub-accounts: Active",
          "closed": "Sub-accounts: Closed",
          "import": "Sub-accounts: Import history",
          "importDetails": "Sub-accounts import results",
          "importFlow": "Import sub-accounts"
        }
      },
      "campaigns": {
        "all": {
          "tabs": "All campaigns"
        },
        "sms": {
          "details": "Campaign details"
        },
        "scheduled": {
          "upcoming": "Upcoming campaigns",
          "paused": "Paused campaigns",
          "notSent": "Not sent campaigns",
          "completed": "Completed campaigns"
        }
      }
    },
    "wizard": {
      "base": "How are you planning to use Textmagic?"
    },
    "auth": {
      "login": "Log in to continue",
      "logout": "Logging out...",
      "signup": "Create a free account",
      "signupUnavailable": "Sign up unavailable",
      "loginPersonal": "Free trial not available",
      "accountSuspended": "Account suspended",
      "accountClosed": "Account deleted by owner",
      "signupConfirmRegistration": "Verifying...",
      "signupConfirmEmail": "Verifying...",
      "forgotPassword": "Forgot password",
      "verifyEmail": "Verify your email",
      "resetPassword": "Reset password",
      "emailCode": "Enter 2FA code",
      "smsCode": "Enter 2FA code",
      "invite": "Activate your account"
    },
    "payment": "Buy SMS credit",
    "planExpired": "Your Team plan has expired",
    "tokenIsLocked": "Too many active sessions"
  },
  "pageTitle": {
    "user": {
      "gettingStarted": "Getting started",
      "chats": "Messenger",
      "chatsSettings": {
        "webWidgets": "Chat widget"
      },
      "compose": {
        "compose": "Compose"
      },
      "campaigns": {
        "campaigns": "Campaigns",
        "compose": "Compose",
        "allCampaigns": "All campaigns",
        "sent": "Sent",
        "scheduled": {
          "scheduled": "Scheduled"
        },
        "archived": "Archived",
        "templates": "Templates",
        "calendar": "Calendar",
        "surveys": "Surveys",
        "history": "History",
        "allTemplates": "All templates"
      },
      "contacts": {
        "contacts": "Contacts",
        "lists": "Lists",
        "myContacts": "My contacts",
        "allContacts": "All contacts",
        "import": "Import contacts",
        "imports": "Imports",
        "blocked": "Blocked",
        "settings": {
          "title": "Contacts settings",
          "settings": "Settings",
          "all": {
            "allSettings": "All settings",
            "unsubscribeRules": {
              "title": "Unsubscribe rules",
              "description": "Manage contacts’ opt out settings, such as “opt-out confirmation”, “auto-unsubscribe” settings, etc."
            },
            "sharing": {
              "title": "Contacts sharing",
              "description": "Manage shared contacts visibility in the “Contacts” table."
            },
            "navMenu": {
              "title": "Navigation menu",
              "description": "Set up the navigation preferences of the Contacts section (default page, page order in the navigation menu)."
            },
            "customFields": {
              "title": "Custom fields",
              "description": "Manage contacts’ data fields available for import and editing in the contact profile view.",
              "sidebarMenu": "Sidebar menu",
              "dndTitle": "Drag & drop sidebar menu order",
              "dndText": "The first item will always open by default when you navigate to the Contacts section.",
              "sidebarMenuPreview": "Sidebar menu preview"
            }
          },
          "unsubscribeRules": {
            "title": "Unsubscribe rules",
            "successful": "The unsubscribe settings have been successfully updated.",
            "defaultReplyMessage": "Thank you - You have been opted out. Text JOIN to opt-in.",
            "alert": {
              "pleaseNote": "Please note:",
              "message": "Unsubscribe settings are shared and affect all your sub-accounts.",
              "messageForUsers": "Unsubscribe settings are shared and affect all your users."
            },
            "optOutConfirmation": {
              "label": "Opt-out confirmation",
              "description": "A confirmation text message will be sent to the number that has unsubscribed. Every message costs credit.",
              "manualLabel": "Manual unsubscribes",
              "manualDescription": "Also send opt-out confirmation when manually unsubscribing a contact.",
              "messageTemplate": "Message template"
            },
            "autoUnsubscribe": {
              "label": "Auto-unsubscribe",
              "description": "Automatically unsubscribe numbers where a network operator was not able to deliver your messages and has returned a \"Failed\" status. You can manually resubscribe these numbers later.",
              "unsubscribeAfter": "Unsubscribe after",
              "option": "{count} outbound message with status \"Failed\" | {count} outbound messages with status \"Failed\""
            },
            "smsChatSettings": {
              "label": "SMS chat settings",
              "closeChat": "Mark chats as solved when unsubscribed",
              "leaveChat": "Leave chats open when unsubscribed",
              "description": "When a contact is unsubscribed, the chat is left open by default and needs to be closed manually later."
            }
          },
          "sharing": {
            "title": "Contacts sharing",
            "sectionTitle": "Shared contacts settings",
            "onlyMyContacts": "Only my contacts",
            "onlyMyContactsDescription": "This page will only display the contacts that belong to your account.",
            "myContactsAndShared": "My contacts and \"shared to me\" contacts",
            "myContactsAndSharedDescription": "This page will display your contacts and the contacts that have been shared to you by sub-accounts.",
            "contactsPageDisplays": "Contacts page displays",
            "successNotification": "The contacts sharing settings have been successfully saved."
          },
          "customFields": {
            "title": "Custom fields"
          },
          "navigationMenu": {
            "title": "Navigation",
            "successNotification": "The contacts navigation settings have been successfully saved."
          }
        }
      },
      "scheduled": {
        "scheduled": "Scheduled",
        "upcoming": "Upcoming",
        "paused": "Paused",
        "notSent": "Not sent",
        "completed": "Completed",
        "calendar": "Calendar",
        "details": "{which} message details",
        "edit": "Scheduled messages"
      },
      "history": {
        "history": "History",
        "sentSms": "Outbound SMS",
        "sentSmsDetails": "Outbound SMS details",
        "receivedSms": "Inbound SMS",
        "receivedSmsDetails": "Inbound SMS details",
        "forwardedCalls": "Forwarded calls",
        "outboundCalls": "Outbound calls",
        "inboundCalls": "Inbound calls",
        "outboundEmails": "Outbound emails",
        "outboundEmailsDetails": "Outbound email details",
        "inboundCallDetails": "Inbound call details",
        "outboundCallDetails": "Outbound call details",
        "forwardedCallDetails": "Forwarded call details"
      },
      "templates": "Templates",
      "allTemplates": "All templates",
      "services": {
        "services": "Services",
        "senderSettings": "Sender settings",
        "emailToSms": "Email to SMS",
        "lookup": "Lookup",
        "automationRules": {
          "title": "Automation rules",
          "index": "Automation rules",
          "new": "Create a new rule",
          "edit": "Edit rule"
        },
        "smsSurveys": "SMS Surveys",
        "forms": {
          "forms": "Forms"
        },
        "downloads": "Downloads",
        "carrierLookup": {
          "title": "Carrier lookup",
          "flow": "Bulk carrier lookup"
        },
        "emailLookup": {
          "title": "Email lookup",
          "flow": "Bulk email lookup"
        },
        "byoc": "Bring your own CPaaS",
        "pointAi": "Point AI"
      },
      "accounts": {
        "accounts": "Account",
        "admin": "Admin",
        "newBilling": {
          "title": "Billing overview"
        },
        "billing": "Billing",
        "users": "Users",
        "monthlyPlans": "Plans",
        "invoices": "Invoices",
        "subAccounts": {
          "title": "Sub-accounts",
          "importFlow": "Import sub-accounts"
        },
        "userWorkflow": {
          "title": "Users",
          "active": {
            "title": "Users: Active"
          },
          "closed": {
            "title": "Users: Closed"
          },
          "invited": {
            "title": "Users: Invited"
          }
        },
        "notifications": "Notifications",
        "dataRetention": "Data retention",
        "statements": "Statements",
        "billingOverview": "Billing overview",
        "workspace": "Workspace",
        "personal": "Personal",
        "details": "My account",
        "sso": "Single Sign-On (SSO)",
        "security": "Security",
        "twoFactorAuthentication": "Set up two-factor authentication"
      },
      "reporting": {
        "reporting": "Reports",
        "overview": "Overview",
        "messages": "Messages",
        "calls": "Calls",
        "auditLogs": "Audit logs",
        "subAccounts": "Sub-accounts",
        "users": "Users",
        "numbers": "Numbers"
      },
      "tasks": {
        "tasks": "Tasks",
        "pipelines": "Pipelines",
        "settings": "Tasks settings"
      },
      "tickets": {
        "tickets": "Tickets"
      },
      "deals": {
        "deals": "Deals",
        "settings": "Deals settings"
      }
    }
  },
  "entities": {
    "segments": {
      "addSegmentsWithCount": "Add {count} segments | Add {count} segment | Add {count} segments",
      "searchPlaceholder": "Search segments"
    },
    "filteredView": {
      "name": "filtered view | filtered views"
    },
    "reportingNumber": {
      "name": "report | reports"
    },
    "loginHistory": {
      "name": "session | sessions"
    },
    "unsubscribedContact": {
      "menuTitle": "Unsubscribed",
      "name": "contact | contacts",
      "searchPlaceholder": "Search contacts"
    },
    "users": {
      "name": "user | users",
      "searchPlaceholder": "Search sub-accounts"
    },
    "contactList": {
      "name": "list | lists",
      "title": "Lists",
      "private": "Private",
      "filterLists": "Filter lists",
      "sharedToMe": "Shared to me",
      "sharedByMe": "Shared by me",
      "sendSMSToList": "Send SMS to this list",
      "makeListPrivate": "Make this list private",
      "shareThisList": "Share this list with sub-accounts",
      "hideList": "Hide list",
      "editList": "Edit list",
      "deleteList": "Delete list",
      "newList": "New list",
      "newContact": "New contact",
      "visible": "visible",
      "hidden": "hidden",
      "hide": "Hide",
      "unhide": "Unhide",
      "unhideAll": "Unhide all",
      "visiblityChangeSuccessMessage": "The selected lists have been successfully updated.",
      "membersCountText": "0 contacts | {countText} contact | {countText} contacts",
      "searchPlaceholder": "Search lists"
    },
    "contacts": {
      "name": "contact | contacts",
      "count": "{count} contact | {count} contacts",
      "viewTitle": "View contact",
      "editTitle": "Edit contact",
      "addListsWithCount": "Add {count} lists | Add {count} list | Add {count} lists",
      "addNewContact": "Add new contact",
      "importContacts": "Import contacts",
      "imports": "Imports",
      "import": {
        "statusFailed": "Error",
        "statusSuccess": "Completed",
        "statusPreparing": "Preparing"
      },
      "fields": {
        "firstName": "First name",
        "firstNamePlaceholder": "Enter first name",
        "lastNamePlaceholder": "Enter last name",
        "lastName": "Last name",
        "lists": "Lists",
        "email": "Email address",
        "phone": "Phone number",
        "whatsappPhone": "WhatsApp number",
        "company": "Company",
        "emailPlaceholder": "Enter email address",
        "companyPlaceholder": "Enter company",
        "phonePlaceholder": "Enter phone",
        "whatsappPhonePlaceholder": "Enter WhatsApp phone",
        "whatsappPhoneActivator": "Connect WhatsApp",
        "ownerId": "Contact owner",
        "tagIds": "Tags",
        "tagIdsPlaceholder": "Add Tags"
      },
      "addContact": "Add contact",
      "saveChanges": "Save changes",
      "myContacts": "My contacts",
      "allContacts": "All contacts",
      "customFields": "Custom fields",
      "blockedContacts": "Blocked",
      "addNumberContact": "Add 0 contacts | Add {count} contact | Add {count} contacts",
      "searchLists": "Search lists",
      "createNewList": "Create new list",
      "customFieldPlaceholder": "Enter {name}",
      "searchPlaceholder": "Search contacts",
      "deleteContact": "Delete contact",
      "showCustomFields": {
        "show": "Show {customFields}",
        "customFields": "{count} more field | {count} more fields"
      },
      "validation": {
        "create": {
          "atLeastOneRequired": "Please fill more contact details to add this contact."
        },
        "update": {
          "atLeastOneRequired": "Please fill more contact details to update this contact."
        }
      },
      "updateWhatsAppNumber": "Update WhatsApp number",
      "ifYouUpdateTheWhatsAppNumber": "<p>If you update the WhatsApp number for <b>{name}</b>, the current chat will be unlinked from this contact and remain in Messenger without a linked contact.</p><p>The new WhatsApp number will be assigned to <b>{name}</b>, but the contact needs to initiate a new WhatsApp conversation from the updated number before you can message them.</p>Are you sure you want to update this contact’s WhatsApp number?"
    },
    "contactImport": {
      "name": "file | files",
      "importedCountText": "0 contacts | {count} contact | {count} contacts",
      "editAndImport": "Edit and import",
      "searchPlaceholder": "Search imported files"
    },
    "contactImportResult": {
      "name": "contact | contacts"
    },
    "blockedContacts": {
      "name": "contact | contacts",
      "searchPlaceholder": "Search blocked contacts"
    },
    "customFields": {
      "name": "custom field |  fields",
      "editTitle": "Edit custom field",
      "newTitle": "New custom field",
      "addOption": "Add option",
      "fieldTypeLabels": {
        "text": "Text",
        "checkbox": "Checkbox",
        "dropdown": "Drop-down list",
        "datetime": "Date",
        "number": "Number"
      },
      "searchPlaceholder": "Search custom fields"
    },
    "imports": {
      "name": "import | imports",
      "newTitle": "New import",
      "tabs": {
        "imported": "Imported",
        "duplicates": "Duplicates excluded",
        "failed": "Failed to import",
        "excluded": "Landlines excluded"
      },
      "downloadOriginalFile": "Download original file",
      "downloadLookupFile": "Download lookup file"
    },
    "baseModel": {
      "fields": {
        "createdAt": "Created at",
        "updatedAt": "Updated at"
      }
    },
    "sentSms": {
      "name": "message | messages",
      "sendTime": "Send time",
      "messageContent": "Message content",
      "sendReply": "Send reply",
      "messageId": "Message ID",
      "editAndResend": "Edit SMS and resend",
      "source": "Source",
      "totalCharacters": "Total characters",
      "charactersNumber": "{number} character | {number} characters",
      "messageParts": "Message parts",
      "messagePartsNumber": "{number} part | {number} parts",
      "recipientsNumber": "{number} recipient | {number} recipients",
      "encoding": "Encoding",
      "unicode": "Unicode",
      "plainText": "Plain text",
      "searchPlaceholder": "Search messages"
    },
    "sentSmsShort": {
      "name": "message | messages"
    },
    "bulkSentSms": {
      "name": "message | messages",
      "sessionId": "Session ID",
      "sendTime": "Send time",
      "receiveTime": "Receive time",
      "phonesNumber": "{number} numbers"
    },
    "receivedSms": {
      "name": "message | messages",
      "tabTitle": "Inbound SMS details",
      "receiveTime": "Receive time",
      "messageContent": "Message content",
      "messageId": "Message ID"
    },
    "template": {
      "name": "template | templates",
      "saveAsTemplate": "Save as template",
      "useTemplate": "Use template",
      "searchPlaceholder": "Search templates",
      "deleteTemplate": "Delete template",
      "editTemplate": "Edit template",
      "newTemplate": "New template",
      "modalTitle": "Templates"
    },
    "templateCategory": {
      "name": "category | categories",
      "allCategories": "All categories",
      "editCategory": "Edit category"
    },
    "sendReceiveSettings": {
      "name": "send & receive setting | send & receive settings"
    },
    "inboundCall": {
      "name": "inbound call | inbound calls",
      "id": "Call ID",
      "direction": "Direction",
      "answeredCall": "Inbound call",
      "missedCall": "Missed call",
      "callTime": "Call time",
      "callLength": "Call length",
      "searchPlaceholder": "Search calls"
    },
    "outboundCall": {
      "name": "outbound call | outbound calls",
      "id": "Call ID",
      "direction": "Direction",
      "outboundCall": "Outbound call",
      "callTime": "Call time",
      "callLength": "Call length",
      "searchPlaceholder": "Search calls"
    },
    "forwardedCall": {
      "name": "forwarded call | forwarded calls",
      "id": "Call ID",
      "forwardedCall": "Forwarded call",
      "callTime": "Call time",
      "callLength": "Call length",
      "editSettings": "Edit forwarding settings",
      "caller": "Caller",
      "numberDialed": "Number dialed",
      "forwardedTo": "Call forwarded to",
      "greetingUsed": "Greeting used",
      "searchPlaceholder": "Search calls"
    },
    "invoices": {
      "name": "invoices",
      "searchPlaceholder": "Search invoices"
    },
    "unsubscribedSettings": {
      "name": "unsubscribed settings | unsubscribed settings"
    },
    "notes": {
      "name": "Note"
    },
    "userCustomField": {
      "name": "field | fields",
      "newCustomField": "New custom field"
    },
    "forwardAudio": {
      "name": "file | files"
    },
    "senderId": {
      "name": "Sender ID | Sender IDs",
      "lowerCaseName": "ID | IDs",
      "status": {
        "A": "Accepted",
        "R": "Rejected",
        "P": "Pending"
      },
      "searchPlaceholder": "Search sender IDs",
      "bySenderId": "{name} (Sender ID)"
    },
    "activityCall": {
      "name": "Call"
    },
    "activitySentSms": {
      "name": "Sent SMS"
    },
    "activityReply": {
      "name": "Inbound SMS"
    },
    "activitySchedule": {
      "name": "Scheduled SMS"
    },
    "subAccountsImport": {
      "name": "file | files"
    },
    "subAccountsImportDetails": {
      "name": "sub-account | sub-accounts"
    },
    "auditLog": {
      "name": "Log | Logs"
    },
    "emailLookup": {
      "name": "lookup | lookups"
    },
    "emailLookupSession": {
      "name": "lookup | lookups",
      "searchPlaceholder": "Search bulk lookup"
    },
    "tenDlcPhoneNumber": {
      "name": "number | numbers"
    },
    "tollFreePhoneNumber": {
      "name": "number | numbers"
    },
    "tenDlcCampaign": {
      "name": "campaign | campaigns"
    },
    "taskBoard": {
      "name": "task board | tasks boards"
    },
    "taskStage": {
      "name": "task stage | tasks stages"
    },
    "task": {
      "name": "task | tasks"
    },
    "teamSubscriptionInvoices": {
      "name": "invoice | invoices"
    },
    "attachmentFile": {
      "name": "file | files"
    },
    "recipient": {
      "name": "recipient | recipients"
    },
    "subAccounts": {
      "name": "sub-account | sub-accounts",
      "searchPlaceholder": "Search sub-accounts"
    }
  },
  "notifications": {
    "delete": {
      "successful": "The selected {entity} has been successfully deleted."
    },
    "create": {
      "successful": "The {entity} has been successfully created.",
      "view": "View {entity}"
    },
    "duplicate": {
      "successful": "The {entity} has been successfully duplicated."
    },
    "update": {
      "successful": "The {entity} has been successfully updated.|The {entity} have been successfully updated."
    }
  },
  "filters": {
    "receivedSmsModalTitle": "Inbound SMS filter",
    "receivedSmsFilterTitle": "Inbound messages with no outbound sent",
    "receivedSmsFilterDescription": "Display only the inbound messages with no outbound message sent beforehand.",
    "sentSmsModalTitle": "Sent SMS filter",
    "sentSmsFilterTitle": "Messages with no replies",
    "sentSmsFilterDescription": "Display only the outbound messages with no replies received.",
    "noResults": "There are no matching results",
    "datetime": {
      "rangeLast": "in the last",
      "rangeFrom": "from",
      "rangeTo": "to"
    }
  },
  "sidebarForm": {
    "placeholder": "Add {field}",
    "fields": {
      "username": "Username",
      "fullName": "Full name",
      "phone": "Phone",
      "whatsappPhone": "WhatsApp",
      "fbMessengerSenderId": "Facebook",
      "instagramSenderId": "Instagram",
      "email": "Email",
      "companyName": "Company",
      "listIds": "Lists",
      "segments": "Segments",
      "assigneeId": "Assignee",
      "ownerId": "Contact owner",
      "tagIds": "Tags",
      "status": "Status",
      "channel": "Channel",
      "chatTagIds": "Tags",
      "priority": "Priority",
      "carbonCopies": "CCs",
      "dueDate": "Due date"
    },
    "leavePage": "You have unsaved changes. If you continue, your changes will be discarded. Are you sure you want to continue?",
    "contactInfo": "Contact info",
    "customFields": "Custom fields",
    "newCustomField": "New custom field",
    "emptyState": {
      "title": "No search results found",
      "description": "Please try a different keyword"
    }
  },
  "filteredView": {
    "viewSaver": {
      "unsavedChanges": "Unsaved changes",
      "saveAsBtnLabel": "Save as…",
      "discardBtnLabel": "Discard",
      "saveInPlace": "Save changes",
      "saveAsNew": "Save as new view"
    }
  },
  "bulkDelete": {
    "defaultSuccessNotification": "The selected item has been successfully deleted. | The selected items have been successfully deleted.",
    "blockedContacts": {
      "title": "Delete contact | Delete contacts",
      "text": "Selected contact will be deleted permanently. Are you sure you would like to delete it? | All selected contacts will be deleted permanently. Are you sure you would like to delete selected contacts?",
      "successNotification": "The selected contact has been successfully deleted. | The selected contacts have been successfully deleted."
    },
    "sentSmsShort": {
      "title": "Delete outbound message | Delete outbound messages",
      "text": "Are you sure you would like to delete the selected outbound message? This action cannot be undone. | Are you sure you would like to delete the selected outbound messages? This action cannot be undone.",
      "successNotification": "The selected outbound message has been successfully deleted. | The selected outbound messages have been successfully deleted."
    },
    "emailCampaign": {
      "title": "Delete email | Delete emails",
      "text": "Are you sure you would like to delete the selected email? This action cannot be undone. | Are you sure you would like to delete the selected emails? This action cannot be undone.",
      "successNotification": "The selected email has been successfully deleted. | The selected emails have been successfully deleted."
    },
    "receivedSms": {
      "title": "Delete inbound message | Delete inbound messages",
      "text": "Are you sure you would like to delete the selected inbound message? This action cannot be undone. | Are you sure you would like to delete the selected inbound messages? This action cannot be undone.",
      "successNotification": "The selected inbound message has been successfully deleted. | The selected inbound messages have been successfully deleted."
    },
    "contacts": {
      "title": "Delete contact | Delete contacts",
      "text": "Selected contact will be deleted permanently. Are you sure you would like to delete the selected contact? | All selected contacts will be deleted permanently. Are you sure you would like to delete the selected contacts?",
      "successNotification": "The selected contact has been successfully deleted. | The selected contacts have been successfully deleted."
    },
    "contactList": {
      "title": "Delete list | Delete lists",
      "text": "The selected list will be deleted permanently. Are you sure you would like to delete the selected list? | The selected lists will be deleted permanently. Are you sure you would like to delete the selected lists?",
      "successNotification": "The selected list has been successfully deleted. | The selected lists have been successfully deleted."
    },
    "contactImport": {
      "title": "Delete imported file | Delete imported files",
      "text": "Are you sure you would like to delete this file? This action cannot be undone. | Are you sure you would like to delete these files? This action cannot be undone.",
      "successNotification": "The import file has been successfully deleted. | The import files have been successfully deleted"
    },
    "inboundCall": {
      "title": "Delete inbound call | Delete inbound calls",
      "text": "Are you sure you would like to delete the selected inbound call? This action cannot be undone. | Are you sure you would like to delete the selected inbound calls? This action cannot be undone.",
      "successNotification": "The selected inbound call has been successfully deleted. | The selected inbound calls have been successfully deleted."
    },
    "outboundCall": {
      "title": "Delete outbound call | Delete outbound calls",
      "text": "Are you sure you would like to delete the selected outbound call? This action cannot be undone. | Are you sure you would like to delete the selected outbound calls? This action cannot be undone.",
      "successNotification": "The selected outbound call has been successfully deleted. | The selected outbound calls have been successfully deleted."
    },
    "forwardedCall": {
      "title": "Delete forwarded call | Delete forwarded calls",
      "text": "Are you sure you would like to delete the selected forwarded call? This action cannot be undone. | Are you sure you would like to delete the selected forwarded calls? This action cannot be undone.",
      "successNotification": "The selected forwarded calls has been successfully deleted. | The selected forwarded calls have been successfully deleted."
    },
    "userCustomField": {
      "title": "Delete custom field | Delete custom fields",
      "text": "{warning} Any data that was contained in field will be permanently deleted. Are you sure you would like to delete the selected field? | {warning} Any data that was contained in fields will be permanently deleted. Are you sure you would like to delete the selected fields?",
      "successNotification": "The selected custom field has been successfully deleted. | The selected custom fields have been successfully deleted."
    },
    "subAccountsImport": {
      "title": "Delete imported file | Delete imported files",
      "text": "Are you sure you would like to delete this file? This action cannot be undone | Are you sure you would like to delete these files? This action cannot be undone.",
      "successNotification": "The import file has been successfully deleted. | The import files have been successfully deleted."
    },
    "allContacts": {
      "title": "Delete all your contacts",
      "target": "all your contacts",
      "successNotification": "All contacts have been successfully deleted."
    },
    "allMessages": {
      "title": "Delete all your messages",
      "target": "all your messages",
      "successNotification": "All messages have been successfully deleted."
    },
    "ticket": {
      "title": "Delete tickets",
      "text": "You are going to delete {ticketsCount}. This action cannot be undone. Are you sure you would like to continue?"
    }
  },
  "bulkEditForm": {
    "selectFields": "Select fields to edit",
    "fieldValue": "New field value",
    "addAnotherField": "Add another field",
    "selectField": "- Select field -",
    "updateN": "Update {count}",
    "operations": {
      "tags": {
        "attach": "Add to existing tags",
        "detach": "Remove these tags",
        "replace": "Replace all tags with",
        "detachAll": "Remove all tags"
      }
    },
    "tagsPlaceholder": "Enter tag name",
    "tagsRequired": "Select new tags value"
  },
  "bulkUpdateModals": {
    "contactTagsBulkModal": {
      "title": "Bulk edit contacts",
      "submitText": "Update {count} contact | Update {count} contacts",
      "successNotification": "We are updating the selected contact. The changes may take a few moments to appear. | We are updating the selected contacts. The changes may take a few moments to appear."
    }
  },
  "header": {
    "admin": "Go to Admin",
    "balance": "Balance",
    "pending": "pending",
    "buyCredit": "Buy credit",
    "subAccountSwitch": "Switch to another sub-account",
    "subAccountSwitchShort": "Switch sub-account",
    "newMessage": "New message",
    "newChat": "New chat",
    "newContact": "New contact",
    "newList": "New list",
    "newSurvey": "New survey",
    "newTask": "New task",
    "newDeal": "New deal",
    "importContacts": "Import contacts",
    "autoRecharge": "Auto-recharge",
    "invoices": "Invoices",
    "subAccounts": "Sub-accounts",
    "notifications": "Notifications",
    "myData": "My data",
    "myAccount": "My account",
    "billing": "Billing",
    "newTemplate": "New template",
    "inviteUsers": "Invite users",
    "inviteSubAccounts": "Invite sub-accounts"
  },
  "tmTableFooter": {
    "pageNavigationTextFull": "**{itemsRange}** of **{itemsTotal}** {entity}",
    "pageNavigationTextShort": "**{itemsRange}** / **{itemsTotal}**",
    "rowsPerPage": "Show on page",
    "perPageOption": "{count} rows"
  },
  "tableNoData": {
    "noMatchingResults": "There are no results with the applied filters",
    "refineRequest": "Please try to change your search request or filters"
  },
  "notFound": {
    "header": "Sorry, we can’t find the page you’re looking for.",
    "text": "The link you followed may be broken, or the page may have been removed.",
    "backText": "Go to Home page",
    "entity": "{entity} not found"
  },
  "errorPage": {
    "title": "Sorry, the app has crashed. Please try refreshing.",
    "description": "Apologies, we are experiencing difficulty in loading the app. This may be due to internal errors or temporary network disruptions.",
    "contactUs": "If this error persists, please contact us on {supportEmail}",
    "refreshApp": "Refresh app",
    "buildId": "Build ID"
  },
  "baseFiltersService": {
    "nameFilterLabel": "Name",
    "firstNameFilterLabel": "First name",
    "lastNameFilterLabel": "Last name",
    "subjectFilterLabel": "Subject",
    "emailFilterLabel": "Email",
    "phonesFilterLabel": "Phones",
    "tagsFilterLabel": "Tags",
    "timezoneFilterLabel": "Timezone",
    "countryFilterLabel": "Country",
    "assigneeFilterLabel": "Assignee",
    "createdAtFilterLabel": "Date created",
    "updatedAtFilterLabel": "Last updated",
    "listsOfContactsFilterLabel": "List of contacts",
    "ipFilterLabel": "IP",
    "osFilterLabel": "Device",
    "browserFilterLabel": "Browser",
    "loginDateFilterLabel": "Login date",
    "deselectAll": "Deselect all",
    "selectAll": "Select all",
    "closedAt": "Closed at",
    "willClosedAt": "Will closed at",
    "statusFilterLabel": "Status",
    "listsFilterLabel": "Lists",
    "priorityFilterLabel": "Priority",
    "onlyWithoutOutbound": "Without outbound",
    "onlyWithoutInbound": "Without inbound",
    "status": "Status",
    "searchTags": "Search tags",
    "searchChannels": "Search channels"
  },
  "errors": {
    "pleaseTryAgainInAFewMinutesOrRefreshThePageNow": "Please try again in a few minutes or refresh the page now.",
    "itLooksLikeSomethingWentWrong": "It looks like something went wrong",
    "tooManyRequests": "Too many attempts. Please try again later.",
    "userNotFound": "Sorry, we couldn't find an account with that email/username.",
    "default": "Server error",
    "resendEmailTimer": "Re-sending of the email will be available in {seconds} seconds",
    "tryAnotherEmail": "Please, try out another email",
    "verificationCodeNotFound": "Verification code not found.",
    "verificationAttemptsLimit": "You have tried too many times. Please wait, then try again in 60 seconds.",
    "password": {
      "letters": "{count} to 48 characters",
      "numbers": "One number",
      "specialChars": "One special character",
      "lowercaseAndUppercase": "Lower and uppercase",
      "requiresEmailVerification": "Email is already registered, please verify your email and log in"
    },
    "valueIsTooShort": "This value is too short. It should have {minLength} characters or more.",
    "confirmationCode": "The {length}-digit number you entered is incorrect. Please try again.",
    "valueIsTooLarge": "This value is too large. It should have {maxLength} characters or less.",
    "theFieldsMustMatch": "The {fieldName} fields must match.",
    "youAreNotAllowedToPerformThisAction": "You are not allowed to perform this action. Please check your account permissions.",
    "maxLength": "Max length {params0} characters",
    "newPasswordMustDifferent": "New password must be different from the old one.",
    "fieldIsNotValid": "This field is not valid",
    "notEnoughBalanceError": "Sorry, but you do not have enough credit to perform this action. {buyCreditLink} credit to continue.",
    "notEnoughBalanceLink": "Please buy"
  },
  "tableColumns": {
    "cost": "Cost",
    "firstName": "First name",
    "lastName": "Last name",
    "fullName": "Full name",
    "phone": "Phone",
    "createdAt": "Date created",
    "createdBy": "Created by",
    "updatedAt": "Last updated",
    "lastUpdatedAt": "Last updated",
    "lastUpdatedBy": "Last updated by",
    "country": "Country",
    "fieldName": "Field name",
    "name": "Name",
    "status": "Status",
    "from": "From",
    "message": "Message",
    "timeReceived": "Time received",
    "companyName": "Company",
    "category": "Category",
    "content": "Content",
    "lastModified": "Last modified",
    "membersCount": "Contacts",
    "shared": "Type",
    "dateCreated": "Date created",
    "unsubscribeTime": "Date unsubscribed",
    "reason": "Reason",
    "unsubscribeText": "Unsubscribed text",
    "dateBlocked": "Date blocked",
    "to": "To",
    "isAnswered": "",
    "callLength": "Call length",
    "callTime": "Call time",
    "email": "Email",
    "emailForSharing": "Email for sharing",
    "logInButton": "Log in",
    "role": "Role",
    "subaccountType": "Role",
    "invoice": "Invoice",
    "amount": "Amount",
    "paymentMethod": "Payment Method",
    "date": "Date",
    "numberDialed": "Number dialed",
    "forwardedTo": "Forwarded to",
    "forwardedCaller": "Caller",
    "forwardedNumber": "Forwarded to",
    "fromNumber": "From",
    "toNumber": "To",
    "length": "Length",
    "fileName": "File name",
    "imported": "Imported",
    "allowedEmails": "Allowed emails",
    "dateAdded": "Date added",
    "lastSms": "Last SMS",
    "surveyName": "Survey name",
    "recipients": "Recipients",
    "questions": "Questions",
    "replies": "Replies",
    "listName": "List name",
    "nextSentDate": "Next sent date",
    "lastSentDate": "Last sent date",
    "type": "Type",
    "isHidden": "Visibility",
    "nextSend": "Next send date",
    "contactName": "To",
    "lastSent": "Last sent date",
    "sendTime": "Send time",
    "lists": "Lists",
    "failedAt": "Attempt date",
    "fileSize": "File size",
    "uploadTime": "Upload time",
    "loginsHistory": {
      "ip": "IP",
      "loginDate": "Login date"
    },
    "action": "Action",
    "debit": "Debit",
    "credit": "Credit",
    "balance": "Balance",
    "description": "Description",
    "account": "Account",
    "user": "User",
    "sender": "Sender",
    "receiver": "Received by",
    "ruleName": "Rule name",
    "executedAt": "Run time",
    "year": "Year",
    "month": "Month",
    "day": "Day",
    "totalCalls": "Total calls",
    "totalTalkTime": "Total talk time",
    "costs": "Costs",
    "clicks": "Clicks",
    "submits": "Submits",
    "embedCode": "Embed code",
    "carrier": "Carrier",
    "textmagicNumber": "Textmagic number",
    "tollFreeNumber": "Toll-free number",
    "linkedToCampaign": "Linked to campaign",
    "campaignName": "Campaign name",
    "campaignId": "Campaign ID",
    "useCase": "Use case",
    "nextRenewal": "Next renewal",
    "responseType": "Auto-responder",
    "created": "Created",
    "brandId": "Brand ID",
    "campaigns": "Campaigns",
    "keywords": "Keywords",
    "tenDlcSelectCampaign": {
      "name": "Campaign name",
      "useCase": "Use case",
      "status": "Status"
    },
    "recipientsCountries": {
      "destination": "Destination",
      "sendFrom": "Send from"
    },
    "label": "Label",
    "provider": "Provider",
    "voice": "Calls",
    "sms": "SMS",
    "mms": "MMS",
    "device": "Device",
    "location": "Location",
    "id": "ID",
    "priority": "Priority",
    "priorityShort": "P",
    "title": "Title",
    "stage": "Stage",
    "assignee": "Assignee",
    "dueDate": "Due date",
    "lastUpdated": "Last updated",
    "whatsappPhone": "WhatsApp",
    "senderId": "Sender ID",
    "ownerId": "Contact owner",
    "tags": "Tags",
    "landingPage": "Landing page",
    "contact": "Contact name",
    "delivered": "Delivered",
    "sendDate": "Send date",
    "source": "Source",
    "openDate": "Open date",
    "unsubscribeDate": "Unsubscribe date",
    "reportDate": "Report date",
    "link": "Link",
    "uniqueClicks": "Unique clicks",
    "clickDate": "Click date"
  },
  "tmColumnEditor": {
    "columnsLabel": "Columns",
    "cantBeHidden": "Can’t be hidden",
    "hideAll": "Hide all",
    "showAll": "Show all"
  },
  "commonValidationRegistrator": {
    "defaultMessage": "Please enter a valid {field}.",
    "required": "Please enter a valid {field}.",
    "min": "{field} should be at least {params0} characters long.",
    "max": "{field} can consist of maximum {params0} characters.",
    "fullName": {
      "firstName": "Please enter a valid first name.",
      "lastName": "Please enter a valid last name."
    },
    "phoneNumber": "Please enter a valid phone number.",
    "phoneNumberForRegion": "Please enter a valid phone number for selected region.",
    "confirmation": "Password confirmation does not match.",
    "equal": "{Field} should be equal.",
    "noEqual": "{Field} should not match.",
    "hasSomeValid": "{Field} should have at least one valid field.",
    "hasEveryValid": "Every {Field} item should be valid.",
    "hasAtLeastOne": "{Field} should have at least one field.",
    "hasLetters": "At least {count} letter.",
    "hasNumbers": "At least {count} number.",
    "hasSpecialChars": "At least {count} special char.",
    "hasLowercaseAndUppercase": "Must contain lowercase and uppercase.",
    "hasUppercase": "Must contain lowercase and uppercase.",
    "mustBeGreaterOrEqual": "Must be greater than or equal to {count}.",
    "domain": "Please enter a valid domain.",
    "domainOrEmail": "Please enter a valid domain or email.",
    "countryCodeNotEmpty": "Country code could not be empty.",
    "phoneNotValid": "Please enter a valid phone number.",
    "emailNotValid": "Please enter a valid email address.",
    "noMoreThanCharactersLong": "{field} cannot be longer than {count} characters.",
    "cannotBeLonger": "Cannot be longer than {length} characters.",
    "emojiTextRequired": "Please enter a valid text.",
    "hasNoText": "Please enter a valid text.",
    "lessThan": "Must be less than {max}."
  },
  "fileValidationRegistrator": {
    "maxFileSize": "File size should be less than {fileSize}",
    "isImage": "{Field} should be an image",
    "fileAllowed": "File type is not allowed",
    "isTableDocument": "We couldn’t process file of this format. Please use only supported file types.",
    "isMessagesAttachment": "We are unable to process the uploaded file format. Please use a valid file format ({formats})."
  },
  "specialValidationRegistrator": {
    "overlaps": "These values of fields are overlapped with each other: {overlaps}",
    "unique": "Please enter unique values",
    "minCharacters": "At least {number} characters long",
    "fromToCharacters": "{min} to {max} characters",
    "lowerAndUppercase": "Lower and uppercase",
    "oneNumber": "One number",
    "oneSpecialChar": "One special character"
  },
  "composeValidationRegistrator": {
    "hasComposeContent": "Please enter a message.",
    "composeAvailableTags": "One of the tags you have entered is invalid. Please check your message text."
  },
  "dateTimeValidationRegistrator": {
    "isEarlierThan": "\"{dateFrom}\" date must be earlier than \"{dateTo}\" date",
    "rangeFromMustBeEarlierThanTo": "Range end bound must be greater than range start",
    "rangeFromMustBeInPast": "Range start bound must be in the past",
    "rangeBoundsRequired": "Range bounds must be defined"
  },
  "numericValidationRegistrator": {
    "fromValueMaxLength": "'From' field cannot be greater than 'To' field"
  },
  "fieldNames": {
    "fullName": "Full name",
    "companyName": "Company name",
    "password": "Password",
    "passwordConfirmation": "Password confirmation",
    "primaryPhone": "Phone",
    "primaryEmail": "Email",
    "attachNewFile": "Attach new file",
    "addNote": "Add note",
    "username": "Username",
    "oldPassword": "Old password",
    "newPassword": "New password",
    "confirmPassword": "Confirm password",
    "selectDate": "Select date",
    "firstName": "First name",
    "lastName": "Last name",
    "timezone": "Timezone",
    "timeFormat": "Time format",
    "phone": "Phone",
    "email": "Email",
    "emailAddress": "Email address",
    "name": "Name",
    "addressLine1": "Address line 1",
    "countryId": "Country",
    "statusId": "Status"
  },
  "fieldMultipleCountries": {
    "noCountries": "No countries found"
  },
  "fieldTimezone": {
    "label": "(UTC {offset}): {timezoneName}",
    "offset": "(UTC {offset})"
  },
  "fileForm": {
    "replacePhoto": "Replace photo",
    "deletePhoto": "Delete current photo",
    "cropImage": "Crop image",
    "savePhoto": "Save changes"
  },
  "tooltips": {
    "smsRejected": "Message had not been sent because it was rejected by Textmagic.",
    "smsFailed": "Message was not delivered. Possible reasons: phone switched off, out of coverage, incorrect number.",
    "smsDelivered": "Message has been successfully delivered to the recipient.",
    "smsSent": "Message has been sent. Waiting for final delivery confirmation from mobile operator.",
    "errorCode": {
      "facebook_invalid_access_token": "The access token has expired. Please reconnect your page or <a href=\"{supportLink}\" target=\"_blank\">contact support</a> for assistance.",
      "facebook_allowed_time_window_expired": "The Facebook 24-hour session has ended. You can send more messages when the contact sends a new message in the chat.",
      "facebook_user_blocked_messaging": "The contact has blocked this Facebook page and does not wish to receive messages.",
      "facebook_bad_request": "An unexpected error occurred. Please wait for our system to retry or try sending your message again later.",
      "instagram_invalid_access_token": "The access token has expired. Please reconnect your Instagram account or <a href=\"{supportLink}\" target=\"_blank\">contact support</a> for assistance.",
      "instagram_allowed_time_window_expired": "The Instagram 24-hour session has ended. You can send more messages when the contact sends a new message in the chat.",
      "instagram_user_blocked_messaging": "The contact has blocked this Instagram account and does not wish to receive messages.",
      "instagram_bad_request": "An unexpected error occurred. Please wait for our system to retry or try sending your message again later.",
      "instagram_unsupported_attachment": "The attachment type or format is not supported by Instagram. Please try with a different file format or contact <a href=\"{supportLink}\" target=\"_blank\">support</a> for assistance."
    },
    "insertEmoji": "Insert emoji",
    "contact": {
      "list": {
        "menu": "View all your Textmagic lists including the ones that have been shared to you by your sub-accounts.",
        "menuUsersWorkflow": "View workspace contact lists.",
        "newList": "Create new list",
        "newContact": "Add new contact",
        "import": "Import contact",
        "sendSms": "Send message to selected lists",
        "delete": "Delete item(s) permanently",
        "share": "Share lists with sub-accounts",
        "private": "This list is not visible to your sub-accounts.",
        "sharedByMe": "This list is owned by you and is visible to your sub-accounts.",
        "sharedToMe": "This list is shared to you by one of your sub-accounts."
      },
      "listDetails": {
        "sendSMS": "Send SMS to selected contacts",
        "addContacts": "Add new contact(s) to this list",
        "import": "Import contacts",
        "download": "Download contacts",
        "makeListPrivate": "Make this list private",
        "shareThisList": "Share this list with sub-accounts",
        "editListDetails": "Edit list details",
        "deleteList": "Delete list",
        "moveCopy": "Move/Copy selected contacts to other list",
        "removeFromList": "Remove selected contacts from list",
        "delete": "Delete selected contacts"
      },
      "myContacts": {
        "menu": "View all contacts that you have imported to Textmagic online. Contacts from the lists shared by sub-accounts are not shown here.",
        "menuUsersWorkflow": "View workspace contacts.",
        "newContact": "Add new contact",
        "import": "Import contacts",
        "download": "Download contacts",
        "sendSMS": "Send SMS to selected contacts",
        "addToList": "Add selected contacts to another list",
        "delete": "Delete selected contacts",
        "settings": "Configure how to display the content"
      },
      "myContactDetails": {
        "newSms": "Send a new message",
        "newSmsBlocked": "This contact is blocked.",
        "viewChat": "View message history",
        "edit": "Edit contact",
        "download": "Download contact",
        "unsubscribe": "Unsubscribe contact",
        "block": "Block contact",
        "unblock": "Unblock contact",
        "delete": "Delete contact"
      },
      "customFields": {
        "menu": "View all your custom fields that can be used as tags for sending personalized SMS.",
        "newCustomField": "Create a new custom field for your contacts",
        "delete": "Delete item(s) permanently"
      },
      "imports": {
        "menu": "View all import sessions that you have created and check the results of the sessions.",
        "newImport": "Import contacts in bulk",
        "delete": "Delete the selected imported files"
      },
      "unsubscribed": {
        "menu": "The list of contacts and numbers that have unsubscribed from your communication.",
        "settings": "Edit unsubscribe settings",
        "import": "Import unsubscribers",
        "download": "Download unsubscribers",
        "manual": "Number manually unsubscribed by a user.",
        "blocked": "Number manually unsubscribed (with inbound blocked) by a user.",
        "rule": "Number automatically unsubscribed by a rule.",
        "stop": "STOP request received from this number.",
        "spam": "Too many messages sent during short period.",
        "auto": "Number has been unsubscribed automatically because the message delivery failed.",
        "complaint": "Unsubscribed by Textmagic support due to the complaint received from the subscriber."
      },
      "blockedContacts": {
        "menu": "View all blocked contacts that cannot send you inbound messages.",
        "addNewBlock": "Add blocked number",
        "import": "Import blocked contact",
        "download": "Download blocked contacts",
        "unblock": "Unblock selected contacts",
        "delete": "Delete selected blocked contacts"
      }
    }
  },
  "passwordConfirmationModal": {
    "text": "To delete {which}, please enter {accountPassword}.",
    "accountPassword": "your account password",
    "fieldLabel": "Password",
    "fieldPlaceholder": "Enter password",
    "wrongPassword": "The provided password in not valid."
  },
  "reopenSubAccountConfirmationModal": {
    "title": "Reopen sub-account",
    "text": "Are you sure, you want to reopen this sub-account?",
    "submit": "Reopen sub-account"
  },
  "reopenUserConfirmationModal": {
    "title": "Reopen user account",
    "youAreAboutToReopen": "You are about to reopen {name} user account. Before you proceed, here’s a summary of what will happen next:",
    "options": [
      "The user will regain access to the workspace immediately and receive an email notification about this change.",
      "Your upcoming subscription payment (on {date}) will be increased on a prorated basis to reflect an extra user seat.",
      "Each user seat costs {cost} per month."
    ],
    "pleaseConfirmReopen": "Please confirm that you would like to reopen this account.",
    "submit": "Reopen account",
    "successMessage": "User account has been opened."
  },
  "auth": {
    "authLoader": {
      "title": "Logging you in securely…",
      "longLoadPlaceholder1": "We appreciate your patience.\nStill working on it…",
      "longLoadPlaceholder2": "If this page appears for more than {number} seconds, {click} to reload.",
      "clickHereLink": "click here"
    }
  },
  "TmRowSelected": {
    "selectAll": "Select all",
    "selectAllVisible": "Select all visible items",
    "clearSelection": "clear selection",
    "selectedText": "{selectedCount} out of {total} {entityName} is selected | {selectedCount} out of {total} {entityName} are selected"
  },
  "partialForm": {
    "fields": {
      "country": "Country",
      "phone": "Phone",
      "email": "Email",
      "lastName": "Last name",
      "firstName": "First name",
      "status": "Status",
      "assignee": "Assigned to",
      "tags": "Tags",
      "timezoneId": "Timezone",
      "primaryContactId": "Primary contact",
      "countryId": "Country",
      "emails": "Emails",
      "phones": "Phones",
      "time": "Time",
      "lists": "Lists",
      "followers": "Followers",
      "subject": "Subject",
      "priority": "Priority",
      "dueDate": "Due date",
      "owner": "Contact owner"
    }
  },
  "formatRelative": {
    "lastWeek": "'Last' eeee 'at', h:mm aaa",
    "yesterday": "'Yesterday', h:mm aaa",
    "today": "'Today', h:mm aaa",
    "tomorrow": "'Tomorrow', h:mm aaa",
    "nextWeek": "eeee 'at' h:mm aaa"
  },
  "formatDateRelative": {
    "lastWeek": "'Last' eeee",
    "yesterday": "'Yesterday'",
    "today": "'Today'",
    "tomorrow": "'Tomorrow'",
    "ago": "ago"
  },
  "blockedContactForm": {
    "title": "Add blocked number",
    "phone": "Phone number"
  },
  "blockContactConfirmation": {
    "submitText": "Block",
    "submitLoadingText": "Blocking...",
    "title": "Block contact",
    "successText": "The number has been successfully blocked.",
    "text": "You will no longer be able to exchange messages or calls with {who} once it is blocked."
  },
  "unblockContactConfirmation": {
    "submitText": "Unblock",
    "submitLoadingText": "Unblocking...",
    "title": "Unblock confirmation",
    "successText": "The number has been successfully unblocked. | The selected numbers have been successfully unblocked.",
    "text": "You will be able to exchange messages with this contact once unblocked."
  },
  "UnsubscribeTableDropdown": {
    "popupMessage": "Your contact can send you an inbound message with these keywords to resubscribe: JOIN, SUBSCRIBE, RESUBSCRIBE, OPT IN, OPT-IN, OPTIN or UNSTOP.",
    "resubscribe": "Resubscribe",
    "blockIncomingMessages": "Block inbound messages",
    "allowIncomingMessages": "Allow inbound messages",
    "toggleBlockToastMessage": "The contact has been successfully updated"
  },
  "unsubscribeEmailTableDropdown": {
    "resubscribe": "Resubscribe",
    "popupMessage": "You cannot manually resubscribe this contact as they unsubscribed via an email link. Contact {support} if this was a mistake.",
    "support": "Textmagic support"
  },
  "ResubscribeAction": {
    "text": "Are you sure you want to resubscribe this contact?",
    "successText": "The contact has been successfully resubscribed",
    "title": "Resubscribe contact",
    "submitText": "Resubscribe",
    "submitLoadingText": "Resubscribing..."
  },
  "UnsubscribeAction": {
    "title": "Unsubscribe contact"
  },
  "BlockedContactsTableDropdown": {
    "unblockContact": "Unblock contact"
  },
  "ReceivedSmsTableDropdown": {
    "deleteMessage": "Delete message",
    "viewContact": "View contact"
  },
  "dateTimePickerDropdown": {
    "date": {
      "label": "Due date",
      "placeholder": "Select date"
    },
    "time": {
      "label": "Due time",
      "placeholder": "Select time"
    },
    "remove": "Remove"
  },
  "resubscribeEmailContactModal": {
    "title": "Resubscribe email",
    "text": "Are you sure you want to resubscribe this email {email}? Once resubscribed, you will be able to send emails again.",
    "submitText": "Resubscribe",
    "submitLoadingText": "Resubscribing...",
    "successText": "The email has been successfully resubscribed."
  },
  "unsubscribeEmailContactModal": {
    "title": "Unsubscribe email",
    "text": "Are you sure you want to unsubscribe this email {email}? Once unsubscribed, you will not be able to send emails to this contact.",
    "submitText": "Unsubscribe",
    "submitLoadingText": "Unsubscribing...",
    "successText": "The email has been successfully unsubscribed."
  },
  "listDeleteModal": {
    "title": "Delete list",
    "text": "{listName} will be deleted permanently. Are you sure you would like to delete it?",
    "checkboxText": "Also permanently delete all contacts contained in this list | Also permanently delete all contacts contained in these lists"
  },
  "receivedSmsDeleteModal": {
    "title": "Delete inbound message",
    "text": "Are you sure you would like to delete selected inbound message? This action cannot be undone.",
    "successText": "The inbound message has been successfully deleted."
  },
  "sentSmsDeleteModal": {
    "title": "Delete outbound message",
    "text": "Are you sure you would like to delete the selected outbound message? This action cannot be undone.",
    "successText": "The outbound message has been successfully deleted."
  },
  "bulkSentSmsDeleteModal": {
    "title": "Delete outbound message session",
    "text": "Are you sure you would like to delete the selected outbound messages session? This action cannot be undone.",
    "successText": "The sent SMS session and messages have been successfully deleted."
  },
  "sentSmsEmptyState": {
    "title": "You don’t have any outbound text messages yet",
    "description": "Use Compose or Chat to send your first SMS."
  },
  "SentSmsTableDropdown": {
    "viewContact": "View contact",
    "deleteMessage": "Delete message"
  },
  "sentSmsBulkTableDropdown": {
    "deleteSession": "Delete session"
  },
  "receivedSmsEmptyState": {
    "title": "You have not received any messages yet",
    "description": "To receive some inbound messages, first send some messages to your contacts."
  },
  "contactImportEmptyState": {
    "title": "Import your phone numbers to Textmagic",
    "description": "Supported file types for import are Excel (.xls or .xlsx) and CSV (.csv).",
    "button": "Import contacts"
  },
  "contactImportDeleteModal": {
    "title": "Delete imported file",
    "text": "Are you sure you would like to delete this imported file? This action cannot be undone.",
    "successText": "The imported file has been successfully deleted."
  },
  "userCustomFieldDeleteModal": {
    "title": "Delete custom field",
    "text": "{warning} Any data that was contained in this ({customField}) field will be permanently deleted. Are you sure you would like to delete selected field?",
    "warning": "Please note!",
    "successText": "The custom field has been successfully deleted."
  },
  "userCustomFieldEmptyState": {
    "title": "Add custom fields and use them as mail merge tags",
    "description": "Custom fields allow you to save more data for your contacts and use them as mail merge tags when sending text messages.",
    "button": "Add new field"
  },
  "subAccountImportEmptyState": {
    "title": "Import sub-accounts from a CSV or Excel file",
    "description": "Supported file types for sub-accounts import are Excel (.xls or .xlsx) and CSV (.csv).",
    "button": "Import sub-accounts"
  },
  "subAccountsImportTopbar": {
    "searchPlaceholder": "Search imported files",
    "buttons": {
      "invite": "Invite sub-accounts"
    }
  },
  "subAccountImportImportedCell": {
    "subAccounts": "{count} sub-account | {count} sub-accounts",
    "users": "{count} user | {count} users"
  },
  "subAccountsImportService": {
    "status": {
      "inProgress": "In progress",
      "completed": "Completed"
    }
  },
  "subAccountImportDeleteModal": {
    "title": "Delete imported file",
    "text": "Are you sure you would like to delete this imported file? This action cannot be undone.",
    "successNotification": "The import file has been successfully deleted"
  },
  "subAccountsImportDetails": {
    "breadcrumbs": {
      "account": "Account",
      "subAccounts": "Sub accounts"
    },
    "tabs": {
      "invited": "Invited",
      "duplicated": "Duplicates excluded",
      "failed": "Failed to invite"
    },
    "tableStatuses": {
      "invited": "invited",
      "duplicated": "excluded",
      "failed": "failed to invite"
    },
    "cellActions": {
      "editAndInvite": "Edit and invite"
    }
  },
  "subAccountsImportDetailsEmptyState": {
    "invited": {
      "title": "No sub-accounts have been invited in this session",
      "description": "Please check the \"Failed to import\" tab to fix possible validation errors."
    },
    "duplicated": {
      "title": "This session had no excluded duplicates",
      "description": "There is nothing to fix, everything is alright here."
    },
    "failed": {
      "title": "This session had no failed rows",
      "description": "There is nothing to fix, everything is alright here."
    }
  },
  "subAccountsImportDetailsTopbar": {
    "buttons": {
      "newImport": "New import"
    }
  },
  "cancelReasons": {
    "selectReason": "Select a reason",
    "notNeedService": "I don't need the service anymore",
    "hasIssuesWithPlatform": "I had issues with the platform",
    "pricingTooHigh": "The pricing is too high",
    "foundBetterAlternative": "I found a better alternative",
    "other": "Other"
  },
  "closeAccountModal": {
    "title": "Closing your Textmagic account?",
    "description": "We're very sorry to see you leave. Please let us know why you're closing your account. Your feedback helps us improve.",
    "customerSupport": "Customer support",
    "permanentDeletionMessage": "I understand that {which} owned by this account will be permanently deleted and cannot be restored.",
    "deletion": {
      "contacts": "contacts",
      "messages": "outbound and inbound messages"
    },
    "reasonField": {
      "label": "Please tell us why you're leaving"
    },
    "descriptionField": {
      "hasIssuesWithPlatform": {
        "label": "Shortly describe the issues you had",
        "placeholder": "It will help us get better in the future"
      },
      "foundBetterAlternative": {
        "label": "What alternative did you switch to?",
        "placeholder": "List the competitor name"
      },
      "other": {
        "label": "Shortly describe your reason",
        "placeholder": "Please explain why you decided to leave"
      }
    },
    "loadingText": "Closing...",
    "submitButton": "Close account"
  },
  "closeSubAccountModal": {
    "title": "Close sub-account",
    "description": "Are you sure you want to close this sub-account?",
    "permanentDeletionMessage": "I understand that the {which} data will be deleted permanently.",
    "deletion": {
      "contacts": "contacts and messaging",
      "numbers": "all virtual numbers"
    },
    "successMessage": "Sub-account has been closed.",
    "loadingText": "Closing...",
    "submitButton": "Close sub-account"
  },
  "usersCloseAccountModal": {
    "title": "Close user account",
    "successMessage": "Account has been closed.",
    "aboutToDeactivate": "You are about to deactivate {name} user account? Before you proceed, here’s a summary of what will happen next:",
    "options": [
      "The user will lose access to the workspace immediately.",
      "Your upcoming subscription payment (on {date}) will be reduced to reflect the removal of this user seat.",
      "Any remaining days from this seat's current subscription period will be calculated on a prorated basis and credited back to you."
    ],
    "confirm": "Please confirm that you would like to close this account.",
    "loadingText": "Closing...",
    "submitButton": "Close account"
  },
  "templatesIndexTopBar": {
    "share": "Share"
  },
  "templateFormName": {
    "label": "Template name",
    "placeholder": "Enter template name",
    "formErrors": {
      "required": "Please enter a valid template name.",
      "maxLength": "Template name cannot be longer than 50 plain text characters."
    }
  },
  "templateFormGenerateBtn": {
    "text": "Generate {content} text",
    "plain": "plain",
    "rich": "rich"
  },
  "templateFormContent": {
    "label": "Message",
    "placeholder": "Enter template text",
    "hint": {
      "characters": "Characters",
      "parts": "Parts"
    },
    "tabs": {
      "content": {
        "tabText": "SMS",
        "tabSubtext": "(Plain text)"
      },
      "emailContent": {
        "tabText": "Email",
        "tabSubtext": "(Rich text)"
      }
    },
    "formErrors": {
      "required": "Please enter a message for your template.",
      "maxLength": "Your message contains too many characters. Please make your message text shorter.",
      "categoryId": "Please enter a category for your template",
      "textHaveBeenTrimmed": "Please note that the plain text version has been cut to { number } characters."
    }
  },
  "templateFormCategory": {
    "label": "Category",
    "placeholder": "Select category"
  },
  "ContactListForm": {
    "nameLabel": "List name",
    "namePlaceholder": "Enter the list name",
    "isPrivateLabel": "Make this list private. Contacts will not be shared with sub-accounts.",
    "isDefaultLabel": "Make this list default for new contacts.",
    "editTitle": "Edit list",
    "addTitle": "Create new list",
    "fields": {
      "listNameRequired": "Please enter a valid List name."
    },
    "createList": "Create list"
  },
  "fieldTableColumns": {
    "addColumnLabel": "Add column",
    "lastColumnDeleteTooltip": "At least one column must be selected"
  },
  "api": {
    "title": "API & integrations"
  },
  "buyNumber": {
    "number": "Number",
    "callForwarding": "Call forwarding"
  },
  "senderSettings": {
    "buyNumber": {
      "callForwarding": "Call forwarding"
    }
  },
  "buyNumberStep1": {
    "chooseMobileNumber": "Choose your Textmagic texting number",
    "getNumberForTextMessaging": "Get your SMS number for text messaging with your customers, staff and partners.",
    "numberFeatures": {
      "sms": "Two-way SMS",
      "mms": "MMS",
      "voice": "Calls",
      "smsMms": "Two-way SMS, MMS"
    },
    "gettingStarted": "Getting started",
    "confirmationButtonTitle": "Activate number"
  },
  "buyNumberBaseFormService": {
    "errors": {
      "useCase": {
        "required": "Please enter your use case description.",
        "minLength": "Use case description has to be at least {minCharacters} characters long.",
        "maxLength": "This value is too long. It should have {maxCharacters} characters or less."
      }
    }
  },
  "buyNumberCaSenderTollFreeList": {
    "tollFree": {
      "title": "Toll-free number",
      "titleBadge": "Registration Required",
      "description": "8XX prefix numbers for U.S. and Canada verified for sending texts by the messaging aggregator.",
      "buttonText": "Get started",
      "options": [
        {
          "start": "Brand & campaign registration",
          "end": "required"
        },
        {
          "start": "Number cost:",
          "end": "per month",
          "monthFree": "1 month free,",
          "payAfter": " then {price} per month",
          "includedInPlan": "Free (included in your plan)"
        },
        "Traffic verified with the SMS aggregator",
        {
          "start": "Approval period",
          "end": "up to 5 weeks"
        },
        "2-way SMS, MMS and voice supported",
        "Minimum initial credit required: {price}",
        {
          "start": "Approval period: ",
          "end": "3-5 business days"
        }
      ]
    },
    "local": {
      "title": "Local Canadian number",
      "description": "10-digit local Canadian numbers that do not require verification by the local network carriers.",
      "buttonText": "Get started",
      "options": [
        {
          "start": "Brand & campaign registration",
          "end": "not required"
        },
        {
          "start": "Number cost:",
          "end": "per month"
        },
        "No verification required",
        "Instant activation",
        "2-way SMS, MMS and voice supported",
        "Minimum initial credit required: {price}"
      ]
    }
  },
  "buyNumberUsSenderTenDlcList": {
    "tollFree": {
      "title": "Toll-free number",
      "titleBadge": "Registration Required",
      "description": "8XX prefix numbers for U.S. and Canada verified for sending texts by the messaging aggregator.",
      "buttonText": "Get started",
      "options": [
        {
          "text": "Tax ID (EIN number) {not} required",
          "not": "not"
        },
        {
          "start": "Brand registration:",
          "end": "Free"
        },
        {
          "start": "Campaign registration:",
          "end": "Free"
        },
        {
          "start": "Number cost:",
          "end": "per month",
          "includedInPlan": "Free (included in your plan)"
        },
        {
          "start": "Traffic verified with the",
          "end": "SMS aggregator"
        },
        {
          "start": "Approval period",
          "end": "up to 5 weeks"
        },
        "2-way SMS, MMS and voice supported",
        "Minimum initial credit required: {price}",
        {
          "start": "Approval period: ",
          "end": "3-5 business days"
        }
      ]
    },
    "tenDlc": {
      "title": "10DLC number",
      "titleBadge": "Registration Required",
      "description": "10-digit local U.S. numbers approved by the mobile network operators for sending texts.",
      "buttonText": "Get started",
      "options": [
        {
          "start": "Tax ID (EIN number)",
          "end": "required"
        },
        {
          "start": "Brand registration + vetting:",
          "end": "Free"
        },
        {
          "start": "Campaign registration:",
          "end": " per month",
          "prefix": "1 month free",
          "suffix": ", then "
        },
        {
          "start": "Number cost:",
          "end": "per month",
          "includedInPlan": "Free (included in your plan)"
        },
        {
          "start": "Traffic whitelisted with the",
          "end": "mobile operators"
        },
        {
          "start": "Approval period",
          "end": "up to 5-7 business days"
        },
        "2-way SMS, MMS and voice supported",
        "Minimum initial credit required: {price}",
        {
          "start": "1 month free,",
          "end": " then {price} per month"
        }
      ]
    }
  },
  "buyNumberAllowedForBuy": {
    "numberType": "Number type",
    "chooseNumber": "Choose number",
    "yourBusinessUseCase": "Your business use case",
    "assignToAccount": "Assign to account",
    "theNumberOnlyAvailableToThisAccount": "Important! The number will only be available to this account.",
    "perMonth": "{price} per month",
    "perMonthDescription": "We will deduct this amount from your account balance each month. If your account balance becomes too low to renew the dedicated number, we leave right to cancel it permanently. You can cancel this number at any time.",
    "perMonthDescriptionForPlan": "One virtual number is included in your Professional plan. If you buy more numbers you will be billed extra for each virtual number according to the Textmagic services pricing.",
    "requiredRegisterFor10DLC": "Please note: You will be required to register for 10DLC if you plan to use local U.S. numbers for sending text messages",
    "readAbout10DLC": "Read more about 10DLC and related costs",
    "tollFreeNumber": "Toll-free number",
    "localNumber": "Local number",
    "local10DLCNumber": "Local 10DLC number",
    "localDescriptions": {
      "areaCodesAvailable": "All area codes available",
      "suitableTwoWayCommunication": "Suitable for two-way communication",
      "maxLengthMessage": "Max length 918 characters / message",
      "maxSendingSpeed": "Max sending speed 1 SMS / sec",
      "maxMessagesDay": "Max 500 messages a day"
    },
    "monthFree": "1 month free,",
    "payAfter": " then {price} per month",
    "includedInPlan": "Free (included in your plan)",
    "useCase": {
      "label": "Your business use case",
      "placeholder": "Describe your use case",
      "hint": "Explain how you intend to use this number. Max {maxCharacters} characters."
    }
  },
  "buyNumberCountryOnlyByRequestAlert": {
    "dueToLocalCarrierRequirementsPleaseContact": "Due to local carrier requirements, please contact {link} to apply for a number in this location.",
    "textMagicSupport": "Textmagic support"
  },
  "buyNumberLocalNumberFields": {
    "areaCode": "Area code",
    "availableNumber": "Available number"
  },
  "buyNumberNotAvailableAlert": {
    "weDonHaveAnyMoreNumbersAvailableForThisArea": "Sorry, but we don't have any more numbers available for this area. We will add more shortly but in the meantime why not try choosing a different area."
  },
  "buyNumberConnectByocAlert": {
    "text": "Connect your {twilio} or {vonage} numbers and send/receive messages within Textmagic."
  },
  "buyNumberCallForwarding": {
    "skipThisStep": "Skip this step"
  },
  "callForwarding": {
    "title": "Set up incoming call forwarding and greeting",
    "subTitle": "Forward incoming calls to chat or your number and add a greeting.",
    "form": {
      "number": "{provider} number",
      "your": "Your",
      "forwardCallsTo": "Forward calls to",
      "enterYourNumber": "Enter your mobile or landline number",
      "costPerMinute": "{cost} / minute{example}",
      "costPerMinuteExample": "(for example {minutes} minutes = {cost})",
      "forwardIncomingCalls": "Forward incoming calls to a landline or mobile phone",
      "inboundCallsPrices": "Inbound calls prices may vary based on the caller origin country."
    }
  },
  "callForwardingFormBuilderService": {
    "forwardCallsToValues": {
      "off": "Disabled",
      "chat": "SMS Chat",
      "phone": "Your number"
    },
    "pleaseEnterValidNumber": "Please enter a valid number."
  },
  "callForwardingFormLineGreeting": {
    "greeting": "Greeting",
    "addGreeting": "Add greeting",
    "hint": "Your customer will hear this recording while calling your {provider} number.",
    "hintFileTypes": "Supported file types: .wav or .mp3.",
    "chooseAudioModal": {
      "title": "Your audio recordings",
      "uploadBtnLabel": "Upload a new sound",
      "confirmButton": "Use recording"
    },
    "editGreeting": "Edit greeting",
    "disableGreeting": "Disable greeting"
  },
  "audioTableEmpty": {
    "title": "No custom sounds uploaded yet",
    "description": "Your uploaded audio files will appear here. Add your custom sounds to personalize your notifications or messages.",
    "button": "Upload a new sound"
  },
  "buyNumberWorkConstraintsAlert": {
    "numbersNotWorkForSmsVerificationOrLoginPurposes": "Textmagic numbers will NOT work for SMS verification or login purposes (bank accounts, social media accounts etc.). You can only receive text messages from your customers and staff using their mobile phones.",
    "learnMore": "Learn more."
  },
  "buyNumberSuccess": {
    "title": "Your dedicated number is now ready for use",
    "subTitle": "This is your number for text messaging with your customers, staff and partners.",
    "table": {
      "account": "Account",
      "dedicatedNumber": "Dedicated number",
      "inboundCallsWillBeForwardedTo": "Inbound calls will be forwarded to",
      "forwardAudio": "Greeting file",
      "forwardingPrice": "Forwarding price",
      "numberPrice": "Number price",
      "renewalDate": "Renewal date",
      "yourNumber": "Your number({number})",
      "SmsChat": "SMS Chat",
      "forwardingPriceInfo": "{currency}{price} / minute ({country})",
      "numberPriceInfo": "{price} / month (will be deducted from account balance)"
    }
  },
  "buyNumberGettingStartedTollFreeField": {
    "description": "8XX prefix numbers for U.S. and Canada verified for sending texts by the messaging aggregator."
  },
  "buyNumberGettingStartedTenDlcField": {
    "description": "10-digit local U.S. numbers approved by the mobile network operators for sending texts."
  },
  "tmPreviewPhone": {
    "form": {
      "inputPlaceholder": "Type your reply",
      "sendButton": "@:common.send"
    }
  },
  "customFieldForm": {
    "label": "Custom field name",
    "placeholder": "Enter name",
    "charactersHint": "Maximum 50 plain text characters",
    "maxLengthError": "Your custom field contains too many characters. Please make your custom field text shorter.",
    "requiredError": "Please enter a valid custom field name."
  },
  "customFieldModalCreate": {
    "title": "New custom field",
    "success": "The custom field has been successfully created."
  },
  "customFieldModalUpdate": {
    "title": "Edit custom field",
    "success": "The custom field has been successfully updated."
  },
  "tmNumberLabelModal": {
    "editTitle": "Edit number label",
    "createTitle": "New number label",
    "field": {
      "number": {
        "label": "Number"
      },
      "label": {
        "label": "Label",
        "placeholder": "Enter number label"
      }
    }
  },
  "numberLabelFormService": {
    "errors": {
      "label": {
        "max": "Max label length is 30 symbols."
      }
    }
  },
  "myContactsEmptyState": {
    "title": "Import your contacts to Textmagic",
    "description": "Supported file types for import are Excel (.xls or .xlsx) and CSV (.csv).",
    "button": "Add contact",
    "importButton": "Import contacts"
  },
  "inboundCallsEmptyState": {
    "title": "You have no inbound calls",
    "description": "Receive calls to your virtual numbers using SMS chat and respond to your contacts faster.",
    "button": "View SMS chat"
  },
  "inboundCallsModalsDelete": {
    "title": "Delete inbound call",
    "text": "Are you sure you would like to delete selected inbound call? This action cannot be undone."
  },
  "outboundCallsEmptyState": {
    "title": "You have no outbound calls",
    "description": "Receive calls to your virtual numbers using SMS chat and respond to your contacts faster.",
    "button": "View SMS chat"
  },
  "outboundCallsModalsDelete": {
    "title": "Delete outbound call",
    "text": "Are you sure you would like to delete selected outbound call? This action cannot be undone."
  },
  "forwardedCallsEmptyState": {
    "title": "You have no forwarded calls",
    "description": "Set up call forwarding and forward incoming calls from your virtual numbers to any phone number.",
    "button": "View my numbers"
  },
  "forwardedCallsModalsDelete": {
    "title": "Delete forwarded call",
    "text": "Are you sure you would like to delete selected forwarded call? This action cannot be undone."
  },
  "exportModal": {
    "title": "Send the download link to an email",
    "description": "Export of data can take some time. Please enter email address, and we will send a download link as soon as possible.",
    "emailLabel": "Email address",
    "emailPlaceholder": "Enter email address",
    "emailErrorRequired": "Please enter a valid email address.",
    "success": "Your data is now being prepared for download. We will send a download link to your email address as soon as possible.",
    "selectFileFormat": "Select file format"
  },
  "ContactListDetailsTopbar": {
    "search": "Search contacts",
    "sendSMS": "Send SMS",
    "addContacts": "Add contacts",
    "moveCopy": "Move/Copy",
    "removeFromList": "Remove from list",
    "editListDetails": "Edit list details"
  },
  "myContactsTableDropdown": {
    "editContact": "Edit contact",
    "blockContact": "Block contact",
    "unsubsribe": "Unsubscribe contact"
  },
  "ContactListDetailsDropdown": {
    "editContact": "Edit contact",
    "blockContact": "Block",
    "unsubsribe": "Unsubscribe",
    "removeFromTheList": "Remove from list"
  },
  "subAccountsEmptyState": {
    "title": "Add sub-accounts for your team members",
    "description": "Equip your entire company with Textmagic by giving employees sub-accounts and sharing your existing credit balance with them.",
    "button": "Invite sub-accounts"
  },
  "usersWorkflowClosedEmptyState": {
    "title": "You have no closed user accounts",
    "description": "When you close user accounts their access to the workspace will be revoked, and these users will be displayed here."
  },
  "usersWorkflowActiveEmptyState": {
    "title": "Invite users for a better teamwork ",
    "description": "Invite team members to collaborate in one shared workspace. Enjoy access to shared messaging channels, messaging data, and contacts, all under a unified billing account for seamless communication.",
    "button": "Invite users"
  },
  "subAccountsTableTopBar": {
    "invite": "Invite sub-accounts",
    "import": "Import"
  },
  "subAccountsLoginCell": {
    "logInAsUser": "Log in as user"
  },
  "subAccountsTableTabs": {
    "invited": "Invited",
    "active": "Active",
    "closed": "Closed",
    "import": "Import"
  },
  "bulkSentSmsTabs": {
    "summary": "Summary",
    "delivered": "Delivered",
    "sent": "Sent",
    "failed": "Failed",
    "rejected": "Rejected"
  },
  "subAccountsTableDropdown": {
    "logIn": "Log in as this account",
    "passwordReset": "Reset password",
    "passwordResetSuccessMessage": "A password reset link has been successfully sent.",
    "edit": "Edit sub-account",
    "close": "Close sub-account",
    "reopen": "Reopen sub-account",
    "resend": "Resend invitation"
  },
  "usersWorkflowTableDropdown": {
    "passwordReset": "Reset password",
    "passwordResetSuccessMessage": "A password reset link has been successfully sent.",
    "edit": "Edit user",
    "close": "Close user account",
    "reopen": "Reopen user"
  },
  "usersWorkflowTableInvitedDropdown": {
    "resend": "Resend invitation",
    "deleteInvite": "Delete invite"
  },
  "removeContactFromTheListAction": {
    "title": "Remove contact from list",
    "text": "{fullName} {phone} will be removed from this list.\n\n Are you sure you would like to remove it?",
    "submitText": "Remove contact",
    "successText": "The selected contact has been successfully removed from this list."
  },
  "bulkRemoveContactFromTheListAction": {
    "submitText": "Remove contacts",
    "title": "Remove contacts from list"
  },
  "bulkRemoveFromListText": {
    "text": "Are you sure you would like to remove the selected contacts from this list?"
  },
  "UnsubsribeContactModal": {
    "blockIncomingText": "Also block inbound messages from this number.",
    "unsubscribe": "Unsubscribe",
    "unsubscribing": "Unsubscribing",
    "modalTitle": "Unsubscribe contact",
    "successText": "The contact has been successfully unsubscribed",
    "text": " will be permanently removed from your lists. All future messages to this number will be rejected by Textmagic. This applies to all your sub-accounts too."
  },
  "unsubscribeContactWithEmailModal": {
    "title": "Unsubscribe contact",
    "text": "You are about to unsubscribe {contact} from receiving messages. Select the communication channels you want to unsubscribe.",
    "blockIncomingText": "Block incoming SMS from this number",
    "emailAlreadyUnsubscribed": "This email has already been unsubscribed.",
    "unsubscribe": "Unsubscribe",
    "unsubscribing": "Unsubscribing",
    "successText": "The contact has been successfully unsubscribed"
  },
  "unsubscribeContactWithEmailModalCheckbox": {
    "willBeUnsubscribed": "Will be unsubscribed"
  },
  "invoicesEmptyState": {
    "title": "You have no invoices yet",
    "description": "After you buy credit, your invoices will be displayed here.",
    "button": "Buy credits"
  },
  "invoicesTableDropdown": {
    "forwardViaEmail": "Forward via email"
  },
  "contactDeleteModal": {
    "title": "Delete contact",
    "text": [
      "{who} will be deleted permanently.",
      "Currently, this contact is the requester in {tickets}. All tickets associated with this contact will be marked as {closed}. This action cannot be undone.",
      "Are you sure you would like to delete it?"
    ],
    "tickets": "ticket | tickets",
    "closed": "Closed"
  },
  "contactsChangeEmailConfirmation": {
    "title": "Change contact email",
    "text": [
      "You are about to change {name} email.",
      "Currently, this contact is the requester in {tickets}. All tickets associated with this contact will be marked as {closed}. This action cannot be undone.",
      "Are you sure you would like to change the email?"
    ],
    "tickets": "ticket | tickets",
    "closed": "Closed"
  },
  "BulkShareContactListConfirmation": {
    "submitText": "Share list",
    "submitLoadingText": "Sharing...",
    "successTextPlural": "The lists have been successfully updated.",
    "successTextSingular": "The list has been successfully updated.",
    "text": "After sharing the selected lists, your sub-accounts will be able to view, edit and delete the contacts in these lists. Are you sure you want to continue?"
  },
  "MakeListPrivateConfirmation": {
    "submitText": "Make private",
    "title": "Make list private",
    "successText": "Selected list has been successfully made private",
    "text": "After making the selected list private, your sub-accounts will not be able to view, edit and delete the contacts in this list. Are you sure you want to continue?"
  },
  "senderSettingsCancelPhoneNumberConfirm": {
    "title": "Cancel number renewal",
    "text": "Are you sure you want to cancel the subscription for the dedicated number {phone}? You will still be able to use this number until {date}. After that, the number will expire.",
    "cancel": "Keep number",
    "submit": "Cancel number",
    "cancelSuccess": "The selected number has been successfully canceled.",
    "successMessage": "The selected number subscription has been successfully scheduled to expire."
  },
  "CancelPhoneTollFreeNumberConfirmation": {
    "title": "Cancel toll-free number",
    "text": "Are you sure you want to cancel the toll-free number {phone}?",
    "cancelButtonText": "Keep number",
    "submitButtonText": "Cancel number"
  },
  "contactDetails": {
    "blocked": "blocked",
    "unsubscribe": "Unsubscribe",
    "sharedBy": "Shared by {fullName}"
  },
  "composeHeader": {
    "sms": "SMS",
    "email": "Email"
  },
  "composeEmail": {
    "title": "Compose an email",
    "info": {
      "title": "Get started with email campaigns",
      "text": "Send email campaigns to large groups, customize your content, and monitor performance - all in one platform. Whether you're sharing alerts, newsletters, or updates, this feature makes it simple to connect with your audience.",
      "campaigns": "Send campaigns",
      "delivery": "Track delivery & engagement",
      "replies": "Manage replies",
      "requestEarlyAccess": "Request early access"
    },
    "faq": {
      "title": "Email campaigns FAQ",
      "benefits": {
        "title": "What are the benefits of the email campaigns feature?",
        "text": "This feature lets you send mass emails, compose and personalize content with dynamic data fields, and track delivery and engagement - all in one place. It’s designed to help you connect with your audience more quickly and efficiently and adds another communication channel to the Textmagic platform."
      },
      "whenAvailable": {
        "title": "When will the email campaigns feature be available?",
        "text": "The feature is coming soon! You can {requestEarlyAccess} to become one of the first users of the email campaigns feature before the other customers.",
        "requestEarlyAccess": "request early access"
      },
      "howToStart": {
        "title": "How to get started with email campaigns?",
        "text": "It is very easy to get started with email campaigns.",
        "step1": "Set up an email sender - verify a domain or a single email address.",
        "step2": "Compose and prepare your first campaign.",
        "step3": "Send the campaign or schedule for later.",
        "step4": "Review the results and track replies."
      },
      "verify": {
        "title": "Why do I need to verify my domain or email address?",
        "text": "Verification ensures your emails reach your recipients’ inboxes and reduces the chances of being flagged as spam. It also helps maintain a professional reputation and improves security. Textmagic focuses on combatting spam and fraudulent emails."
      },
      "recipients": {
        "title": "Where will my recipients’ replies go?",
        "text": "There are two ways how to get replies to your email campaigns.",
        "tickets": {
          "title": "Textmagic Tickets",
          "text": "Set your {inbox} as a “Reply-to” address when creating your email campaign. This way all replies will end up in Textmagic Tickets section and you can assign them to your team members, tag them, and continue your conversations directly from Textmagic app.",
          "inbox": "Textmagic inbox"
        },
        "inbox": {
          "title": "Your custom inbox (outside the Textmagic app)",
          "text": "If you set your own “Reply to” email address, then all replies will go directly to your selected inbox, which means that you will not be able to track replies in the Textmagic app."
        },
        "customEmail": {
          "text": "All replies will go directly to your selected inbox, which means that you will not be able to track replies in the Textmagic app."
        }
      },
      "comply": {
        "title": "How to stay compliant with industry standards when sending email campaigns?",
        "consent": {
          "title": "Get consent before sending",
          "text": "Only email people who have explicitly opted in to receive communication from you. This aligns with regulations like the {canSpamAct}."
        },
        "unsubscribe": {
          "title": "Provide an unsubscribe option",
          "text": "Include a visible and functional unsubscribe link in every email. Make sure the process is easy and immediate."
        },
        "verifiedDomains": {
          "title": "Use verified domains",
          "text": "Always send emails from a verified email domain to ensure deliverability and credibility."
        },
        "misleadingSubject": {
          "title": "Avoid misleading subject lines",
          "text": "Use clear and honest subject lines that accurately reflect the content of your email. Misleading subjects violate the {canSpamAct}."
        },
        "businessInformation": {
          "title": "Include your business information",
          "text": "Add your company’s name, physical mailing address, or registered P.O. box in every email footer, as required by the {canSpamAct}."
        },
        "emailLists": {
          "title": "Avoid buying email lists",
          "text": "Use contact lists where recipients have willingly signed up to receive your emails. Purchased lists often lead to complaints and non-compliance issues."
        },
        "segmentAudience": {
          "title": "Segment your audience",
          "text": "Send relevant messages based on audience preferences to avoid overloading recipients with unwanted emails."
        },
        "spam": {
          "title": "Avoid spam triggers",
          "text": "Refrain from using phrases or words that may trigger spam filters, such as \"Get rich quick\" or \"Act now,\" to improve email deliverability."
        },
        "trackComplaints": {
          "title": "Track complaints and bounces",
          "text": "Monitor metrics like bounce rates and spam complaints. High rates can indicate poor compliance or list quality."
        },
        "usRegulations": {
          "title": "Follow U.S. email regulations",
          "text": "The {canSpamAct} applies to all commercial emails sent in or to the U.S. Familiarize yourself with its rules to avoid penalties."
        },
        "text": "These steps ensure compliance while helping you build a trusted connection with your audience.",
        "canSpamAct": "CAN-SPAM Act"
      }
    }
  },
  "composeMessage": {
    "title": "Compose a text message",
    "to": "To",
    "scheduleMessage": "Schedule message",
    "previewMessage": "Preview message",
    "previewAndSendMessage": "Preview & send",
    "previewAndScheduleMessage": "Preview & schedule",
    "sendNow": "Send now",
    "previewInCalendar": "Preview in calendar",
    "cancel": "Cancel",
    "from": "From",
    "fromPlaceholder": "Start typing a number or contact name",
    "message": "Message",
    "messagePartsTooltip": "Your message includes {parts} parts now.",
    "schedule": "Schedule",
    "successSend": "Your message has been successfully sent to {count} recipient. | Your message has been successfully sent to {count} recipients",
    "attachFileInfo": "You can upload files that are up to 10 MB in size.___A clickable hyperlink will be included in your SMS where recipients can view / download your file.___Attachments will be stored on Textmagic's servers.",
    "attachMmsFileInfo": "You can send images via MMS that are up to 3.5 MB and other files up to 525 KB in size.___A multimedia message (MMS) will be sent to your recipient's phone including the attached file.___Attachments will be stored on Textmagic's servers.",
    "buyCredit": "Buy credit",
    "notFoundValidRecipients": "One or more of the recipients' numbers are invalid. Please remove invalid phone numbers and try again."
  },
  "composeScheduleSection": {
    "firstSendDate": "First send date",
    "hourFormat": "24-hour format",
    "timezone": "Timezone",
    "timezoneDescription": "Please note that Textmagic automatically considers daylight saving time (DST) changes."
  },
  "composeScheduleRepeatTypes": {
    "none": "Doesn’t repeat",
    "hourly": "Hourly",
    "daily": "Daily",
    "weekly": "Weekly",
    "monthly": "Monthly",
    "yearly": "Yearly"
  },
  "composeEndTypes": {
    "never": "Never",
    "after": "After",
    "date": "On date"
  },
  "composeOrdinalDays": {
    "first": "First",
    "second": "Second",
    "third": "Third",
    "fourth": "Fourth",
    "last": "Last"
  },
  "messagesSendForm": {
    "recipientsPlaceholder": "Start typing a number or contact name",
    "recipientsErrorRequired": "Please enter at least one recipient.",
    "recipientsErrorInvalidNumber": "Please enter a valid mobile phone number.",
    "messagePlaceholder": "Type your message"
  },
  "composeCharactersHint": {
    "unicode": "Unicode characters:",
    "common": "Characters:"
  },
  "composePartsHint": {
    "label": "Parts:"
  },
  "messagesSendFormWarnings": {
    "pleaseNote": "Please note:",
    "balanceLow": "You need to buy credit to send this message. Suitable credit amount: {amount}.",
    "canadianNumber": "We cannot guarantee successful delivery of marketing messages, alerts, and notifications to Canadian numbers because this content should originate from a short code, that we do not offer at the moment.",
    "maxLength": "Your message contains too many characters. Please make your message text shorter.",
    "bulkSession": "You are about to send a large bulk session. Please preview your message before sending.",
    "shortLink": "The carriers may reject messages with short links. To ensure the SMS delivery, please replace {domain} with a regular long link.",
    "byocPlanExceedLimit": "The following SMS session cannot be sent because it exceeds your BYOC plan’s message limit. To proceed, please {upgrade} or reduce the number of recipients or SMS parts.",
    "byocPlanUpgrade": "upgrade your BYOC plan",
    "unicode": "Your message contains Unicode characters ({unicodeChars}{others}), which reduce the max length of each message part and may increase the total cost of sending. {learnMore}",
    "unicodeOthers": "and {othersLength} other|and {othersLength} others",
    "multipleParts": "Your message contains {parts}, increasing the total cost. To reduce the number of message parts, shorten your text. {learnMore}",
    "parts": "{count} parts",
    "senderId": "Your message will be sent from an alphanumeric Sender ID. This means that users {canNotReply} to you or {optOut}.",
    "canNotReply": "will not be able to reply",
    "optOut": "opt out"
  },
  "composeSideButton": {
    "contacts": "Contacts",
    "lists": "Lists",
    "segments": "Segments",
    "frequentlySent": "Frequently sent",
    "senderSettings": "Sender settings",
    "insertTemplate": "Insert template",
    "insertTag": "Add dynamic field",
    "attachFile": "Attach file",
    "noContacts": "You have no contacts here yet."
  },
  "composePreviewModal": {
    "modalTitle": "Message preview for 1 recipient (out of {count} total)",
    "modalTitleSingleMessage": "Message preview",
    "backToEditing": "Back to editing",
    "submit": "Send {number} message | Send {number} messages",
    "submitSchedule": "Schedule message",
    "submitScheduleCount": "Schedule {number} message | Schedule {number} messages",
    "approximatePrice": "Approximate cost is displayed because you have tags in your message.",
    "bulkSession": "You are about to send a bulk session. Please review your message carefully and confirm.",
    "table": {
      "from": "From",
      "to": "To",
      "messageContent": "Message content",
      "totalCharacters": "Total characters",
      "totalCharactersNumber": "{number} character | {number} characters",
      "messageParts": "Message parts",
      "messagePartsNumber": "{number} part | {number} parts",
      "costOfThisMessage": "Cost of this message",
      "totalSessionCost": "Total session cost"
    }
  },
  "composePhonePreviewModal": {
    "modalTitle": "Preview text message",
    "backToEditing": "Back to editing",
    "submit": "Send {number} message | Send {number} messages",
    "submitSchedule": "Schedule message",
    "submitScheduleCount": "Schedule {number} message | Schedule {number} messages",
    "bulkSession": "You are about to send a bulk session. Please review your message carefully and confirm.",
    "preview": "Preview {index} of {total}"
  },
  "composeSuccessSendMessages": {
    "scheduledMessageDetails": "Scheduled message details",
    "sentTextMessageDetails": "Outbound text message details",
    "successAlertTitle": "Your message has been successfully sent to {quantity} recipient. | Your message has been successfully sent to {quantity} recipients.",
    "editSmsAndResend": "Edit SMS and resend",
    "viewSentMessages": "View outbound messages"
  },
  "composeSuccessSendScheduled": {
    "scheduleNewMessage": "Schedule a new message",
    "viewScheduledMessages": "View scheduled messages",
    "viewInCalendar": "View in calendar",
    "yourMessageHasBeenScheduledSuccessfully": "Your message has been scheduled successfully. Next send time is on {dateTime}."
  },
  "accountDetails": {
    "title": "My account",
    "editAccountDetails": "Edit account details",
    "username": "Username",
    "accountOwner": "Account owner",
    "role": "Role",
    "contactEmail": "Contact email",
    "mobileNumber": "Mobile number",
    "timeZone": "Time zone",
    "timeDisplayFormat": "Time display format",
    "registrationCountry": "Registration country",
    "successSendVerificationOnEmail": "We have sent you a verification email. Please check your inbox.",
    "resendLinkIn": {
      "text": "Resend link in {minutes}{seconds}",
      "minutes": "{minutes}m",
      "seconds": "{seconds}s"
    },
    "close": {
      "label": "Closing your account",
      "text": "If you’re having issues or have questions about Textmagic, please {contactSupport}, and if you want to close your account, please use this link to {closeAccount}.",
      "contactSupport": "contact Textmagic support",
      "closeAccount": "close your account"
    }
  },
  "accountDetailsEditModal": {
    "title": "Edit account details",
    "formFields": {
      "userName": {
        "label": "Username",
        "placeholder": "Enter username",
        "formErrors": {
          "required": "Please enter username."
        }
      },
      "firstName": {
        "label": "First Name",
        "placeholder": "Enter first name",
        "formErrors": {
          "required": "Please enter first name."
        }
      },
      "lastName": {
        "label": "Last Name",
        "placeholder": "Enter last name",
        "formErrors": {
          "required": "Please enter last name."
        }
      },
      "email": {
        "label": "Contact email",
        "placeholder": "Enter contact email",
        "formErrors": {
          "required": "Please enter contact email."
        }
      },
      "phone": {
        "label": "Mobile number",
        "placeholder": "Enter mobile number"
      },
      "timezone": {
        "label": "Time zone"
      },
      "displayTimeFormat": {
        "label": "Time display format"
      }
    }
  },
  "phoneVerificationModal": {
    "title": "We sent you and SMS with a 6-digit code to:",
    "didntReceiveAnSms": "Didn’t receive an SMS?",
    "sendSmsAgain": "Send SMS again",
    "cancelVerification": "Cancel verification",
    "confirmYourPhoneNumberBySms": "Please confirm your mobile phone number by SMS.",
    "pleaseEnterCodeHere": "Please enter the code here",
    "verifyNumber": "Verify number",
    "verifyingNumber": "Verifying number...",
    "errors": {
      "codeIsIncorrect": "The 6-digit number you entered is incorrect. Please try again."
    },
    "resendSuccess": "We have sent you a verification SMS. Please check your phone."
  },
  "vatInformation": {
    "title": "EU VAT information",
    "edit": "Edit VAT information",
    "infoMessage": "VAT zero-rated: Article 56.1 of Council Directive 2006/112/EC applies. Subject to reverse charge in the country of receipt.",
    "vatNumber": "EU VAT number",
    "addVatCta": "Add VAT number"
  },
  "vatInformationEditModal": {
    "title": "EU VAT information",
    "success": "EU VAT information has been successfully updated.",
    "formFields": {
      "vatNumber": {
        "label": "EU VAT number",
        "placeholder": "Please enter EU VAT number",
        "formErrors": {
          "invalid": "Please enter a valid VAT number."
        }
      }
    }
  },
  "inviteSubAccountModal": {
    "title": "Invite new sub-account",
    "submitButton": "Send invitation",
    "success": "The invitation email has been sent successfully.",
    "eachUserSeatMonthly": "Each user seat costs {cost} per month.",
    "eachUserSeatYearly": "Each user seat costs {cost} per month (billed yearly).",
    "inviteTeamMembers": "Invite team members to collaborate in a shared workspace with access to shared messaging channels, data, and contacts.",
    "formFields": {
      "email": {
        "label": "Email address",
        "placeholder": "Enter email address",
        "info": "Activation link will be sent to your team member’s email."
      },
      "role": "Role"
    },
    "addAnother": "Add another"
  },
  "inviteUsersModal": {
    "title": "Invite users"
  },
  "inviteSubAccountsModal": {
    "title": "Invite sub-accounts"
  },
  "switchSubAccountModal": {
    "title": "Switch sub-account",
    "manage": "Manage sub-accounts"
  },
  "resendInvitationSubAccountModal": {
    "title": "Resend invitation",
    "description": "Are you sure you want to send invitation to this sub-account? You can change email address for this sub-account if needed.",
    "descriptionForUsers": "Are you sure you want to send invitation to this user? You can change email address for this user if needed.",
    "submitButton": "Resend invitation",
    "success": "An activation link has been sent to {email}",
    "formFields": {
      "email": {
        "label": "Email",
        "placeholder": "email{'@'}example.com"
      }
    }
  },
  "fieldTagsList": {
    "addLists": "Add lists",
    "fieldTagsList": "fieldTagsList",
    "createNewList": "Create new list",
    "searchLists": "Search lists"
  },
  "fieldSelectSearchSenderSettings": {
    "byoc": "{provider} (BYOC)",
    "byocNumbers": "BYOC numbers",
    "dedicated": "Textmagic numbers",
    "whatsApp": "WhatsApp",
    "widgets": "Widgets",
    "facebook": "Facebook pages",
    "instagram": "Instagram accounts",
    "senderIds": "Sender IDs",
    "userNumbers": "Your mobile phone",
    "shared": "Shared reply numbers",
    "defaultCallerSelectValue": "Default sender settings (recommended)",
    "voiceEnabled": "Voice-enabled numbers",
    "defaultCallerSelectValueShort": "Default sender settings",
    "buyTextmagicDedicatedNumber": "- Buy a Textmagic dedicated number -",
    "connectCPaaSProvider": "- Connect a CPaaS provider -",
    "applyForSenderID": "- Apply for a Sender ID -",
    "addYourMobileNumberUnderAccount": "- Add your mobile number under account -",
    "verifyYourMobileNumber": "- Verify your mobile number -",
    "senderIDisNotSupported": "- Sender ID is not supported in this country -",
    "noAvailableSenderIds": "- No available Sender IDs -",
    "ownMobileSettingIsNotSupported": "- Own mobile setting is not supported in this country -",
    "sharedMobileSettingIsNotSupported": "- Shared mobile setting is not supported in this country -",
    "connectWhatsappNumber": "- Connect WhatsApp number to Textmagic -"
  },
  "fieldSelectSenderPhone": {
    "displayCustomNumber": "Display custom number",
    "selectNumber": "- Select number -",
    "errors": {
      "phoneRequired": "Please select a phone number."
    }
  },
  "fieldSelectSearchInbox": {
    "selectInbox": "- Select an inbox -",
    "useCustomEmail": "Use custom email address",
    "connected": "Connected inboxes",
    "connectNew": "- Connect new inbox -",
    "customEmailPlaceholder": "Type email here",
    "errors": {
      "inbox": "Please select a inbox."
    }
  },
  "fieldSelectSearchFacebookPage": {
    "selectFacebookPage": "- Select a Facebook page -",
    "useCustomFacebookPage": "Use custom page",
    "facebookPages": "Facebook pages",
    "connectNew": "- Connect Facebook page to Textmagic -",
    "customFacebookPagePlaceholder": "Add link to Facebook page",
    "searchFacebookPages": "Search Facebook pages",
    "errors": {
      "facebookPage": "Please select a Facebook page.",
      "customFacebookPage": "Please enter a valid Facebook page link."
    }
  },
  "fieldSelectSearchInstagramAccount": {
    "selectAccount": "- Select an Instagram account -",
    "useCustomAccount": "Use custom account",
    "accounts": "Instagram accounts",
    "connectNew": "- Connect Instagram account to Textmagic -",
    "customAccountPlaceholder": "Add link to Instagram account",
    "searchAccounts": "Search Instagram accounts",
    "errors": {
      "account": "Please select an Instagram account.",
      "customAccount": "Please enter a valid link to Instagram account."
    }
  },
  "fieldWeekDaysTimeRangeLists": {
    "errors": {
      "endTimeBeforeStartTime": "The end time must be later than the start time.",
      "intervalsOverlap": "Time intervals should not overlap within a day."
    }
  },
  "contactsImportedEmptyState": {
    "title": "This import session had no successfully imported contacts",
    "description": "Please check the “Failed to import” tab to fix possible validation errors."
  },
  "contactsDuplicatesEmptyState": {
    "title": "This import session had no excluded duplicate numbers",
    "description": "There is nothing to fix, everything is alright here."
  },
  "contactsFailedEmptyState": {
    "title": "This import session had no failed rows",
    "description": "There is nothing to fix, everything is alright here."
  },
  "contactsExcludedEmptyState": {
    "title": "This import session had no excluded rows",
    "description": "There is nothing to fix, everything is alright here."
  },
  "noteFormService": {
    "note": {
      "placeholder": "Type your note",
      "button": "Add note",
      "buttonSave": "Save note"
    },
    "formErrors": {
      "required": "Field is required."
    }
  },
  "deleteNoteConfirmation": {
    "title": "Delete note",
    "text": "Are you sure you would like to delete this note? This action cannot be undone later."
  },
  "contactDetailsNoteAdd": {
    "text": "Click to add an internal note"
  },
  "contactDetailsNotesList": {
    "title": "Notes",
    "showMore": "Load {count} more",
    "noNotesYet": "No notes yet"
  },
  "contactDetailsScheduleCard": {
    "title": "Scheduled text message",
    "scheduled": "Scheduled",
    "paused": "Paused"
  },
  "contactDetailsSchedulesList": {
    "title": "No scheduled messages yet"
  },
  "contactDetailsNotesAction": {
    "create": "The note has been successfully added.",
    "update": "The note has been successfully updated.",
    "updateSome": "Edited notes has been successfully updated.",
    "delete": "The selected note has been successfully deleted."
  },
  "contactDetailsSidebar": {
    "title": "Details",
    "addCustomField": "Add new custom field",
    "manageCustomField": "Manage custom fields",
    "placeholder": "Add {field}",
    "fields": {
      "fullName": "Full name",
      "phone": "Phone",
      "whatsappPhone": "WhatsApp",
      "email": "Email",
      "companyName": "Company",
      "lists": "Lists",
      "ownerId": "Contact owner",
      "tags": "Tags"
    },
    "systemFields": {
      "source": {
        "label": "Source",
        "manual": "Manual",
        "import": "Import",
        "api": "API",
        "form": "Form",
        "liveChat": "Live chat",
        "whatsapp": "WhatsApp",
        "facebook": "Facebook",
        "ticket": "Ticket",
        "instagram": "Instagram",
        "sms": "SMS"
      }
    },
    "leavePage": "You have unsaved changes. If you continue, your changes will be discarded. Are you sure you want to continue?",
    "contactInfo": "Contact info",
    "customFields": "Custom fields",
    "newCustomField": "New custom field",
    "emptyState": {
      "title": "No search results found",
      "description": "Please try a different keyword"
    }
  },
  "unsavedFormRouteLeave": "You have unsaved changes. If you continue, your changes will be discarded. Are you sure you want to continue?",
  "contactDetailsHeader": {
    "addNote": "Add note",
    "details": "Details",
    "endCall": "End call",
    "call": "Call"
  },
  "contactDetailsModalUnsavedNote": {
    "title": "Unsaved changes",
    "description": "You have unsaved changes in the internal note, that you have been editing. Would you like to save these changes?",
    "submitButton": "Save changes",
    "cancelButton": "Keep editing"
  },
  "contactDetailsCustomFieldsEmpty": {
    "title": "No custom data fields",
    "description": "Create a new custom field to store more data",
    "buttonTitle": "New custom field"
  },
  "contactDetailsContentLoader": {
    "title": "Loading contact information..."
  },
  "whiteBlockContentLoader": {
    "description": "Please wait while we are loading your information."
  },
  "audioDeleteModal": {
    "title": "Delete recording",
    "text": "Are you sure you would like to delete this recording? This action cannot be undone.",
    "deleteSuccess": "The audio file has been successfully deleted."
  },
  "selectingContactsModal": {
    "title": "Contacts"
  },
  "selectingContactsProgressModal": {
    "modalTitle": "Please wait...",
    "title": "We are currently working on grouping your contacts. Please wait..",
    "progressInfo": "{progress} out of {total} contacts processed"
  },
  "selectingContactsListsModal": {
    "title": "Lists"
  },
  "selectingSegmentsModal": {
    "title": "Segments"
  },
  "addContactsChooseSourceModal": {
    "title": "Add contacts",
    "manuallyLabel": "Create a new contact",
    "manuallyDescription": "Add contacts one by one",
    "listLabel": "Add from existing contacts",
    "listDescription": "Copy contacts from other Lists"
  },
  "contactListMoveCopyModal": {
    "titleMove": "Move contacts",
    "titleCopy": "Copy contacts",
    "description": "Please choose from the following options:",
    "moveTitle": "Move selected contacts to a new list",
    "moveDescription": "Contacts are removed from the current list and added to a new one.",
    "copyTitle": "Copy selected contacts to a new list",
    "copyDescription": "Contacts remain in the current list and will be added to a new one.",
    "success": {
      "move": "The selected contacts have been successfully moved to the new list.",
      "copy": "The selected contacts have been successfully copied to the new list."
    }
  },
  "myContactsTopbar": {
    "newContact": "New contact",
    "import": "Import",
    "addToList": "Add to list"
  },
  "myContactsFilters": {
    "isEmpty": "Is empty",
    "fields": {
      "tags": {
        "label": "Tags"
      },
      "country": {
        "label": "Country"
      },
      "ownerId": {
        "label": "Contact owner"
      },
      "createdDate": {
        "label": "Date created"
      },
      "lastUpdated": {
        "label": "Last updated"
      },
      "phone": {
        "label": "Phone"
      },
      "whatsappPhone": {
        "label": "WhatsApp"
      },
      "email": {
        "label": "Email"
      },
      "company": {
        "label": "Company"
      },
      "createdBy": {
        "label": "Created by"
      },
      "lists": {
        "label": "Lists"
      },
      "source": {
        "label": "Source"
      }
    }
  },
  "myContactsFilterBar": {
    "searchContactOwner": "Search contact owner",
    "startTypingEmail": "Start typing email",
    "startTypingCompany": "Start typing company",
    "startTypingPhone": "Start typing phone",
    "searchUsers": "Search users"
  },
  "addContactsToList": {
    "title": "Add contacts to list",
    "button": "Add to list",
    "confirmModal": "The selected {count} {contact} will be added to the following list:\n{list}. Are you sure you would like to continue?",
    "contact": "contact | contacts",
    "success": "Selected contacts have been successfully copied to the new list."
  },
  "senderIdApplyModal": {
    "title": "Apply for Sender ID",
    "submit": "Apply now",
    "loading": "Applying...",
    "senderId": {
      "label": "Enter your sender ID:",
      "placeholder": "Enter sender ID name",
      "hint": [
        "Maximum 11 characters and at least 1 text character. A-Z and 0-9. No special symbols or spaces.",
        "Generic Sender IDs, e.g. Verify, SMS, INFO are not allowed."
      ]
    },
    "explanation": {
      "label": "Please explain your use case:",
      "placeholder": "Enter your message",
      "hint": [
        "To increase the speed of your request, please provide a brief description of your business intends to use this Sender ID.",
        "Max 300 characters."
      ]
    },
    "price": {
      "label": "Price:",
      "price": "{price} per month",
      "hint": "You can cancel the subscription at any time."
    },
    "alert": {
      "text": "Sorry, but you do not have enough credit to buy this Sender ID. Please {link} now to continue."
    },
    "createdForAustralia": "Thank you for requesting the Sender ID. We will process your order and assign the requested Sender ID within 5-7 business days.",
    "weHaveReceivedYourSenderIdRequest": "We have received your Sender ID request. You will be notified via email once your new Sender ID has been approved."
  },
  "senderIdApplyFormService": {
    "errors": {
      "senderId": {
        "required": "Please enter a valid sender ID.",
        "min": "This value is too short. It should have 3 characters or more.",
        "letters": "In order to avoid number spoofing we require at least one text character in all Sender IDs."
      },
      "explanation": "Please explain why you need this Sender ID.",
      "maxLength": "This value is too long. It should have 300 characters or less."
    }
  },
  "dateFilter": {
    "All": "All time",
    "Today": "Today",
    "Yesterday": "Yesterday",
    "This week": "This week",
    "Last week": "Last week",
    "This month": "This month",
    "Last month": "Last month",
    "Previous month": "Last month",
    "Last 30 days": "Last 30 days",
    "Last 60 days": "Last 60 days",
    "Pick date range": "Custom"
  },
  "dateFilterCustom": {
    "from": "From",
    "to": "To",
    "show": "Show",
    "close": "Close"
  },
  "historyStatusAlert": {
    "warningMessage": "This contact is {status}. You cannot send messages to this contact anymore."
  },
  "historyTableSharedTopButtons": {
    "blockContact": "Block contact",
    "unblockContact": "Unblock contact"
  },
  "contactDetailsActivityNoResult": {
    "title": "There are no results with the applied filters",
    "description": "Please try to change your search request or filters"
  },
  "contactDetailsActivityBase": {
    "noEventsYet": "No events yet"
  },
  "contactsDetailsActivities": {
    "title": "Activity log",
    "scheduled": "Scheduled",
    "sent": "Sent SMS",
    "received": "Inbound SMS",
    "call": "Calls",
    "whatsapp": "WhatsApp",
    "livechat": "Live chat",
    "facebook": "Facebook",
    "instagram": "Instagram",
    "searchActivities": "Search activities",
    "tickets": "Tickets"
  },
  "activityCardCall": {
    "to": "To {phone}",
    "from": "From {phone}",
    "titleOutbound": "Outbound call",
    "titleForwarded": "Forwarded call",
    "titleInbound": "Inbound call",
    "titleMissed": "Missed call"
  },
  "activityCardReceivedSms": {
    "title": "Inbound text message",
    "titleForMms": "Inbound MMS"
  },
  "activityCardSentSms": {
    "title": "Outbound text message",
    "titleForMms": "Outbound MMS",
    "status": "sent"
  },
  "activityCardLiveChat": {
    "title": "Live chat"
  },
  "activityFacebookChat": {
    "title": "Chat with {title} page"
  },
  "activityInstagramChat": {
    "title": "Chat with {title}"
  },
  "contactDetailsContent": {
    "notes": {
      "title": "Notes"
    },
    "scheduled": {
      "title": "Scheduled"
    }
  },
  "activityCardTickets": {
    "emptyText": "There are no messages yet"
  },
  "messagesAttachmentFileModal": {
    "attachFile": "Attach a file",
    "attachFileInsertDownloadLink": "Attach a file - Insert download link",
    "attachFileMMS": "Attach a file - MMS",
    "orDragAndDrop": "or drag and drop",
    "fileErrorRequired": "Please choose a file that you would like to attach to your message.",
    "supportsFormats": "Supports {startOfLine} & {commaWithLastElement} file formats",
    "attachWhatsAppMediaFileInfo": "You can upload image files up to 5 MB, video and audio files up to 16 MB.",
    "attachFacebookMediaFileInfo": "You can upload image files up to 8 MB, video and audio files up to 25 MB.",
    "attachWhatsAppDocFileInfo": "You can upload files up to 100 MB.",
    "attachFacebookDocFileInfo": "You can upload files up to 25 MB."
  },
  "messagesAttachmentSelectModal": {
    "insertDownloadLink": "Insert download link",
    "aClickableLinkToTheFileWillBeAddedToYourMessage": "A clickable link to the file will be added to your message.",
    "aMultimediaMessageWillBeSentToYourRecipientPhone": "A multimedia message will be sent to your recipient’s phone."
  },
  "messagesAttachmentFilePreviewModal": {
    "fileUploaded": "File uploaded",
    "directLink": "Direct link",
    "uploadStatus": "Upload status",
    "addLinkToMessage": "Add link to message",
    "attachFile": "Attach file",
    "previewNotAvailable": "Preview is not available for this type of file."
  },
  "fieldImportFile": {
    "dragAndDropFile": "Drag & drop your file here",
    "fileTypes": "Supported file types: Excel (.xls or .xlsx) and CSV (.csv)."
  },
  "tmRepeatForm": {
    "repeat": "Repeat"
  },
  "tmRepeatFormMonthly": {
    "onDay": "on day",
    "onThe": "on the"
  },
  "tmRepeatFormYearly": {
    "on": "on",
    "of": "of",
    "onThe": "on the"
  },
  "tmRepeatFormRepeatAndCount": {
    "every": "every"
  },
  "tmRepeatFormEnd": {
    "end": "End",
    "sentSmsSessions": "sent SMS sessions"
  },
  "weekDaysFull": {
    "day": "Day",
    "weekday": "Weekday",
    "weekendday": "Weekend day"
  },
  "importContacts": {
    "breadcrumbTitle": "New import"
  },
  "flowStepNumber": {
    "value": "step {number}"
  },
  "importUpload": {
    "file": "Upload a file",
    "list": "Copy & paste",
    "edit": "Start over",
    "upload": "Upload file"
  },
  "importUploadSubtitle": {
    "file": "You have uploaded the file {name} ({size}).",
    "text": "You have manually added {entities} to be imported."
  },
  "importUploadFile": {
    "title": "Upload a file",
    "generatingPreview": "Generating preview..."
  },
  "importUploadFileInfo": {
    "gettingStarted": {
      "title": "Getting started",
      "text": "See how the data should be organized for a successful import.",
      "link": "Download example file"
    },
    "learnMore": {
      "title": "Learn more about import"
    },
    "options": {
      "xls": "Microsoft Excel workbook (.xls)",
      "xlsx": "Microsoft Excel workbook (.xlsx)",
      "csv": "Comma-separated values (.csv)"
    }
  },
  "contactImportUpload": {
    "title": "Upload contact data",
    "subtitle": "Select a way how you would like to upload contacts.",
    "file": {
      "description": "Upload a file with contacts phone numbers, names, organization etc."
    },
    "phones": {
      "title": "Copy and paste phone numbers",
      "description": "Simply copy and paste multiple numbers to add many contacts at ones (no field matching).",
      "placeholder": "Paste your phone numbers here",
      "hint": "Paste one phone number per line or separated with commas.",
      "error": {
        "required": "Please enter at least 1 valid phone number"
      }
    },
    "learnMoreText": "Read about the methods of how you can import contacts."
  },
  "flowUploadPhonesFormService": {
    "phonesErrorMessage": "Please enter valid phone numbers."
  },
  "importMatching": {
    "title": "Match fields",
    "subtitle": "This is a preview of the imported data. Please match the columns below to the data fields in Textmagic.",
    "subtitleFinished": "You have selected {count} spreadsheet data columns to be imported."
  },
  "importMatchingTopic": {
    "topic": "Data fields preview",
    "subtopic": "Please select the relevant user fields to match the spreadsheet data columns.",
    "fullScreen": "View in full screen"
  },
  "importMatchingFullscreenModal": {
    "title": "Match data fields",
    "subtitle": "Please select the relevant user fields to match the spreadsheet data columns."
  },
  "importMatchingCountryModal": {
    "title": "Number validation",
    "alert": "We were unable to automatically validate your numbers in the file. Please select manually one of the options below to help us import your phone numbers correctly:",
    "subtitle": "Numbers format",
    "country": "All of my numbers are from the same country",
    "international": "My numbers are from multiple countries",
    "internationalText": "Numbers must be in the international format with the country code and no leading zeroes, e.g. 183782315212 for the USA."
  },
  "fieldConfigMatching": {
    "confirm": {
      "title": "Contact field is already in use",
      "text": "This field is already in use by another column. Do you want the selected column to use this field instead?"
    }
  },
  "importMatchSelect": {
    "new": "Add new field",
    "skip": "Do not import"
  },
  "userDetails": {
    "breadcrumbs": {
      "accounts": "Admin",
      "users": "Users"
    }
  },
  "subAccountDetails": {
    "breadcrumbs": {
      "accounts": "Admin",
      "subAccounts": "Sub-accounts"
    },
    "tabs": {
      "overview": "Overview",
      "permissions": "Permissions",
      "sent": "Outbound SMS",
      "received": "Inbound SMS"
    }
  },
  "usersDetails": {
    "tabs": {
      "overview": "Overview",
      "permissions": "Permissions"
    }
  },
  "subAccountDetailsButtons": {
    "logInAccount": "Log in as this account",
    "resetPassword": "Reset password",
    "resend": "Resend invitation",
    "openSubAccount": {
      "button": "Reopen sub-account",
      "successMessage": "Sub-account has been opened."
    }
  },
  "userDetailsButtons": {
    "resetPassword": "Reset password",
    "resend": "Resend invitation",
    "openSubAccount": "Reopen user"
  },
  "contactImportSettings": {
    "title": "Additional options",
    "subtitle": "Choose lists where to import contacts and additional actions.",
    "subtitleFinished": "All contacts will be added to the following lists: {lists}",
    "unsubscribe": "Unsubscribers",
    "block": "Blocked"
  },
  "contactImportSettingsForm": {
    "lists": "Add imported contacts to the following lists",
    "moreActions": "More actions",
    "unsubscribe": "Unsubscribe imported contacts",
    "tags": {
      "label": "Add tags",
      "placeholder": "Start typing tag name"
    },
    "block": "Block imported contacts",
    "landline": "Exclude landline numbers",
    "landlineHint": "If your list contains landline numbers, they will be ignored and will not be imported."
  },
  "contactImportSettingsLandlineAlert": {
    "text": "Your uploaded file contains {rows}. It will cost you {cost} to check them for landlines using {link} service.",
    "carrierLookup": "Carrier lookup"
  },
  "contactImportSettingsCreditAlert": {
    "text": "Sorry, but you do not have enough credit to check these numbers. Please {buyCredit} now to continue.",
    "buyCredit": "buy credit"
  },
  "contactImportSettingsAlert": {
    "text": "{note} You are about to import phone numbers into the {list} list. These phone numbers will be moved from your account to {contacts} contacts. {warning} to these phone numbers after this import.",
    "note": "Please note:",
    "warning": "It will no longer be possible to send messages"
  },
  "contactImportsService": {
    "listName": "Import {date}"
  },
  "myData": {
    "totalUsers": "Total users",
    "users": "user | users"
  },
  "contactImportProgress": {
    "title": "Import results",
    "subtitle": "Just leave it with us and we will import your data in the background.",
    "progressLabel": "Import in progress ({progress}%)...",
    "completeLabel": "Import completed",
    "subtextProgress": "Big files processing can take time. You can close this tab and we will send you an email once the import has completed",
    "subtextComplete": "Your import has been completed. To see details please click the button below",
    "continue": "View results",
    "restart": "Start new import"
  },
  "importProgressResult": {
    "name": "File name",
    "size": "File size",
    "rows": "Total rows"
  },
  "sentSmsBulkDetailsModal": {
    "title": "Outbound SMS details"
  },
  "sentSmsBulkDetailsEmptyState": {
    "title": "There are no messages with this status",
    "description": "Please check other tabs for more details"
  },
  "sentSmsBulkDetailsRejectReasonAlert": {
    "informationAboutRejectedReasons": "You can find more information about the common reasons for the undelivered messages from our {link}.",
    "textMagicSupport": "support center"
  },
  "headerVerifyEmail": {
    "title": "Please verify your email - {email} - by clicking the verification link found in the message we just sent to you.",
    "resendButtonTitle": "Resend verification email"
  },
  "headerVerifyIdentity": {
    "needVerifyIdentity": "We need to verify your identity before we can complete your order. Please {verify}",
    "verifyYourIdentity": "verify your identity",
    "yourIdentityBeingReviewed": "Your identity is being reviewed. You will be notified via email once your identity has been verified (normally it takes {minMinutes} - {maxMinutes} minutes)."
  },
  "downloads": {
    "title": "Downloads",
    "desktop": "Desktop",
    "android": "Android",
    "ios": "iOS"
  },
  "downloadsItem": {
    "text": "Textmagic for {name}"
  },
  "subAccountSidebar": {
    "title": "Profile",
    "username": "Username",
    "subaccountType": "Role",
    "status": "Status",
    "lastActive": "Last active",
    "createdAt": "Date created"
  },
  "subAccountDetailsPermissions": {
    "sections": {
      "actionsPermissions": {
        "title": "Actions",
        "description": "Manage the global actions applicable to the whole workspace.",
        "options": {
          "exportData": {
            "title": "Export data"
          },
          "deleteData": {
            "title": "Delete data"
          },
          "importContacts": {
            "title": "Import contacts"
          },
          "mobileApp": {
            "title": "Mobile app"
          }
        }
      },
      "messagingPermissions": {
        "title": "Messaging",
        "description": "Manage the permissions related to messaging.",
        "options": {
          "smsChat": {
            "title": "SMS chat"
          },
          "scheduled": {
            "title": "Scheduled"
          },
          "history": {
            "title": "History"
          },
          "composePage": {
            "title": "Compose page"
          },
          "sendMms": {
            "title": "MMS"
          },
          "byoc": {
            "title": "Bring your own CPaaS"
          }
        }
      },
      "contactsPermissions": {
        "title": "Contacts",
        "description": "Manage the permissions related to contacts, lists and importing.",
        "options": {
          "contacts": {
            "title": "Contacts"
          },
          "resubscribe": {
            "title": "Resubscribe"
          },
          "createNewLists": {
            "title": "Create new lists"
          }
        }
      },
      "servicesPermissions": {
        "title": "Services",
        "description": "Manage the permissions related to additional services.",
        "options": {
          "emailToSms": {
            "title": "Email to sms"
          },
          "distributionLists": {
            "title": "Distribution lists"
          },
          "textToSpeechSettings": {
            "title": "Text-to-speech settings"
          },
          "api": {
            "title": "API & integrations"
          },
          "automationRules": {
            "title": "Automation rules"
          },
          "carrierLookup": {
            "title": "Carrier lookup"
          },
          "subscribeForms": {
            "title": "Subscribe forms"
          },
          "senderSettings": {
            "title": "Sender settings"
          },
          "makeCalls": {
            "title": "Calls"
          },
          "emailLookup": {
            "title": "Email lookup"
          },
          "verifyApi": {
            "title": "Verify API"
          }
        }
      },
      "accountPermissions": {
        "title": "Account",
        "description": "Manage the permissions related to the account.",
        "options": {
          "accountSettings": {
            "title": "Account settings"
          },
          "billing": {
            "title": "Billing"
          },
          "autorechargeSettings": {
            "title": "Auto-recharge"
          },
          "invoices": {
            "title": "Invoices"
          },
          "subaccountSettings": {
            "title": "Sub-accounts"
          },
          "messagingData": {
            "title": "Messaging data"
          },
          "accountNotifications": {
            "title": "Notifications"
          }
        }
      },
      "reportingPermissions": {
        "title": "Reporting",
        "description": "Manage the permissions related to the reporting section.",
        "options": {
          "reportingOverview": {
            "title": "Reporting overview"
          },
          "reportingMessages": {
            "title": "Messages"
          },
          "reportingCalls": {
            "title": "Calls"
          },
          "reportingStatements": {
            "title": "Statements"
          },
          "reportingAuditLogs": {
            "title": "Audit logs"
          },
          "reportingSubAccounts": {
            "title": "Sub-accounts"
          },
          "reportingUsers": {
            "title": "Users"
          },
          "reportingNumbers": {
            "title": "Numbers reporting"
          }
        }
      }
    },
    "sectionsTooltip": {
      "actionsPermissions": {
        "exportData": "Allow sub-account to export data from Textmagic.",
        "deleteData": "Allow sub-account to delete any data from Textmagic.",
        "importContacts": "Allow sub-account to import contacts to Textmagic.",
        "mobileApp": "Allow sub-account to log in using the mobile app for iOS and Android."
      },
      "messagingPermissions": {
        "scheduled": "Allow sub-account to access the Scheduled messages page.",
        "history": "Allow sub-account to access the History (Sent, Received, Forwarded calls) section.",
        "composePage": "Allow sub-account to send messages using the Compose page in the Web app.",
        "sendMms": "Allow sub-account to send MMS.",
        "byoc": "Allow sub-account to manage CPaaS providers and their numbers."
      },
      "contactsPermissions": {
        "contacts": "Allow sub-account to access the Contacts (Lists, My contacts, Custom fields, Import, Blocked) section.",
        "resubscribe": "Allow sub-account to resubscribe contacts, who have been manually unsubscribed.",
        "createNewLists": "Allow sub-account to create new lists."
      },
      "servicesPermissions": {
        "emailToSms": "Allow sub-account to access and use the email to SMS feature.",
        "distributionLists": "Allow sub-account to access the Distribution lists feature.",
        "textToSpeechSettings": "Allow sub-account to access the Text-to-speech settings page.",
        "api": "Allow sub-account to access and use the SMS API Gateway feature.",
        "automationRules": "Allow sub-account to access the Automation rules page.",
        "carrierLookup": "Allow sub-account to access and use the carrier lookup tool.",
        "subscribeForms": "Allow sub-account to access the Subscribe forms page.",
        "senderSettings": "Allow sub-account to edit Sender settings.",
        "makeCalls": "Allow sub-account to make a calls.",
        "emailLookup": "Allow sub-account to access and use the email lookup tool.",
        "verifyApi": "Allow sub-account to access and use the VerifyAPI."
      },
      "accountPermissions": {
        "accountSettings": "Allow sub-account to access the Account settings page.",
        "billing": "Allow sub-account to access the Billing settings.",
        "autorechargeSettings": "Allow sub-account to access the Auto-recharge page.",
        "invoices": "Allow sub-account to access the Invoices page.",
        "subaccountSettings": "Allow sub-account to access the Sub-accounts page.",
        "messagingData": "Allow sub-account to access the Messaging data page.",
        "accountNotifications": "Allow sub-account to change notification settings."
      },
      "reportingPermissions": {
        "reportingOverview": "Allow sub-account to access the Reporting overview page.",
        "reportingMessages": "Allow sub-account to access the Messages reporting page.",
        "reportingCalls": "Allow sub-account to access the Calls reporting page.",
        "reportingStatements": "Allow sub-account to access the Statements page.",
        "reportingAuditLogs": "Allow sub-account to access the Audit logs page.",
        "reportingSubAccounts": "Allow sub-account to access the Sub-accounts reporting page.",
        "reportingNumbers": "Allow sub-account to access the Numbers reporting page."
      }
    },
    "sectionsTooltipForUsers": {
      "actionsPermissions": {
        "exportData": "Allow user account to export data from Textmagic.",
        "deleteData": "Allow user account to delete any data from Textmagic.",
        "importContacts": "Allow user account to import contacts to Textmagic.",
        "mobileApp": "Allow user account to log in using the mobile app for iOS and Android."
      },
      "messagingPermissions": {
        "scheduled": "Allow user account to access the Scheduled messages page.",
        "history": "Allow user account to access the History (Sent, Received, Forwarded calls) section.",
        "composePage": "Allow user account to send messages using the Compose page in the Web app.",
        "sendMms": "Allow user account to send MMS.",
        "byoc": "Allow user account to manage CPaaS providers and their numbers."
      },
      "contactsPermissions": {
        "contacts": "Allow user account to access the Contacts (Lists, My contacts, Custom fields, Import, Blocked) section.",
        "resubscribe": "Allow user account to resubscribe contacts, who have been manually unsubscribed.",
        "createNewLists": "Allow user account to create new lists."
      },
      "servicesPermissions": {
        "emailToSms": "Allow user account to access and use the email to SMS feature.",
        "distributionLists": "Allow user account to access the Distribution lists feature.",
        "textToSpeechSettings": "Allow user account to access the Text-to-speech settings page.",
        "api": "Allow user account to access and use the SMS API Gateway feature.",
        "automationRules": "Allow user account to access the Automation rules page.",
        "carrierLookup": "Allow user account to access and use the carrier lookup tool.",
        "subscribeForms": "Allow user account to access the Subscribe forms page.",
        "senderSettings": "Allow user account to edit Sender settings.",
        "makeCalls": "Allow user account to make a calls.",
        "emailLookup": "Allow user account to access and use the email lookup tool.",
        "verifyApi": "Allow user account to access and use the VerifyAPI."
      },
      "accountPermissions": {
        "accountSettings": "Allow user account to access the Account settings page.",
        "billing": "Allow user account to access the Billing settings.",
        "autorechargeSettings": "Allow user account to access the Auto-recharge page.",
        "invoices": "Allow user account to access the Invoices page.",
        "subaccountSettings": "Allow user account to access the Users page.",
        "messagingData": "Allow user account to access the Messaging data page.",
        "accountNotifications": "Allow user account to change notification settings."
      },
      "reportingPermissions": {
        "reportingOverview": "Allow user account to access the Reporting overview page.",
        "reportingMessages": "Allow user account to access the Messages reporting page.",
        "reportingCalls": "Allow user account to access the Calls reporting page.",
        "reportingStatements": "Allow user account to access the Statements page.",
        "reportingAuditLogs": "Allow user account to access the Audit logs page.",
        "reportingNumbers": "Allow user account to access the Numbers reporting page.",
        "reportingUsers": "Allow users to access the Users reporting page."
      }
    },
    "users": "Users"
  },
  "subAccountEditModal": {
    "title": "Edit sub-account",
    "titleForUsers": "Edit user",
    "fields": {
      "username": {
        "title": "Username",
        "placeholder": "Enter username"
      },
      "firstName": {
        "title": "First name",
        "placeholder": "Enter first name"
      },
      "lastName": {
        "title": "Last name",
        "placeholder": "Enter last name"
      },
      "phone": {
        "title": "Mobile number",
        "placeholder": "Enter mobile number"
      },
      "email": {
        "placeholder": "email{'@'}example.com"
      },
      "role": {
        "title": "Role"
      }
    }
  },
  "subAccountImportFlow": {
    "breadcrumbs": {
      "accounts": "Admin",
      "subAccounts": "Sub-accounts"
    },
    "discard": "Cancel import"
  },
  "subAccountImportUpload": {
    "title": "Upload data",
    "subtitle": "Select a file with the users’ data that you want to invite to Textmagic.",
    "file": {
      "description": "Upload a file with users’ emails, names and other data."
    },
    "emails": {
      "title": "Copy and paste emails",
      "description": "Simply copy and paste multiple emails to invite multiple users at once (no field matching).",
      "placeholder": "Paste your emails here",
      "hint": "Paste one email per line or separated with commas."
    },
    "learnMoreText": "Read about the methods how you can invite team members."
  },
  "subAccountImportMatching": {
    "headersHidden": "Exclude the first row of the spreadsheet (column names) from the import"
  },
  "subAccountImportSettings": {
    "title": "Choose role",
    "subtitle": "Select a role for your new sub-accounts.",
    "subtitleFinished": "You have selected the {role} role for your new sub-accounts.",
    "roles": {
      "A": "Administrator",
      "U": "Regular user"
    }
  },
  "subAccountsImportDetailsRoleField": {
    "admin": {
      "label": "Administrator",
      "badge": "recommended"
    },
    "user": {
      "label": "Regular user"
    },
    "description": "{verb} manage sub-accounts and top up account.",
    "descriptionForUsersWorkflow": "{verb} manage other users and billing."
  },
  "subAccountImportProgress": {
    "title": "Invite sub-accounts",
    "subtitle": "Just leave it with us and we will invite your sub-accounts in the background. They will receive an invitation email soon.",
    "progressLabel": "Inviting in progress ({progress}%)...",
    "completeLabel": "{count} sub-account have been invited|{count} sub-accounts have been invited",
    "subtextProgress": "Big files processing can take time. You can close this tab and we will send you an email once the import has completed",
    "subtextComplete": "Your import has been completed. To see details please click the button below",
    "continue": "View results",
    "restart": "Start new import"
  },
  "tmBuyNumberCell": {
    "buyNumber": "Buy number"
  },
  "senderSettingsCountriesTypes": {
    "byoc": "{provider} number",
    "byocSenderId": "{provider} sender id",
    "tmNumber": "Textmagic number",
    "sharedNumber": "Shared number",
    "ownMobile": "Own mobile"
  },
  "senderSettingsSearchResultsTable": {
    "title": "Search results"
  },
  "senderSettingsCountriesTableRowMobile": {
    "buyNumber": "Buy number"
  },
  "senderSettingsCountriesEditDefaultSenderModal": {
    "title": "Edit default sender"
  },
  "senderSettingsCountriesRestoreSettingsModal": {
    "title": "Restore default sender settings",
    "description": "Some of your changes might be overwritten when restoring default sender settings.",
    "successMessage": "The default sender settings have been successfully restored."
  },
  "voiceCallService": {
    "couldNotConnectYourCallTheNumberIsBusy": "Could not connect your call. The number is busy.",
    "couldNotConnectYourCall": "Could not connect your call.",
    "couldNotConnectYourCallPleaseTryAgainLater": "Could not connect your call. Please try again later or contact support.",
    "noPhoneNumberInIncomingCall": "No phone number in incoming call",
    "collapseCurrentCallInformation": "Collapse current call information",
    "expandCurrentCallInformation": "Expand current call information",
    "areYouSureYouWantToCloseThisPageYourActiveCallWillBeDisconnected": "Are you sure you want to close this page? Your active call will be disconnected",
    "outboundCall": "Outbound call",
    "inboundCall": "Inbound call",
    "chat": "Chat",
    "mute": "Mute"
  },
  "audioSettings": {
    "defaultDevice": "System Default Device",
    "inputDeviceLabel": "Microphone (input)",
    "outputDeviceLabel": "Speakers (output)",
    "title": "Audio settings",
    "testMic": "Test mic",
    "stopTestMic": "Stop test mic",
    "testSpeakers": "Test speakers",
    "accessDeniedWarning": "You can't change audio settings unless you give the webpage access to media devices"
  },
  "subAccountsDetailsOverview": {
    "title": "Audit logs"
  },
  "auditLogTableEmpty": {
    "title": "This user has no audit logs yet",
    "description": "Once this user has performed any actions, they will be displayed here."
  },
  "subAccountsImportDetailsFailedModal": {
    "title": "Edit import",
    "form": {
      "firstName": {
        "label": "First name",
        "placeholder": "Enter first name"
      },
      "lastName": {
        "label": "Last name",
        "placeholder": "Enter last name"
      },
      "phone": {
        "label": "Mobile number",
        "placeholder": "Enter mobile number"
      },
      "email": {
        "placeholder": "email{'@'}example.com"
      },
      "role": {
        "label": "Role"
      }
    },
    "success": "Sub-account has been invited successfully."
  },
  "tmUsersSelectDropdown": {
    "allAccounts": "All accounts ({count})"
  },
  "confirmEmailResolver": {
    "error": "The confirmation link is expired or invalid. Please try resending a new link from the account settings page."
  },
  "resetPassword": {
    "title": "Reset your password",
    "description": "Please create a new password for Textmagic",
    "email": {
      "label": "Textmagic account email"
    },
    "password": {
      "label": "New password",
      "placeholder": "Create a new password"
    },
    "confirm": {
      "label": "Confirm new password",
      "placeholder": "Confirm your new password"
    },
    "submit": "Reset password",
    "toLogin": {
      "text": "Remembered password?",
      "link": "Log in"
    }
  },
  "resetPasswordResolver": {
    "error": "The link is expired or invalid. Please try resending a new link."
  },
  "accountSsoDetails": {
    "title": "SAML details",
    "edit": "Edit SAML details",
    "entityId": "Identity provider Entity ID",
    "ssoService": "Identity provider SSO URL",
    "sloService": "Identity provider SLO URL",
    "x509Cert": {
      "label": "Public x509 certificate",
      "value": "Standard Strength Certificate (2048-bit)"
    },
    "forceSsoLogin": {
      "label": "Force SSO only",
      "sso": "SSO",
      "ssoAndPassword": "SSO and textmagic password"
    }
  },
  "accountSsoDisable": {
    "alert": "If you would like to disable your Single Sign-On feature, please click on the link below. In that case no accounts will be able to log in using SSO anymore. If you are completely sure about disabling the SSO feature, please click here: {link}.",
    "disable": "Disable SSO feature"
  },
  "accountSsoTutorials": {
    "title": "Single Sign-On tutorials"
  },
  "accountSsoTutorial": {
    "view": "View guide"
  },
  "accountSsoEnable": {
    "title": "Enable Single Sign-On",
    "description": "Connect to a secure SSO portal to give access to all of your employees with one click, \nusing SAML authentication standard.",
    "submit": "Add SAML details"
  },
  "accountSsoDetailsModal": {
    "title": "SAML details",
    "entityId": {
      "label": "Identity provider Entity ID",
      "placeholder": "https://www.example.com/saml/metadata"
    },
    "ssoService": {
      "label": "Identity provider SSO URL",
      "placeholder": "https://www.example.com/saml/sso"
    },
    "sloService": {
      "label": "Identity provider SLO URL",
      "placeholder": "https://www.example.com/saml/slo"
    },
    "x509Cert": {
      "label": "Public x509 certificate",
      "placeholder": "Standard Strength Certificate (2048-bit)"
    },
    "forceSsoLogin": {
      "label": "Log in options",
      "sso": {
        "label": "SSO only",
        "description": "Allow users to login to Textmagic only via SSO"
      },
      "ssoAndPassword": {
        "label": "SSO, and Textmagic password",
        "description": "Allow, users to login to Textmagic via SSO and the account password"
      }
    },
    "successNotification": "SAML details has been successfully updated."
  },
  "accountSsoDetailsFormService": {
    "errors": {
      "entityId": "Please specify an Entity ID.",
      "ssoService": "Please specify an SSO URL.",
      "sloService": "Please enter a valid SLO URL.",
      "x509Cert": "Please specify a public x509 certificate."
    }
  },
  "forms": {
    "tabs": {
      "clickToText": "Click-to-text forms",
      "subscribes": "Subscribe forms"
    }
  },
  "formsToolbar": {
    "add": "Add new form",
    "searchPlaceholder": "Search forms"
  },
  "formsEmptyState": {
    "contact": {
      "title": "Embed a form on your website and start chatting with your customers",
      "description": "With click-to-text form, you can show your availability and improve customer communication.",
      "button": "Add new form"
    },
    "subscribe": {
      "title": "Embed a form on your website and start collecting mobile numbers",
      "description": "Boost your marketing by creating a customized web form that can be embedded on your website to collect mobile numbers from your visitors",
      "button": "Add new form"
    }
  },
  "unsubscribeReason": {
    "manual": "Manual",
    "blocked": "Blocked",
    "rule": "Rule",
    "stop": "Stop",
    "spam": "Spam",
    "auto": "Auto",
    "complaint": "Complaint"
  },
  "fieldCountriesList": {
    "add": "Add new country",
    "all": "All countries"
  },
  "fieldPageUrlRules": {
    "constraints": {
      "contains": "URL contains",
      "doesNotContain": "URL doesn’t contain",
      "startsWith": "URL starts with",
      "endsWith": "URL ends with",
      "equalsTo": "URL equals to",
      "doesNotEqualTo": "URL doesn’t equal to"
    },
    "add": "Add rule"
  },
  "mockAlert": "This page use mocked data to present content.",
  "fieldSelectColumn": {
    "defaultButtonText": "number(s)",
    "addOne": "Add {text}",
    "addAll": "Add all",
    "removeOne": "Remove {text}",
    "removeAll": "Remove all"
  },
  "headerSubAccount": {
    "loggedIn": "Logged in as {name} ({login})."
  },
  "contactListsEmpty": {
    "title": "Organize your contacts into lists",
    "description": "Improve your SMS communication by sorting all your contacts in your database into different groups and lists.",
    "button": "Create new list"
  },
  "unsubscribedEmpty": {
    "title": "You have no unsubscribed contacts yet",
    "description": "When someone opts out from your messages, these numbers will be displayed here."
  },
  "unsubscribedEmailEmpty": {
    "title": "No email contacts have been unsubscribed yet",
    "description": "Your unsubscribed contacts will appear here. Stay connected and track opt-outs to maintain a clean and engaged contact list."
  },
  "unsubscribedEmailReason": {
    "manual": "This email has been manually unsubscribed by the workspace user.",
    "linkClick": "This email has been unsubscribed by clicking the unsubscribe link."
  },
  "tmUnsubscribedEmailReasonCell": {
    "reason": {
      "manual": {
        "title": "Manual"
      },
      "linkClick": {
        "title": "Link click"
      }
    }
  },
  "blockedContactsEmpty": {
    "title": "You have no blocked contacts yet",
    "description": "You can add blocked numbers or contacts with the button below.",
    "button": "Add blocked number"
  },
  "contactListDetailsEmpty": {
    "title": "Import your contacts to Textmagic.",
    "description": "Supported file types for import are Excel (.xls or .xlsx) and CSV (.csv).",
    "addContact": "Add contact",
    "or": "or",
    "import": "Import contacts"
  },
  "faq": {
    "title": "Frequently asked questions",
    "description": "Haven’t found what you were looking for? {contact}",
    "tollFree": {
      "title": "Toll-free FAQ",
      "whyChoose": "When and why choose a toll-free number for texting?",
      "considerApply": [
        "When you sign up with us, you'll receive a complimentary toll-free number for your first month of service",
        "We recommend the toll-free number because it covers the broadest range of {useCases} for messaging in the USA and Canada. These numbers also support high throughput, compared to 10DLC, which makes them perfect for all sorts of business communications, whether it's one-on-one conversations, marketing campaigns, multimedia messages, or making phone calls",
        "You should consider choosing a toll-free number if any of the following apply."
      ],
      "between10DLCTollFree": "What is the difference between 10DLC and toll-free numbers?",
      "theDifferenceBetween": "The difference between the two is that a toll-free number has a distinct three-digit code (for example, 800). A 10DLC number, on the other hand, has a region-specific area code associated with it that might be recognizable to the recipient.",
      "useCases": "use cases",
      "trafficRegistration": "traffic registration.",
      "10DLCRegistration": "10DLC registration",
      "weRecommendTheTollFree": "We recommend the toll-free number because it covers the broadest range of {useCases} for messaging in the USA and Canada. Additionally, it offers quick and free {trafficRegistration}",
      "weRecommendTheTollFreeForUs": "We recommend the toll-free number because it covers the broadest range of {useCases} for messaging in the USA and Canada. The toll-free {trafficRegistration} is free and usually takes 2 business days.",
      "10DLCRegistrationTakesUp": "{registration} takes up to 5 weeks and requires a minimum credit of $100.",
      "10DLCRegistrationTakesUpForUs": "{registration} is also free, however, it takes up to 7 business days.",
      "whyDoINeedToComplete": {
        "question": "Why do I need to complete a toll-free number verification?",
        "answer": [
          "Verifying your toll-free number isn't just a Textmagic rule; it's an {requirement} and a trust-building measure created to protect consumers from unwanted spam and fraud. Non-compliance will lead to blocked traffic, significant fines, and a loss of service.",
          "In other words, without completing this mandatory traffic verification for your toll-free number, your text messages will not be delivered."
        ]
      },
      "industryWideRequirement": "industry-wide requirement",
      "whatHappensCompleteTollFree": {
        "question": "What happens if I don't complete toll-free verification",
        "answer": "U.S. carriers, the messaging aggregator, and Textmagic will block any SMS campaigns that are sent from unverified toll-free numbers and do not comply with regulations. "
      },
      "noTaxId": "Your business does NOT have a Tax ID (EIN number), or you are a sole proprietor.",
      "oneOfCategories": "One of the {categories} of the toll-free campaigns matches your use case.",
      "categoriesLink": "43 categories",
      "planToSend": "You plan to send marketing messages.",
      "callsFromAreas": "You will be using a toll-free number to receive calls from different areas.",
      "messagesToCountries": "You want to send messages to the {countries}.",
      "messageCountries": "USA and Canada",
      "howToStart": "How can I get started with the toll-free verification?",
      "startSteps": [
        "When you sign up, you'll get a toll-free number for the first month for free. We recommend starting the free verification process right away by clicking on",
        "Complete verification."
      ],
      "buyCredit": "Register your brand",
      "minimumCredit": "Submit information about your organization to confirm its connection with your brand.",
      "chooseNumber": "Register your SMS campaign",
      "activateNumber": "Select the campaign type that aligns best with your use case, complete the campaign registration, and include sample messages along with a description of your opt-in process.",
      "hereIsWhatYouNeed": "Here is what you need to do:",
      "registerBrand": "Register your brand.",
      "verifyBusiness": "Your organization (brand) may be contacted at any time to verify its business relationship with your company.",
      "registerCampaign": "Register the campaign and its attributes.",
      "chooseFromCampaigns": "From the {campaigns} types, choose one that best describes your use case.",
      "campaignsLink": "43 campaign types",
      "gatherAllInformation": "We will gather all of that information for you in a form and send it on to obtain approval from the toll-free messaging aggregator. It’s minimal work for you and will take you a long way toward texting success.",
      "whyCompleteForm": "Why do I need to complete the toll-free verification form?",
      "newStandard": "Toll-free verification is a new, industry-wide requirement. It tags your traffic as verified with the toll-free messaging aggregator.",
      "coverage": "The industry is moving to 100% registered messaging traffic as a means of fighting spam and fraud.",
      "mandatory": "Toll-free verification is mandatory. It cannot be skipped.",
      "whatAreCosts": "What are the costs of the toll-free verification?",
      "costs": "Nothing. Verification is free.",
      "whyLimitedTrial": "How can I test Textmagic for free? Why is my trial limited?",
      "industryRequirement": "Your trial is limited because, due to the industry-wide requirement, all texts must be registered before you can start texting.",
      "checkInterface": "You can check the web app interface and see all features during the trial, except sending and receiving texts.",
      "whatInformation": "What information is required for the toll-free verification?",
      "mustDocument": "When you complete the toll-free verification form, you must document:",
      "organizationName": "your organization’s legal name, address, and contact details;",
      "organizationType": "your organization type (private, publicly traded, nonprofit);",
      "website": "your website address;",
      "campaignDescription": "descriptions of each campaign you text for;",
      "sampleMessages": "sample messages for each campaign you text for;",
      "whatInformationEndText": [
        "A proper opt-in is crucial. Without a clear and well-documented explanation of how you obtain your customers' consent to message them, successfully completing the verification process can be challenging.",
        "For detailed explanations of the different types of consent required based on your message type, refer to Section 5.1 of the 'CTIA Messaging Principles and Best Practices."
      ],
      "howLongCompleteVerification": {
        "question": "How long will it take to complete the verification?",
        "answer": [
          "After you've submitted your toll-free verification application through the Textmagic self-service {app}, our dedicated team will promptly review the form and pass it along to the messaging aggregator within just one business day.",
          "Normally, the verification process takes approximately 3-5 business days. It's important to note that we cannot expedite the process as an external toll-free messaging aggregator manages the approval."
        ]
      },
      "webApp": "web app",
      "helpFlows": "our opt-in, opt-out, and help flows.",
      "howToNotify": "How will I be notified about the status of my toll-free verification application?",
      "viaEmail": "We will send you an email as soon as we have any news about the status of your toll-free application. While you're waiting for the approval, you can check the {overviewPage} for status updates.",
      "tollFreeOverviewPage": "Toll-free verification overview page",
      "canITransferExistingTollFreeVerification": {
        "question": "Can I transfer my existing toll-free verification to Textmagic?",
        "answer": "Textmagic does not support transfers of toll-free verification from another supplier. We recommend completing the verification form once again."
      },
      "check": "Textmagic will check your completed verification form and submit it for review with the toll-free messaging aggregator as quickly as possible. The approval process can take up to 5 weeks.",
      "timeFrame": "This time frame cannot be affected by the Textmagic team because the approval is handled by an external toll-free messaging aggregator.",
      "factors": "Several factors may cause a delay in processing:",
      "highVolume": "a high volume of requests;",
      "incompleteInformation": "incomplete or unclear information;",
      "submissions": "multiple submissions;",
      "multipleNumbers": "multiple toll-free numbers on one request.",
      "highQuality": "Please submit detailed, high-quality forms. It will make review and approval easier!",
      "whatTypeOfContent": "What type of content is prohibited on toll-free numbers?",
      "whatCampaignTypes": "What campaign types can I send using Textmagic toll-free numbers?",
      "standardTypes": "As best practice, please send texts only to people who have explicitly “opted in” to receive texts from your company.",
      "completeList": "Currently, the toll-free messaging aggregator allows the registration of 43 use cases. The complete list is {link}.",
      "here": "here",
      "needToResubmit": "I have completed the verification with my previous messaging provider. Do I need to go through the process again?",
      "resubmitRequired": "Sorry, yes. Textmagic does not support transfers of toll-free verification from another supplier. We recommend completing the verification form once again.",
      "tab": "Toll-free numbers"
    },
    "prohibitedContent": {
      "titles": [
        "High-risk financial services",
        "Get rich quick Schemes",
        "Debt forgiveness",
        "Illegal substances/ activities",
        "General"
      ],
      "contentTypes": [
        [
          "Payday loans",
          "Short-term, high-interest loans",
          "Auto loans",
          "Mortgage loans",
          "Student loans",
          "Gambling",
          "Sweepstakes",
          "Stock alerts",
          "Cryptocurrency"
        ],
        [
          "Debt consolidation",
          "Debt reduction",
          "Credit repair programs",
          "Deceptive work from home programs",
          "Multi-level marketing"
        ],
        ["Work from home programs", "Risk investment opportunities", "Debt collection or consolidation"],
        ["Cannabis", "Alcohol", "Tobacco or vape"],
        ["Phishing Frauds or scams", "Deceptive marketing", "Pornography", "S.H.A.F.T. content"]
      ]
    },
    "tenDlc": {
      "title": "10DLC FAQ",
      "whyChoose": "When and why choose a local 10DLC number for texting?",
      "whyChooseDescription": "If a local presence is vital to you, consider the 10DLC number. With local area code numbers, you can start two-way conversations and send powerful messages, including images or video with MMS, to get your campaigns noticed.",
      "shouldConsider": "You should consider using a 10DLC number if any of the following apply.",
      "hasTaxId": "Your business has a Tax ID (EIN number).",
      "oneOfCategories": "One of the {categories} of the 10DLC campaigns matches your use case.",
      "categoriesLink": "11 categories",
      "localizedCommunication": "You value localized and personalized communication.",
      "localIdentity": "You wish your brand to have a local identity.",
      "ensureDelivery": "You want to ensure message delivery by whitelisting your traffic with U.S. mobile network operators.",
      "tenDlcChoice": "If you want one number for your business calls and texts, then both 10DLC and toll-free numbers are great choices.",
      "tollFreeChoice": "Toll-free numbers can help maximize your reach, as you can use one number for both the U.S. and Canada.",
      "localPresence": "But if a local presence is vital to you, consider 10DLC.",
      "noticedCampaign": "With their area code numbers, you can start two-way conversations and send powerful messages, including images or video with MMS, to get your campaigns noticed.",
      "avoidSpamFilters": "With the 10DLC campaign registration, you will also avoid getting caught up in spam filters.",
      "howToStart": "How can I get started with 10DLC?",
      "whatIs10DLC": {
        "question": "What is 10DLC, and why do I need to register?",
        "answer": [
          "10DLC (10-digit long code) is an {requirement} applicable to all businesses sending text messages to customers within the United States using a local number.  Before you can start sending texts, you must register and gain approval for both your brand and campaign.",
          "In other words, without completing this mandatory 10DLC registration for your local number, your text messages will not be delivered. Furthermore, U.S. telecom carriers may issue you a fine or penalty if you send non-compliant SMS traffic.\n"
        ],
        "industryWideRequirement": "industry-wide requirement"
      },
      "whatIs10DLCForUs": {
        "question": "What is 10DLC, and why do I need to register?",
        "answer": [
          "10DLC (10-digit long code) is an {requirement} applicable to all businesses sending any type of text messages to mobile numbers within the United States using a local number. Before you can start sending texts, you must register and gain approval for both your brand and campaign.",
          "In other words, without completing this mandatory 10DLC registration for your local number, your text messages will not be delivered. Furthermore, U.S. telecom carriers may issue you a fine or penalty if you send non-compliant SMS traffic.\n"
        ],
        "industryWideRequirement": "industry-wide requirement"
      },
      "register": "Before sending business SMS traffic to the United States, you must complete 10DLC registration in four simple steps:",
      "registerUs": "Before sending business SMS traffic to the United States, you must complete 10DLC registration in 3 simple steps:",
      "registerUsOptions": [
        {
          "title": "Choose your local area code and a virtual number.",
          "description": "Select a local virtual number and activate it directly in the Textmagic platform."
        },
        {
          "title": "Register a brand with its attributes.",
          "description": "Submit information about your organization to confirm its connection with your brand."
        },
        {
          "title": "Register the campaign and its attributes.",
          "description": "Choose the type of campaign that best fits your use case, register your campaign, and submit sample messages."
        }
      ],
      "buyCredit": "Buy Textmagic credit.",
      "minimumCredit": "The minimum initial credit required is {price}.",
      "chooseNumber": "Choose your local area code and a virtual number.",
      "activateNumber": "Select a local virtual number and activate it directly in the Textmagic platform.",
      "registerBrand": "Register a brand with its attributes.",
      "mayBeContacted": "Submit information about your organization to confirm its connection with your brand.",
      "registerCampaign": "Register the campaign and its attributes.",
      "chooseUseCase": "Choose the type of campaign that best fits your use case, register your campaign, and submit sample messages.",
      "useCases": "2FA, Account Notifications, Customer Care, Delivery Notifications, Fraud Alert Messaging, Higher Education, Marketing, Mixed, Polling and Voting, Public Service Announcement, or Security Alert.",
      "gatherInformation": "We will gather all of that information for you in a form and send it on to obtain approval from mobile network operators. It’s minimal work for you, and will take you a long way toward texting success.",
      "toLearnHowToComplete": "To learn how to complete the 10DLC registration form, please read the {support}.",
      "supportArticle": "support article",
      "whatHappens": {
        "question": "What happens if I don't register for 10DLC?",
        "answer": [
          "The industry is moving to 100% registered messaging traffic to fight spam and fraud. Registration is mandatory. It cannot be skipped. Non-compliance will lead to blocked traffic, significant fines, and a loss of service.",
          "U.S. carriers, the Campaign Registry, and Textmagic can suspend or block any SMS campaigns that do not comply with the 10DLC regulations. No refunds are issued if a brand or campaign is blocked."
        ]
      },
      "doNeedRegisterTenDlc": {
        "question": "Do I need to register for 10DLC if I'm not sending marketing campaigns?",
        "answer": [
          "Yes, you still need to register for 10DLC. This requirement applies to all text messages sent to U.S. customers using a local number, not just marketing campaigns."
        ]
      },
      "newStandard": "10DLC is a new, industry-wide requirement. It qualifies your traffic as compliant with U.S. mobile network operators.",
      "completeCoverage": "The industry is moving to 100% registered messaging traffic as a means of fighting spam and fraud. Registration is mandatory. It cannot be skipped.",
      "whatAreCosts": "What are the costs for 10DLC registration?",
      "whatAreCostsAnswer": "Textmagic will cover all one-time costs for your 10DLC brand + campaign vetting. The first month is free, later, you will need to pay {cost} monthly for the 10DLC campaign renewal.",
      "oneTimeCosts": "Your 10DLC registration has one-time costs, plus monthly and quarterly costs, as listed below.",
      "campaignRegistry": "Once you've completed the registration form, these costs will be automatically deducted from your Textmagic account balance. The Campaign Registry sets these fees.",
      "brandRegistration": "Brand registration + vetting:",
      "priceOneTimeFee": "{price} (one-time fee).",
      "brandVetting": "Brand vetting:",
      "priceOneTime": "{price} per quarter.",
      "campaignRegistration": "Campaign registration:",
      "virtualNumber": "Number cost:",
      "priceMonthly": "{price} per month.",
      "whyTrialLimited": "How can I test Textmagic for free? Why is my trial limited?",
      "industryRequirements": "Your trial is limited because, due to the industry-wide requirement, all texts must be registered before you can start texting. You can check the web app interface and see all features during the trial, except sending and receiving texts.",
      "useInterface": "You can use the interface and test all features during the trial period, except sending and receiving texts.",
      "whatInformation": "What information is required for 10DLC registration?",
      "whatInformationText": [
        "A proper opt-in is crucial. Without a clear and well-documented explanation of how you obtain your customers' consent to message them, successfully completing the verification process can be challenging.",
        "For detailed explanations of the different types of consent required based on your message type, refer to Section 5.1 of the 'CTIA Messaging Principles and Best Practices."
      ],
      "howLongDoes": {
        "question": "How long does it take to get started with 10DLC?",
        "answer": [
          "After submitting your 10DLC registration form, the Textmagic team will review it and forward to the Campaign Registry for approval within one business day.",
          "It usually takes 7 business days to get your 10DLC brand and campaign approved. The Textmagic team cannot expedite this time frame because the Campaign Registry handles the approval process."
        ],
        "webApp": "web app"
      },
      "mustDocument": "When you register for 10DLC, you must document:",
      "organizationName": "your organization’s legal name, address, and contact details;",
      "organizationType": "your organization type (private, publicly traded, nonprofit);",
      "organizationTaxId": "your organization’s tax number ID or EIN;",
      "website": "your website address;",
      "campaignDescription": "descriptions of each campaign you text for;",
      "sampleMessages": "sample messages for each campaign you text for;",
      "flows": "our opt-in, opt-out, and help flows.",
      "whenNotified": "How will I be notified about the status of my 10DLC registration?",
      "sendEmail": "We will email you as soon as we have news about the status of your 10DLC application. While you're waiting for approval, you can check the {registration} for status updates.",
      "registrationPage": "10DLC registration page",
      "howLong": "How long will it take to whitelist my 10DLC messaging traffic with the mobile network operators?",
      "quickly": "Textmagic will check your completed 10DLC form and submit it for review with the U.S. mobile network operators as quickly as possible.",
      "fewWeeks": "The approval process can take up to 5 weeks. This time frame cannot be affected by the Textmagic team because the mobile network operators handle the approval process.",
      "whatTypeOfContent": "What type of content is prohibited on 10DLC?",
      "typesRegistration": "The following campaign types are ineligible for the 10DLC registration:",
      "whatCampaignTypes": "What 10DLC campaign types can I register with Textmagic?",
      "campaignTypes": "At the moment, Textmagic offers the following 11 standard campaign types.",
      "needToResubmit": "Can I transfer my existing 10DLC registration to Textmagic?",
      "resubmitRequired": "Unfortunately, that is not possible. Textmagic does not support transfers of 10DLC registrations from other suppliers.",
      "tab": "10DLC numbers"
    },
    "tenDlcCampaignTypes": {
      "2FA": "2FA",
      "anyAuthentication": "Any authentication, verification, or one-time passcode.",
      "notifications": "Account Notifications",
      "standardNotifications": "Standard notifications to account holders relating to or being about an account.",
      "customerCare": "Customer Care",
      "customerInteractions": "All customer care interactions, including account management and customer support.",
      "deliveryNotifications": "Delivery Notifications",
      "serviceStatus": "Notifications about the delivery status of a product or service.",
      "fraudAlert": "Fraud Alert Messaging",
      "fraudActivity": "Notifications about potential fraudulent activity on a user’s account.",
      "higherEducation": "Higher Education",
      "educationCampaigns": "Message campaigns from colleges, universities, and other educational institutions.",
      "marketing": "Marketing",
      "anyCommunication": "Any communication that includes marketing or promotional content.",
      "mixed": "Mixed",
      "standardCampaigns": "Any messaging campaign containing 2–5 standard use cases.",
      "polling": "Polling and voting",
      "surveys": "The sending of surveys and polling or voting campaigns for non-political arenas.",
      "publicService": "Public Service Announcement",
      "informationMessaging": "Information messaging to raise an audience’s awareness about important matters.",
      "securityAlert": "Security Alert",
      "compromisedSystem": "A notification that the security of a system, either software or hardware, has been compromised in some way and that action must be taken."
    }
  },
  "tollFreeCaFaq": [
    {
      "question": "When and why choose a toll-free number for texting?",
      "answer": [
        "We recommend choosing the toll-free number because it covers the broadest range of messaging {useCases} in the USA and Canada. These numbers also support high throughput compared to local numbers, which makes them perfect for all sorts of business communications, whether it's one-on-one conversations, marketing campaigns, multimedia messages, or phone calls.",
        "You should consider choosing a toll-free number if any of the following apply:"
      ],
      "useCases": "use cases",
      "categories": "categories",
      "options": [
        "Your business does NOT have a Tax ID (EIN number), or you are a sole proprietor.",
        "One of the {categories} of the toll-free campaigns matches your use case.",
        "You plan to send marketing messages and need a high throughput.",
        "You want to send messages to the USA and Canada."
      ]
    },
    {
      "question": "What is the difference between local and toll-free numbers?",
      "answer": [
        "The difference between the two is that a toll-free number has a distinct three-digit code (for example, 800). A local number, on the other hand, has a region-specific area code associated with it that might be recognizable to the recipient.",
        "We recommend the toll-free number because it covers the broadest range of {useCases} for messaging in the USA and Canada. Additionally, it offers quick and free {trafficRegistration}"
      ],
      "useCases": "use cases",
      "trafficRegistration": "traffic registration"
    },
    {
      "question": "Why do I need to complete a toll-free number verification?",
      "answer": [
        "Verifying your toll-free number isn't just a Textmagic rule; it's an {requirement} and a trust-building measure created to protect consumers from unwanted spam and fraud.",
        "U.S. carriers, the messaging aggregator, and Textmagic will block messages that are sent from unverified toll-free numbers and do not comply with regulations.",
        "In other words, without completing this mandatory traffic verification for your toll-free number, your text messages will not be delivered. "
      ],
      "requirement": "industry-wide requirement"
    },
    {
      "question": "What information is required for the toll-free verification?",
      "answer": [
        "When you complete the toll-free verification form, you must document:",
        "A proper opt-in is crucial. Without a clear and well-documented explanation of how you obtain your customers' consent to message them, successfully completing the verification process can be challenging.",
        "For detailed explanations of the different types of consent required based on your message type, refer to Section 5.1 of the 'CTIA Messaging Principles and Best Practices.",
        "To learn how to complete the verification form, please watch our {video} or read the {article}."
      ],
      "options": [
        "your organization’s legal name, address, and contact details;",
        "your organization type (private, publicly traded, nonprofit);",
        "your website address;",
        "descriptions of each campaign you text for;",
        "sample messages for each campaign you text for;",
        "our opt-in, opt-out, and help flows."
      ]
    },
    {
      "question": "How long will it take to complete the verification?",
      "answer": [
        "After you've submitted your toll-free verification application through Textmagic, our dedicated team will promptly review the form and pass it along to the messaging aggregator within just one business day.",
        "Normally, the verification process takes approximately 3-5 business days. It's important to note that we cannot expedite the process as an external toll-free messaging aggregator manages the approval."
      ]
    },
    {
      "question": "How will I be notified about the status of my toll-free verification application?",
      "answer": [
        "We will email you as soon as we have news about the status of your toll-free application. While you're waiting for approval, you can check the {overviewPage} for status updates."
      ],
      "verificationPage": "Toll-free verification overview page"
    },
    {
      "question": "What campaign types can I send using Textmagic toll-free numbers?",
      "answer": [
        "Currently, the toll-free messaging aggregator allows the registration of 43 use cases. The complete list is {link}.",
        "As best practice, please send texts only to people who have explicitly “opted in” to receive texts from your company."
      ],
      "here": "here"
    },
    {
      "question": "Can I transfer my existing toll-free verification to Textmagic?",
      "answer": [
        "Textmagic does not support transfers of toll-free verification from another supplier. We recommend completing the verification form once again."
      ]
    }
  ],
  "gettingStarted": {
    "title": "Getting started",
    "description": "To send text messages, you need to buy a virtual number, verify your brand and use case, and get approved by the mobile network operators. Once your messaging traffic has been approved, you can send and receive SMS, MMS, and voice calls.",
    "tenDlcNumber": "10DLC number",
    "tollFreeNumber": "Toll-free number",
    "tenDlcNumbersApproved": "10-digit local U.S. numbers approved by the mobile network operators for sending texts.",
    "tollFreeNumbersApproved": "8XX prefix numbers for U.S. and Canada verified for sending texts by the messaging aggregator.",
    "taxIdRequired": "Tax ID (EIN number) required",
    "taxIdNotRequired": "Tax ID (EIN number) {not} required",
    "not": "not",
    "paidRegistration": "Paid registration + {fees}",
    "fees": "quarterly fees",
    "freeRegistration": "Free registration",
    "trafficWhitelisted": "Traffic whitelisted {operators}",
    "operators": "with the mobile operators",
    "trafficVerified": "Traffic verified with the {aggregator}",
    "aggregator": "messaging aggregator",
    "approvalPeriod": "Approval period {varies}",
    "varies": "varies",
    "twoWaySms": "2-way SMS, MMS and voice supported",
    "minimumCredit": "Minimum initial credit required: {price}",
    "minimumTollFreeCredit": "Minimum initial credit required: {currency}20",
    "enoughCredit": "You have enough credit to get started",
    "notEnoughCredit": "You don't have enough credit to get started",
    "applicationSubmitted": "Your application has been submitted",
    "getTenDlcNumber": "Get 10DLC number",
    "getTollFreeNumber": "Get toll-free number",
    "chooseTenDlcNumber": "You can choose your number with the local area code prefix. The 10DLC registration fees apply.",
    "chooseTollFreeNumber": "You can choose your toll-free number with the 8XX prefix. The verification is free.",
    "registrationRequired": "Registration Required",
    "messagingRegistration": "10DLC and toll-free messaging registration is {requirement}",
    "industryRequirement": "an industry-wide requirement. ",
    "industryMoving": "The industry is moving to {traffic} to fight spam and fraud. Registration cannot be skipped.",
    "traffic": "100% registered messaging traffic",
    "tenDlcAfterPayment": "After payment, you can choose your 10DLC number with the local area code.",
    "tollFreeAfterPayment": "After payment, you can choose your toll-free number with the 8XX prefix.",
    "tenDlcCanBuy": "You can choose your number with the local area code prefix. The 10DLC registration fees apply.",
    "tollFreeCanBuy": "You can choose your toll-free number with the 8XX prefix. The verification is free.",
    "tenDlcComplete": "Please complete your 10DLC brand and campaign registration to start sending messages.",
    "tollFreeComplete": "Please complete your toll-free registration to start sending messages.",
    "upToFiveWeeks": "Please allow up to 5 weeks for your form to be reviewed. Then, you can start sending text messages.",
    "buyCredit": "Buy credit now",
    "completeRegistration": "Complete registration",
    "viewStatus": "View application status",
    "fixIssues": "Fix issues",
    "localCanadianNumber": "Local Canadian number",
    "chooseCanadianNumber": "You can choose your number with the local area code prefix.",
    "tenDlcCanadian": "10-digit local Canadian numbers for sending and receiving text messages and voice calls.",
    "noBulkText": "No bulk and promotional texts allowed",
    "noRegistrationRequired": "No registration required",
    "p2p": "Peer-to-peer single messages only",
    "mmsNotSupported": "MMS not supported",
    "brandAndVetting": "Brand registration + vetting: {price}",
    "brandRegistration": "Brand registration: {price}",
    "oneTimeFee": "(one time fee)",
    "campaignRegistration": "Campaign registration: {price}",
    "perQuarter": "per quarter",
    "numberCost": "Number cost: {price}",
    "perMonth": "per month",
    "upToWeeks": "up to 5 weeks",
    "free": "Free",
    "actionRequired": "Action required"
  },
  "contactList": {
    "noMatchingResults": "There are no visible lists.",
    "refineRequest": "Please unhide some of the lists or create a new one to see the results."
  },
  "headerAlertOldVersion": {
    "text": "A new version of Textmagic is available.",
    "refresh": "Update now"
  },
  "headerAlertOffline": {
    "text": "Connection lost. Please check your internet connection.",
    "refresh": "Refresh"
  },
  "headerAlertPlanFailedRenew": {
    "text": "Your Team plan failed to renew on {date}.  None of the users can access this workspace. ",
    "buttonTitle": "Update payment method"
  },
  "importMatchingAlert": {
    "text": "Please match \"{fieldName}\" field with your import file."
  },
  "fieldSelectSearchUser": {
    "allAccounts": "All accounts ({count})"
  },
  "fieldSelectSearchCountry": {
    "placeholder": "- Select country -"
  },
  "fieldAutomationRulesAction": {
    "add": "Add a new action"
  },
  "fieldFullName": {
    "placeholderFirstName": "Enter first name",
    "placeholderLastName": "Enter last name"
  },
  "systemExitResolver": {
    "successNotification": "You were logged out. Please log in again."
  },
  "attachmentPreview": {
    "title": "Attachment preview",
    "pleaseDownloadTheFileToViewIt": "Please download the file to view it",
    "currentlyThePreviewOfFilesIsNotSupported": "Currently, the preview of { extension } files is not supported.",
    "pleaseDownloadTheOriginalFileToOpenIt": "Please download the original file to open it."
  },
  "composeMessagesBulksProgressModal": {
    "title": "Campaign in progress...",
    "titleError": "Campaign was not sent",
    "description": "Your SMS campaign will be completed in the background. Feel free to close this window.",
    "alertTitle": "Your message has been successfully sent to {recipients} recipients.",
    "connectionIsUnstable": "Connection is unstable. All messages will be processed and sent in the background. You can later check the details from the history page.",
    "errorMessage": "Sorry, but you do not have enough credit to send this message. Please buy credit now and send a message once again.“ message should be displayed",
    "notEnoughMoneyMessage": "Sorry, we could not send the session because you do not have enough credit on your account. Please {buyCredit} and try sending the message again.",
    "buyCredit": "buy credit"
  },
  "composeMessagesBulksProgress": {
    "sendingTextMessages": "Sending text messages... ({progress}%)",
    "messagesSent": "Messages sent",
    "totalMessages": "Total messages",
    "notEnoughMoneyMessage": "Your campaign was not sent because you do not have enough credit on your account.",
    "notEnoughMoneyMessageTitle": "Too low balance to send campaign"
  },
  "composeMessagesBulksProgressPopup": {
    "sendingCampaign": "Sending campaign... ({progress}%)"
  },
  "composeCompleteNumberRegistrationAlert": {
    "title": "Your traffic is unverified:",
    "text": "{title} Texts will be blocked or marked as spam. To avoid this, complete {brandAndCampaignRegistration}. Alternatively, use our BYOC service to {connectYourExistingNumbers} from {twilio} or {vonage} without verification.",
    "brandAndCampaignRegistration": "brand and campaign registration",
    "connectYourExistingNumbers": "connect your existing numbers",
    "twilio": "Twilio",
    "vonage": "Vonage"
  },
  "tmParticipantSenderId": {
    "senderId": "(Sender ID)"
  },
  "formDetailsSidebar": {
    "errorNotification": "We could not update the {fullName} contact. <a href=\".\">Please reload the contact detail page</a> to see the latest data."
  },
  "attachment": {
    "title": "Please download the file to view it",
    "subtitle": "The preview of <b>.{fileFormat}</b> files is not supported.",
    "info": "This file was sent using {textmagic}.\nIn case of any issues, please {support}.",
    "textmagic": "Textmagic",
    "size": "Size: {weight}"
  },
  "editVerifyingEmailModal": {
    "title": "Edit email address ",
    "description": "If you’d like to change your email address from {email} to a new one, please enter the new email in the field below. We will resend a verification code to the new address.",
    "form": {
      "label": "New email address",
      "placeholder": "Enter new email address",
      "validationMessage": "Please enter a valid email address.",
      "button": "Save and resend",
      "buttonLoading": "@:common.saving",
      "errors": {
        "forbidden": "This email cannot be used. Please use a different email address.",
        "needVerifyEmail": "This email address is already registered in our system.",
        "invalidEmail": "Please enter your corporate/work email address."
      }
    },
    "notifySuccess": "Your email has been successfully updated, and the 6-character code has been resent. Please check your inbox."
  },
  "verificationCodeModal": {
    "title": "Confirm email change",
    "email": "For your security, we have sent a 6-digit code to",
    "enterCode": "Enter the verification code below:",
    "noResponse": "Didn’t get the verification code?",
    "resendCode": "Resend code"
  },
  "emailVerificationModal": {
    "title": "Confirm email change",
    "alert": "You will be {loggedOut} of your account after confirming your email change with the verification code.",
    "loggedOut": "logged out",
    "success": "The email has been successfully changed.",
    "resendSuccess": "We have sent you a verification email. Please check your inbox."
  },
  "passwordVerificationModal": {
    "title": "Confirm password change",
    "success": "The password has been successfully changed.",
    "tooManyRequests": "You've entered the wrong code too many times. Please wait a moment and try again from start.",
    "resendSuccess": "We have sent you a verification SMS. Please check your phone."
  },
  "yourAccountPermissionsHaveChangedNotification": "Your account permissions have changed. For more information, please contact your Textmagic account administrator.",
  "accountBalanceCriticallyLowModal": {
    "title": "Account balance is critically low",
    "description": "Please be aware that your Textmagic numbers and other subscriptions may be canceled if your balance is insufficient to renew them on their renewal date(s).",
    "balanceRemaining": "Balance remaining: {balance}"
  },
  "smartBannerService": {
    "title": "Download our Mobile App",
    "author": "Textmagic",
    "button": "VIEW",
    "store": {
      "ios": "On the App Store",
      "android": "In Google Play"
    },
    "price": "FREE"
  },
  "payment": {
    "thankYouForRequestingTheSenderId": "Thank you for requesting the Sender ID. We will process your order and assign the requested Sender ID within 5-7 business days."
  },
  "contactImportMatching": {
    "previousMatched": "Match columns according to previous import.",
    "headersHidden": "Exclude the first row of the spreadsheet (column names) from the import"
  },
  "numberIsAlreadyTakenError": "Sorry, but this number is already taken. Please choose a different one.",
  "gettingStartedByocFeature": {
    "title": "Connect CPaaS provider",
    "description": "Connect your existing Twilio, Vonage, Sinch, or Bandwidth numbers and start texting."
  },
  "gettingStartedUsWhatsFeature": {
    "title": "Connect WhatsApp",
    "description": "Respond to customer-initiated WhatsApp chats from within the Textmagic app."
  },
  "gettingStartedLiveChatFeature": {
    "title": "Set up live chat widget",
    "description": "Embed the chat widget into your website and let your customers contact you directly via live chat."
  },
  "gettingStartedSharedEmailFeature": {
    "title": "Set up shared email inbox",
    "description": "Connect your email inbox and turn requests from your customers into organized tickets."
  },
  "gettingStartedFacebookFeature": {
    "title": "Connect Facebook",
    "description": "Connect your Facebook pages to manage customer chats directly from the Textmagic app."
  },
  "gettingStartedInstagramFeature": {
    "title": "Connect Instagram",
    "description": "Respond to messages from your Instagram followers directly in the Textmagic app."
  },
  "tollFreeUsFaq": [
    {
      "question": "When and why choose a toll-free number for texting?",
      "answer": [
        "We recommend choosing the toll-free number because it covers the broadest range of messaging {useCases} in the USA and Canada. These numbers also support high throughput, compared to 10DLC, which makes them perfect for all sorts of business communications, whether it's one-on-one conversations, marketing campaigns, multimedia messages, or phone calls.",
        "You should consider choosing a toll-free number if any of the following apply:"
      ],
      "useCases": "use cases",
      "categories": "categories",
      "options": [
        "Your business does NOT have a Tax ID (EIN number), or you are a sole proprietor.",
        "One of the {categories} of the toll-free campaigns matches your use case.",
        "You plan to send marketing messages and need a high throughput.",
        "You want to send messages to the USA and Canada."
      ]
    },
    {
      "question": "What is the difference between 10DLC and toll-free numbers?",
      "answer": [
        "The difference between the two is that a toll-free number has a distinct three-digit code (for example, 800). A 10DLC number, on the other hand, has a region-specific area code associated with it that might be recognizable to the recipient.",
        "We recommend the toll-free number because it covers the broadest range of {useCases} for messaging in the USA and Canada. The toll-free {trafficRegistration} is free and usually takes 2 business days.",
        "{registration} is also free, however, it takes up to 7 business days. "
      ],
      "useCases": "use cases",
      "trafficRegistration": "traffic registration",
      "10DLCRegistration": "10DLC registration"
    },
    {
      "question": "Why do I need to complete a toll-free number verification?",
      "answer": [
        "Verifying your toll-free number isn't just a Textmagic rule; it's an {requirement} and a trust-building measure created to protect consumers from unwanted spam and fraud.",
        "U.S. carriers, the messaging aggregator, and Textmagic will block messages that are sent from unverified toll-free numbers and do not comply with regulations.",
        "In other words, without completing this mandatory traffic verification for your toll-free number, your text messages will not be delivered. "
      ],
      "requirement": "industry-wide requirement"
    },
    {
      "question": "What information is required for the toll-free verification?",
      "answer": [
        "When you complete the toll-free verification form, you must document:",
        "A proper opt-in is crucial. Without a clear and well-documented explanation of how you obtain your customers' consent to message them, successfully completing the verification process can be challenging.",
        "For detailed explanations of the different types of consent required based on your message type, refer to Section 5.1 of the 'CTIA Messaging Principles and Best Practices.",
        "To learn how to complete the verification form, please watch our {video} or read the {article}."
      ],
      "options": [
        "your organization’s legal name, address, and contact details;",
        "your organization type (private, publicly traded, nonprofit);",
        "your website address;",
        "descriptions of each campaign you text for;",
        "sample messages for each campaign you text for;",
        "our opt-in, opt-out, and help flows."
      ]
    },
    {
      "question": "How long will it take to complete the verification?",
      "answer": [
        "After you've submitted your toll-free verification application through Textmagic, our dedicated team will promptly review the form and pass it along to the messaging aggregator within just one business day.",
        "Normally, the verification process takes approximately 3-5 business days. It's important to note that we cannot expedite the process as an external toll-free messaging aggregator manages the approval."
      ]
    },
    {
      "question": "How will I be notified about the status of my toll-free verification application?",
      "answer": [
        "We will email you as soon as we have news about the status of your toll-free application. While you're waiting for approval, you can check the {overviewPage} for status updates."
      ],
      "verificationPage": "Toll-free verification overview page"
    },
    {
      "question": "What campaign types can I send using Textmagic toll-free numbers?",
      "answer": [
        "Currently, the toll-free messaging aggregator allows the registration of 43 use cases. The complete list is {link}.",
        "As best practice, please send texts only to people who have explicitly “opted in” to receive texts from your company."
      ],
      "here": "here"
    },
    {
      "question": "Can I transfer my existing toll-free verification to Textmagic?",
      "answer": [
        "Textmagic does not support transfers of toll-free verification from another supplier. We recommend completing the verification form once again."
      ]
    }
  ],
  "gettingStartedLayout": {
    "title": "Welcome to Textmagic! Let’s get you set up {icon}",
    "description": "Get started by setting up your first messaging channel.",
    "faq": {
      "title": "Getting started FAQ"
    }
  },
  "gettingStartedLayoutAll": {
    "byocFeatureDescription": "Connect your existing numbers from your CPaaS provider to Textmagic using our BYOC service. The setup is quick, enabling you to begin texting in minutes.",
    "byocFeatureRequiredDescription": "CPaaS provider API key is required."
  },
  "gettingStartedNumberFeature": {
    "buttons": {
      "setCallForwarding": "Set up call forwarding"
    },
    "qr": {
      "title": "SMS QR Code",
      "tooltip": "Scan this QR code to text your virtual number."
    },
    "available": "available",
    "comingSoon": "coming soon"
  },
  "gettingStartedUsCaNumberFeature": {
    "title": "Your TOLL-FREE NUMBER",
    "description": [
      "We've assigned a toll-free number to your account for 1 month for free, so you can start engaging with your audience sooner. Please {complete} to start texting.",
      "complete a free verification"
    ],
    "buttonCompleteVerification": "Complete verification",
    "buttonCompleteRegistration": "Complete registration",
    "buttonViewStatus": "View status"
  },
  "gettingStartedUsLocalNumberFeature": {
    "title": "Get a local number",
    "description": [
      "Select a local area code your customers will recognize. Local numbers are ideal for most {useCases}.",
      "10DLC {registration} and takes up to {business} to get approved. "
    ],
    "useCases": "use cases",
    "chooseNumber": "Choose your local number",
    "registrationFree": "registration is free",
    "businessDays": "7 business days"
  },
  "gettingStartedCaLocalWithoutNumber": {
    "title": "Get a local number",
    "description": [
      "Select a local area code your customers will recognize. Local numbers are ideal for most use cases.",
      "{immediately} with no traffic registration needed."
    ],
    "chooseNumber": "Choose your local number",
    "immediately": "Get started immediately"
  },
  "gettingStartedAuUkNumberFeature": {
    "title": "Your Virtual NUMBER",
    "description": "We've assigned a virtual number to your account for 1 month for free, so you can start engaging with your audience sooner. You can start texting right away.",
    "buttonNewMessage": "New text message"
  },
  "gettingStartedAuUkSenderFeature": {
    "title": "Apply for a Sender ID",
    "description": [
      "Elevate your brand by sending texts from your company name for just {cost}. Replies are not possible with alphanumeric Sender ID. Get started instantly"
    ],
    "inMonth": "{cost}/month"
  },
  "gettingStartedTabs": {
    "tollFree": "Toll-free numbers",
    "byoc": "CPaaS integrations",
    "virtualNumbers": "Virtual numbers",
    "senderId": "Sender IDs",
    "whatsApp": "WhatsApp",
    "liveChat": "Live chat",
    "facebook": "Facebook"
  },
  "gettingStartedUs": {
    "title": "Welcome! {icon}  Let's set up your texting number",
    "description": [
      "Set up your preferred communication channel to get started with business texting.",
      "Please choose an option below or learn more from our {faq}."
    ],
    "gettingStartedFaq": "getting started FAQ",
    "tabs": {
      "tollFree": "Toll-free numbers",
      "10dlc": "10DLC numbers"
    },
    "freeForMonth": "FREE FOR 1 MONTH",
    "free": "FREE",
    "freeForMonthTollFreeTooltip": [
      "The first toll-free number is free for 1 month, later {cost} per month / number. ",
      "The toll-free verification is totally free forever."
    ],
    "freeTollFreeTooltip": [
      "The first toll-free number is included in your current plan, each extra number costs {cost} per month / number.",
      "The toll-free verification is totally free forever."
    ],
    "freeForMonthTenDlcTooltip": [
      "The first 10DLC number is free for 1 month, later {cost} per month / number.",
      "The 10DLC brand and campaign initial vetting + registration is free.",
      "The first 10DLC campaign is free for 1 month, later {cost} per month / campaign."
    ],
    "freeTenDlcTooltip": [
      "The first 10DLC number is included in your current plan, each extra number costs {cost} per month / number.",
      "The 10DLC brand and campaign initial vetting + registration is free.",
      "The first 10DLC campaign is free for 1 month, later {cost} per month / campaign."
    ]
  },
  "gettingStartedAuUk": {
    "freeForMonthTooltipText": ["The first texting number is free for 1 month, later {cost} per month / number."],
    "freeTooltipText": [
      "The first texting number is included in your current plan, each extra number costs {cost} per month / number. "
    ]
  },
  "gettingStartedCaLocalFeature": {
    "freeForMonthTooltip": "The first local number is free for 1 month, later {cost} per month / number.",
    "freeTooltip": "The first local number is included in your current plan, each extra number costs {cost} per month / number."
  },
  "gettingStartedAdditionalFeatureSeparator": {
    "text": "Add more communication channels:"
  },
  "gettingStartedDefaultFeature": {
    "comingSoon": "COMING SOON"
  },
  "gettingStartedPreloader": {
    "description": "Please wait while we are loading your information."
  },
  "gettingStartedUsTollFreeWithoutNumber": {
    "title": "Get a toll-free number",
    "description": [
      "Numbers that begin with a 8XX prefix and are most suitable for mass texting in the U.S. and Canada.",
      "Toll-free {verification} and takes {business} to get approved."
    ],
    "verificationFree": "verification is free",
    "businessDays": "3-5 business days",
    "chooseNumber": "Choose your toll-free number"
  },
  "gettingStartedUsTenDlcNumberFeature": {
    "title": "Your 10DLC NUMBER",
    "description": [
      "Before you can start sending texts, you must",
      "register your brand and campaign.",
      "This is an industry-wide requirement applicable to all businesses sending text messages to the U.S."
    ],
    "buttonViewStatus": "View status",
    "buttonCompleteRegistration": "Complete registration"
  },
  "gettingStartedCaLocalNumberFeature": {
    "title": "Your local number",
    "description": [
      "Before you can start sending texts, you must",
      "register your brand and campaign.",
      "This is an industry-wide requirement applicable to all businesses sending text messages to the U.S."
    ]
  },
  "gettingStartedUsCaRejectedReason": {
    "description": [
      "We were unable to verify your application due to the following reason:",
      "Please fix the errors and resubmit the form again."
    ],
    "buttonFixIssues": "Fix issues and resubmit"
  },
  "gettingStartedCa": {
    "title": "Welcome! {icon}  Let's set up your texting number",
    "description": [
      "Set up your preferred communication channel to get started with business texting.",
      "Please choose an option below or learn more from our {faq}."
    ],
    "gettingStartedFaq": "getting started FAQ",
    "tabs": {
      "tollFree": "Toll-free numbers",
      "localNumbers": "Local numbers"
    }
  },
  "localNumbersCaFaq": [
    {
      "question": "When and why choose a local number for texting?",
      "answer": [
        "Sending texts from a local number helps to build trust with a local presence and boosts open rates. Consider using a local Canadian virtual number for peer-to-peer communication (sending single texts, not campaigns).",
        "When you sign up, you can choose one virtual number for the first month for free. {weRecommend} because it covers the broadest range of {useCases} in Canada and the USA and supports high throughput compared to local Canadian numbers. "
      ],
      "weRecommend": "We recommend the toll-free number",
      "useCases": "messaging use cases"
    },
    {
      "question": "What is the difference between local and toll-free numbers?",
      "answer": [
        "The difference between the two is that a toll-free number has a distinct three-digit code (for example, 800). A local number, on the other hand, has a region-specific area code associated with it that might be recognizable to the recipient.",
        "We recommend using a toll-free number because it supports high throughput compared to a local number, which makes it perfect for all sorts of business communications."
      ]
    },
    {
      "question": "Do I need to verify my messaging traffic sent using a local number?",
      "answer": [
        "10DLC registration is not required when using a local number for texting in Canada. The key practice is to have accurate opt-ins tailored to your message type. Only send texts to those who have explicitly opted in."
      ]
    },
    {
      "question": "What are the costs of using a local number?",
      "answer": [
        "Canadian local virtual number subscription costs {costs} per month. Credit for the number renewal is deducted from the prepaid account balance."
      ]
    },
    {
      "question": "Can I port over my existing local number to Textmagic?",
      "answer": [
        "Yes, you can port local Canadian numbers. Textmagic accepts only full porting. Once the number is ported to Textmagic, it costs { costs } per month to use it."
      ]
    }
  ],
  "gettingStartedAuUkWithoutNumber": {
    "title": "Get a texting number",
    "description": [
      "Deliver SMS marketing campaigns, notifications, and have two-way conversations with your customers in {country} for just {cost} Get started instantly."
    ],
    "inMonth": "{cost}/month."
  },
  "gettingStartedQrCode": {
    "title": "SMS QR Code",
    "message": "Hello, this is a message to Textmagic number"
  },
  "gettingStartedAuVirtualNumbersFaq": [
    {
      "question": "What is a virtual number, and how does it work?",
      "answer": [
        "A Textmagic virtual number is a phone number that allows users to call, send, and receive text messages through the Textmagic platform.",
        "When you sign up, you'll get an Australian virtual number for the first month for free. This number is designed to work only with Australian mobile subscribers. It cannot receive 2FA/verification texts sent by other services.",
        "We recommend using the virtual number because it is perfect for all sorts of business communications, whether it's one-on-one conversations, marketing campaigns, or making phone calls."
      ]
    },
    {
      "question": "What is the difference between a virtual number and a Sender ID?",
      "answer": [
        "The virtual number and Sender ID serve different purposes and have distinct functions:",
        [
          "The {number} supports calls and two-way texting for various purposes like marketing, two-way SMS chats, customer alerts, and staff communication.",
          "The {sender} is designed for one-way outbound texting without reply capabilities. It is suitable for use cases like marketing campaigns and sending important announcements where responses are unnecessary."
        ]
      ],
      "virtualNumber": "virtual number",
      "senderId": "Sender ID"
    },
    {
      "question": "How can I get started with sending texts from a virtual number?",
      "answer": [
        "When you sign up, you'll get an Australian virtual number for the first month for free. You can start texting instantly via the {compose} and {chats} pages."
      ],
      "compose": "Compose",
      "chats": "Chats"
    },
    {
      "question": "Can I port in my existing virtual number to Textmagic?",
      "answer": [
        "Textmagic does not offer number porting in Australia. Please use our local dedicated virtual number or a Sender ID."
      ]
    },
    {
      "question": "Can I send text messages from my own mobile number instead?",
      "answer": [
        "Textmagic doesn't support sending texts from personal mobile numbers due to carrier restrictions. To send texts, you can use our local dedicated virtual number or a Sender ID."
      ]
    },
    {
      "question": "Can I have multiple virtual numbers on one account?",
      "answer": [
        "Yes, it is possible to have multiple virtual numbers on the account and use them to send different campaigns."
      ]
    },
    {
      "question": "Can I receive inbound messages using the Textmagic virtual number?",
      "answer": [
        "Yes, you can. With the Textmagic virtual number, you can send and receive messages, as well as make phone calls. Australian virtual number is designed to work only with local Australian mobile subscribers and cannot receive 2FA/verification texts."
      ]
    },
    {
      "question": "How am I charged for the virtual number subscription?",
      "answer": [
        "When you sign up, you'll get an Australian virtual number for the first month for free. After the first free month, the credit for the virtual number renewal will be automatically deducted from your prepaid account balance.",
        "Learn more about {pricing}"
      ],
      "pricing": "Textmagic pricing."
    },
    {
      "question": "How much does it cost for someone to send a message to my virtual number?",
      "answer": [
        "The cost for sending the SMS is just the standard charge made by your client’s mobile operator for sending a message. Textmagic never charges your client for sending a message."
      ]
    }
  ],
  "gettingStartedAuUkSenderIdsFaq": [
    {
      "question": "What is a Sender ID, and how does it work?",
      "answer": [
        "A Sender ID is a unique name that appears in the \"from\" field of your text messages on the recipient's phone. It is important to note that:",
        [
          "The Sender ID can be a maximum of 11 characters long.",
          "The Sender ID must relate to your business name and cannot be a general word.",
          "The Sender ID subscription can be shared with your team members.",
          "Your recipients cannot reply to text messages sent using a Sender ID.",
          "The Sender ID subscription costs {cost} per month."
        ]
      ]
    },
    {
      "question": "What is the difference between a virtual number and a Sender ID?",
      "answer": [
        "The virtual number and Sender ID serve different purposes and have distinct functions:",
        [
          "The {number} supports calls and two-way texting for various purposes like marketing, two-way SMS chats, customer alerts, and staff communication.",
          "The {sender} is designed for one-way outbound texting without reply capabilities. It is suitable for use cases like marketing campaigns and sending important announcements where responses are unnecessary."
        ]
      ],
      "virtualNumber": "virtual number",
      "senderId": "Sender ID"
    },
    {
      "question": "How can I get started with sending texts from my business name?",
      "answer": [
        "To send text messages from your business name (Sender ID):",
        [
          "Register for a Textmagic account or {logIn} to your existing account.",
          "{verify} by purchasing SMS credit.",
          "Go to the {sender} and click {apply}",
          "Fill out the Sender ID application with your company data and use case description."
        ],
        "A Textmagic specialist will review your Sender ID application. Once it is approved, you will receive an email confirmation."
      ],
      "logIn": "log in",
      "verify": "Verify your account value",
      "sender": "Sender IDs page",
      "apply": "Apply for Sender ID."
    },
    {
      "question": "Why was my Sender ID rejected?",
      "answer": [
        "Textmagic may reject your Sender ID application if one of the following applies:",
        [
          "Sender ID looks like a number, not a business name.",
          "Sender ID is a general word, not specific to your business name.",
          "Sender ID relates or looks similar to a well-known protected brand like Apple, UPS,  Lloyds etc."
        ]
      ]
    },
    {
      "question": "Can I set up my own number as a Sender ID?",
      "answer": [
        "You can't use your personal number as a Sender ID on Textmagic due to carrier restrictions. The Sender ID needs to reflect your company name for compliance."
      ]
    },
    {
      "question": "Can I register multiple Sender IDs on my account?",
      "answer": [
        "Yes, it is possible to have multiple Sender IDs on the account and use them to send different campaigns."
      ]
    },
    {
      "question": "Can I share one Sender ID with my team members?",
      "answer": ["Yes, the Sender ID is automatically shared with all your users from the same account branch."]
    },
    {
      "question": "Are there any restrictions when it comes to choosing a Sender ID?",
      "answer": [
        "The Sender ID can be a maximum of 11 characters long, consisting only of letters and numbers without any special characters. The use of generic sender IDs like INFO, SMS, NOTICE, etc. is prohibited."
      ]
    },
    {
      "question": "Can I receive inbound messages or replies to texts sent from a Sender ID?",
      "answer": [
        "No, you can not. The Sender ID is designed for one-way outbound texting without reply capabilities. It is suitable for use cases like marketing campaigns and sending important announcements where responses are unnecessary.\n"
      ]
    },
    {
      "question": "How am I charged for the Sender ID subscription?",
      "answer": [
        "The Sender ID subscription costs {cost} per month. The credit for the subscription renewal is automatically deducted from your prepaid account balance.",
        "Learn more about Textmagic pricing."
      ]
    }
  ],
  "gettingStartedUkVirtualNumbersFaq": [
    {
      "question": "What is a virtual number, and how does it work?",
      "answer": [
        "A Textmagic virtual number is a phone number that allows users to call, send, and receive text messages through the Textmagic platform.",
        "When you sign up, you'll get a UK virtual number for the first month for free. This number is designed to work only with UK mobile subscribers. It cannot receive 2FA/verification texts sent by other services.",
        "We recommend using the virtual number because it is perfect for all sorts of business communications, whether it's one-on-one conversations, marketing campaigns, or making phone calls."
      ]
    },
    {
      "question": "What is the difference between a virtual number and a Sender ID?",
      "answer": [
        "The virtual number and Sender ID serve different purposes and have distinct functions:",
        [
          "The {number} supports calls and two-way texting for various purposes like marketing, two-way SMS chats, customer alerts, and staff communication.",
          "The {sender} is designed for one-way outbound texting without reply capabilities. It is suitable for use cases like marketing campaigns and sending important announcements where responses are unnecessary."
        ]
      ],
      "virtualNumber": "virtual number",
      "senderId": "Sender ID"
    },
    {
      "question": "How can I get started with sending texts from a virtual number?",
      "answer": [
        "When you sign up, you'll get a UK virtual number for the first month for free. You can start texting instantly via the {compose} and {chats} pages."
      ],
      "compose": "Compose",
      "chats": "Chats"
    },
    {
      "question": "Can I port in my existing virtual number to Textmagic?",
      "answer": [
        "Textmagic does not offer number porting in the UK. Please use our dedicated virtual UK number or a Sender ID."
      ]
    },
    {
      "question": "Can I send text messages from my own mobile number instead?",
      "answer": [
        "Textmagic doesn't support sending texts from personal mobile numbers due to carrier restrictions. To send texts, you can use our dedicated virtual UK number or a Sender ID. "
      ]
    },
    {
      "question": "Can I have multiple virtual numbers on one account?",
      "answer": [
        "Yes, it is possible to have multiple virtual numbers on the account and use them to send different campaigns."
      ]
    },
    {
      "question": "Can I receive inbound messages using the Textmagic virtual number?",
      "answer": [
        "Yes, you can. With the Textmagic virtual number, you can send and receive messages, as well as make phone calls. The UK virtual number is designed to work only with local UK mobile subscribers and cannot receive 2FA/verification texts."
      ]
    },
    {
      "question": "How am I charged for the virtual number subscription?",
      "answer": [
        "When you sign up, you'll get a UK virtual number for the first month for free. After the first free month, the credit for the virtual number renewal will be automatically deducted from your prepaid account balance. ",
        "Learn more about {pricing}"
      ],
      "pricing": "Textmagic pricing."
    },
    {
      "question": "How much does it cost for someone to send a message to my virtual number?",
      "answer": [
        "The cost for sending the SMS is just the standard charge made by your client’s mobile operator for sending a message. Textmagic never charges your client for sending a message."
      ]
    }
  ],
  "gettingStartedRequestNumberButton": {
    "requestNumber": "Request a number"
  },
  "upgradeAccountModal": {
    "title": "Upgrade your account to unlock this feature",
    "description": ["Unlock the sub-accounts feature by buying credit,", "and bring your team on board with you."],
    "buyCredit": "Buy credit"
  },
  "sentry": {
    "feedback": "Feedback",
    "feedbackAndIdeas": "Feedback and ideas",
    "sendFeedback": "Send feedback",
    "messagePlaceholder": "Describe your ideas to improve this page…",
    "emailLabel": "Your email"
  },
  "tenDlcSampleMessagesFormService": {
    "defaultMessage": "[Enter your sample message]\nReply STOP to opt out."
  },
  "importResultListCell": {
    "listsLink": "{count} lists",
    "blocked": "Blocked contacts",
    "unsubscribed": "Unsubscribed contacts"
  },
  "fieldPhoneWithCountrySelect": {
    "emptyValue": "- Select a virtual number -"
  },
  "buyCreditAlert": {
    "notEnoughCredit": "Sorry, but you do not have enough credit to buy this number. Please {link} now to continue.",
    "buyCredit": "buy credit"
  },
  "HeaderProfilePendingTooltip": {
    "title": "Your order is being processed",
    "description": {
      "orderWillBeReviewed": "Your order will be reviewed within the next { hours } hours by our accounts team. If you wish to avoid waiting and get your order processed instantly, please verify your identity.",
      "youWillReceiveConfirmation": "You will receive confirmation once your account is topped up. Thank you for your patience."
    }
  },
  "headerProfileRemainingMessagesTooltip": {
    "youCanSendCpass": "You can send {cpass} (or ~{quantityMessages} text messages to {country} using the Textmagic numbers).",
    "messagesViaCpass": "~{quantityMessages} text messages via your connected CPaaS provider",
    "youCanSend": "You can send {messages} to {country} with your credits.",
    "textMessages": "~{quantityMessages} text messages",
    "thisNumberMayDecrease": "This number may decrease depending on message length, encoding, and destination.",
    "howCharged": "How am I charged for text messages?"
  },
  "userPresence": {
    "online": "Online",
    "offline": "Offline",
    "tooltip": "Set your availability for live chat. If all agents are offline, an auto-reply will be shown or live chat will be hidden based on your widget settings."
  },
  "sidebarDivider": {
    "comingSoon": "Coming soon"
  },
  "tasks": {
    "increaseProductivity": "Increase your team's productivity with tasks",
    "movePlanning": "Move your project planning, organization, and teamwork forward with fully customizable task management.",
    "faqTasks": "Tasks FAQ",
    "faqDescription": "Haven’t found what you were looking for? {contact}",
    "faq": {
      "whatIsFeature": "What is the tasks feature?",
      "features": "The tasks feature in Textmagic is a user-friendly tool designed to simplify tracking your team activities. Each task can be customized with file attachments, optional due dates, and reminders to ensure timely completion. All tasks are organized in a board-style layout, with each column representing a specific task stage for easy visualization. Plus, you have the flexibility to manage multiple projects simultaneously by setting up various boards tailored to each project's needs.",
      "howTasksDiffer": "How do tasks in Textmagic differ from other task managers?",
      "uniqueAdvantage": "Tasks in Textmagic offer a unique advantage over traditional task managers by integrating the ability to communicate directly with customers without needing to switch apps or contexts. By allowing tasks to be created and managed within the context of customer interactions, Textmagic enhances a team's ability to be responsive to customer needs. This not only improves customer satisfaction but also helps in quicker resolution of issues, as tasks can be assigned and addressed as soon as they arise from a conversation. Textmagic solution is ideal for teams looking for a more integrated and communication-centric approach.",
      "howCanImprove": "How can the Tasks feature improve my productivity?",
      "allowingToOrganize": "By allowing you to organize and prioritize your tasks, the feature helps you focus on what's important, reducing the time spent figuring out what to work on next. This organization leads to more efficient use of time and higher productivity. A board-style layout helps you quickly identify the most important tasks first, ensuring that high-priority work gets the attention it deserves. With a clear plan in place, you can dedicate more time to actual work rather than planning or juggling tasks in your mind.",
      "howCanHelp": "Can the Tasks feature help me in managing deadlines?",
      "dueDates": "Yes, it allows you to set due dates for each task. This keeps you aware of upcoming deadlines, helping you to plan ahead and avoid last-minute rushes. Plus, you can set reminders so you don't forget about these important deadlines. This way, you're less likely to miss something crucial and can stay more organized and relaxed.",
      "canCreate": "Can I create a task from the chat?",
      "createDirectly": "Yes, you can create a task directly from a chat conversation. This feature is designed to streamline workflows, allowing users to quickly turn discussions into actionable tasks without leaving the chat interface. This means that when a task or action item arises during a conversation, you can immediately create a task for it, ensuring that it gets attention and follow-up.",
      "linkAnything": "Plus, you can also link anything else from the Textmagic app to your tasks, like a contact, a list, or even a specific SMS message. This makes it easy to keep all your related stuff connected and organized right where you need it.",
      "canHelpWithDeadlines": "Can the tasks feature help me in managing deadlines?",
      "setDueDates": "Yes, it allows you to set due dates for each task. This keeps you aware of upcoming deadlines, helping you to plan ahead and avoid last-minute rushes. Plus, you can set reminders so you don't forget about these important deadlines. This way, you're less likely to miss something crucial and can stay more organized and relaxed."
    },
    "sideBar": {
      "personal": "Personal",
      "marketing": "Marketing",
      "support": "Support",
      "sales": "Sales",
      "dev": "Dev",
      "management": "Management",
      "design": "Design"
    }
  },
  "tickets": {
    "simplifySupport": "Simplify support with an intuitive ticketing system",
    "turnInteractions": "Turn customer interactions into meaningful experiences and delight customers at every turn.",
    "faqTitle": "Tickets FAQ",
    "haveNotFound": "Haven’t found what you were looking for? {contact}",
    "sideBar": {
      "assigned": "Assigned to me",
      "open": "Open",
      "pending": "Pending",
      "unassigned": "Unassigned",
      "solved": "Solved",
      "closed": "Closed ",
      "all": "All tickets"
    },
    "faq": {
      "whatIsFeature": "What is the tickets feature?",
      "features": "The tickets feature in Textmagic serves as a support and communication tool that aims to simplify the process of managing customer inquiries, service requests, and issues. This feature allows users to receive and create requests directly within the Textmagic platform, without using any third-party tools.",
      "whyUse": "Why should I use tickets in Textmagic?",
      "benefits": "Textmagic tickets offer an efficient solution for organizing all your communication in one place. Whether you're handling customer support queries, feedback, or specific issues, everything will be centralized and easily accessible. All relevant information and communications will be consolidated in one ticket, making it easier for you to contact the customer in a single click. By consolidating all the relevant information, resolving problems becomes much more efficient, and you won't have to waste time sifting through emails or messages on different platforms.",
      "canCreate": "Can I create a new ticket from chat?",
      "createEasily": "It's easy to create a ticket directly from the conversation. Additionally, you can link any item, such as a contact, chat, list, task, or single message, to the ticket. The Textmagic tickets feature is designed to increase the productivity of your support process, allowing you to focus solely on resolving issues without worrying about the interface.",
      "willCreateAutomatically": "Will you automatically create a ticket when I receive an email from someone?",
      "ticketAutomation": "Sure! Upon receiving a new email message, we will automatically determine whether it corresponds to an existing ticket or not. If it is a new message, we will create a new ticket."
    }
  },
  "monthlyPlansPayAsYouGo": {
    "title": "Pay-as-you-go plan",
    "description": "Pay-as-you-go allows you to add and spend credit whenever you want. Your prepaid balance won’t expire.",
    "downgradeButton": "Downgrade to pay-as-you-go plan"
  },
  "monthlyPlansCard": {
    "perMonth": "{currency} per month",
    "buttons": {
      "downgrade": "Downgrade",
      "currentPlan": "Current plan",
      "upgrade": "Upgrade"
    }
  },
  "monthlyPlansDefaultCard": {
    "discount": {
      "text": "{sale} discount",
      "tooltip": "Compared to Textmagic pay-as-you-go price."
    },
    "textMonth": {
      "text": "{quantity} texts / month",
      "tooltip": "Single messages are limited to {character} characters, including {br} spaces. Longer messages incur {end}",
      "end": "additional charges."
    },
    "perText": {
      "text": "{price} per text",
      "tooltip": "This is the price per text that you will be paying for every message that goes over the plan limit."
    },
    "allFeatures": "All features included",
    "chatAI": "Chat AI assistant"
  },
  "teamMemberChooseMonthlyPlan": {
    "addUserSeats": "Add user seats",
    "featurePartSubscription": "The feature is available as a part of a subscription from {cost} per user.",
    "chooseMonthlyPlan": "Choose monthly plan"
  },
  "usersWorkflowFaq": {
    "title": "Users FAQ"
  },
  "usersWorkflowInviteWithoutPlan": {
    "title": "Invite users for a better teamwork",
    "description": "Invite team members to collaborate in one shared workspace. Enjoy access to shared messaging channels, messaging data, and contacts, all under a unified billing account for seamless communication.",
    "inviteUsers": "Invite users"
  },
  "usersWorkflowPaymentProcess": {
    "title": "Payment is being processed...",
    "description": "Please wait while we are updating your subscription information."
  },
  "usersWorkflowTopBar": {
    "searchPlaceholder": "Search users",
    "invite": "Add users",
    "import": "Import"
  },
  "badges": {
    "beta": "Beta",
    "new": "New"
  },
  "inviteSubAccountEmailControl": {
    "roles": {
      "administrator": "Administrator",
      "user": "User"
    }
  },
  "inviteSubAccountFormContent": {
    "tooltipText": [
      {
        "text": "{arg0} - Can manage other users and billing.",
        "args": ["Administrator"]
      },
      {
        "text": "{arg0} - Cannot manage other users and billing.",
        "args": ["Regular user"]
      }
    ]
  },
  "inviteSubAccountAlert": {
    "text": "Users who accept invites will count as active seats in your Team plan."
  },
  "planExpiredBase": {
    "title": "Your Team plan has expired"
  },
  "planExpiredForAdmin": {
    "description": [
      "Your Team plan subscription failed to renew on {date}.",
      "To restore access to your account, please update your payment method."
    ],
    "updatePaymentMethod": "Update payment method",
    "buttonDescription": "You will be directed to our secure Stripe billing portal.",
    "faq": [
      {
        "question": "Why can't I access my account?",
        "answer": [
          "Your subscription renewal was unsuccessful, and our attempt to process the payment did not go through. This is typically due to expired credit card information or changes to your bank's policies."
        ]
      },
      {
        "question": "How do I reactivate my account?",
        "answer": [
          "Click on “Update payment method” to enter new billing information and reactivate your account. Once updated, your service will be restored."
        ]
      }
    ]
  },
  "planExpiredForUser": {
    "description": [
      "Your Team plan subscription failed to renew on {date}.",
      "To restore access to your account, please {contact} to update the payment method."
    ],
    "contact": "contact your workspace administrator",
    "workspaceAdministrators": "Workspace administrators"
  },
  "usersWorkflowLoadingOverlayContent": {
    "title": "Redirecting to Stripe...",
    "subtitle": "Please wait while we redirect you to a secure Stripe portal to complete checkout."
  },
  "usersWorkflowActive": {
    "accountOwner": "The workspace account owner is {name} - {email}.",
    "manage": "Manage"
  },
  "deleteInvitationModal": {
    "title": "Delete user invite",
    "text": [
      "Are you sure you would like to delete the invite for {email}?",
      "The user will not be able to join your workspace, and the invitation will be revoked."
    ],
    "successMessage": "The user invite has been successfully deleted."
  },
  "richEditor": {
    "formats": {
      "bold": "Bold",
      "underline": "Underline",
      "italic": "Italic",
      "strike": "Strikethrough",
      "codeBlock": "Code",
      "link": "Link",
      "bulletList": "Bulleted list",
      "orderedList": "Ordered list",
      "clear": "Clear formatting",
      "textAlignCenter": "Center",
      "textAlignLeft": "Left",
      "textAlignRight": "Right",
      "textAlignJustify": "Justify"
    },
    "pasteFormatted": "Paste formatted",
    "pastePlainText": "Paste as plain text",
    "linkText": "Link text",
    "formatting": "Formatting",
    "insertDynamicField": "Insert dynamic field"
  },
  "sentSmsDetailsSenderItem": {
    "label": "Sent by"
  },
  "saveFormFooterBar": {
    "unsavedChanges": "You have unsaved changes."
  },
  "formSubscribeLanding": {
    "copyright": "Subscribe form powered by {company}"
  },
  "fieldImageAttachArea": {
    "text": "Drag image here or {browse}",
    "browse": "browse..."
  },
  "fieldNarrowFileUploadArea": {
    "text": "Drag image here or {browse}",
    "browse": "browse..."
  },
  "fieldAssignee": {
    "assignTo": "Assign to",
    "unassigned": "Unassigned",
    "inviteNewUser": "Invite a new user",
    "you": "You"
  },
  "tmDropdownUsersFilter": {
    "filter": "Filter",
    "deselectAll": "Deselect all",
    "selectAll": "Select all",
    "unassigned": "Unassigned",
    "searchPlaceholder": "Search users",
    "users": "{count} users",
    "allUsers": "All users"
  },
  "myContactDetails": {
    "contactShared": "This contact is shared by {name}.",
    "backToContacts": "Back to Contacts"
  },
  "commonPriority": {
    "low": "Low",
    "normal": "Normal",
    "high": "High",
    "urgent": "Urgent"
  },
  "fieldContactSelect": {
    "enterNameOrPhone": "Please enter Contact name or phone number",
    "newContact": "new contact"
  },
  "contactNotes": {
    "title": "Contact notes",
    "editNote": "Edit note",
    "addContactNote": "Add contact note",
    "showMoreNotes": "Show more notes",
    "showLessNotes": "Show less notes",
    "addNewNote": "Add a new note",
    "emptyTitle": "This contact has no notes",
    "emptyText": "Please add a new note using the button below.",
    "notesFor": "Notes for {name}"
  },
  "myContactsTable": {
    "unassigned": "Unassigned"
  },
  "fieldContactTags": {
    "newTag": "New tag",
    "enterTag": "Enter tag name"
  },
  "tmRestoreCell": {
    "expiresDays": "Expires in {days} day | Expires in {days} days"
  },
  "restoreNumberRenewalModal": {
    "title": "Restore number renewal",
    "buttonTitle": "Restore renewal",
    "text": "Are you sure you want to restore the subscription for the dedicated number {phone}? The next renewal date will be on {date}",
    "successMessage": "The selected number subscription has been successfully restored."
  },
  "pageSingle": {
    "important": "IMPORTANT: ",
    "back": "Back to Textmagic app",
    "copy": "Copy text",
    "poweredBy": "Powered by"
  },
  "contactListsTableDropdown": {
    "pin": "Pin list",
    "unpin": "Unpin list"
  },
  "pinnedContactComposition": {
    "pinSuccess": "Your selected contact list have been successfully pinned.",
    "unpinSuccess": "Your selected contact list have been successfully unpinned."
  },
  "pinnedSegmentComposition": {
    "pinSuccess": "Your selected segment has been successfully pinned.",
    "unpinSuccess": "Your selected segment has been successfully unpinned."
  },
  "tmPriceWithGiftCell": {
    "included": "included in your plan"
  },
  "tmFilterTopBar": {
    "addFilterLabel": "Add filter",
    "addFiltersLabel": "More filters",
    "addGroupLabel": "Add filter group",
    "discardLabel": "Discard",
    "hidePanel": "Hide"
  },
  "tmSentSmsMessageCell": {
    "deleted": "Message deleted"
  },
  "typingSingle": {
    "userTyping": "is typing"
  },
  "typing": {
    "severalPeople": "Several people",
    "severalAgentsTyping": "{people} are typing"
  },
  "topFilterPanelButton": {
    "label": "Filters"
  },
  "messenger": {
    "scanQrCode": {
      "title": "scan QR Code"
    },
    "featureInstagramTooltip": {
      "title": "Instagram integration",
      "badge": "NEW",
      "text": "Connect your Instagram account to manage customer chats directly from the Textmagic app.",
      "getStarted": "Get started",
      "remindLater": "I’ll do it later"
    },
    "settings": {
      "breadcrumbs": {
        "chats": "Messenger",
        "settings": "Settings",
        "emails": "Emails",
        "emailsFlow": "Email"
      }
    }
  },
  "attachments": {
    "unknownName": "Unknown file",
    "attachFile": "Attach file",
    "errors": {
      "unknownUploadError": "Unknown upload error",
      "uploadErrorWithDash": "Upload error - ",
      "uploadError": "Upload error",
      "maxFileSizeError": "The maximum allowed file size is 10M.",
      "maxFileSizeErrorShort": "10MB maximum size",
      "offlineError": "Connection lost"
    }
  },
  "tmFilterTagsEmpty": {
    "title": "There are no tags yet",
    "description": "Organize your {domain} by creating tags."
  },
  "tmFilterTags": {
    "searchPlaceholder": "Search tags"
  },
  "tmFilterContactSource": {
    "searchPlaceholder": "Search source"
  },
  "tmFilterList": {
    "searchPlaceholder": "Search lists"
  },
  "tmFilterWords": {
    "options": {
      "anyOf": "Contains at least one of these words",
      "noneOf": "Contains none of these words",
      "isEmpty": "Is empty",
      "isNotEmpty": "Is not empty"
    }
  },
  "tmFilterPhone": {
    "startsWith": "starts with",
    "endsWith": "ends with",
    "none": "none",
    "options": {
      "startsWith": "Starts with",
      "endsWith": "Ends with",
      "like": "Contains",
      "notLike": "Does not contain",
      "isEmpty": "Is empty",
      "isNotEmpty": "Is not empty"
    }
  },
  "tmFilterCountry": {
    "placeholder": "Search country"
  },
  "tmFilterContacts": {
    "emptyTitle": "There are no contacts yet"
  },
  "tmFilterOptions": {
    "empty": "Is empty",
    "placeholder": "Search options"
  },
  "tmFilterNumeric": {
    "empty": "Is empty",
    "notEmpty": "Is not empty",
    "labels": {
      "BETWEEN": "Between",
      "GTE": "Greater than or equal to",
      "LTE": "Less than or equal to",
      "IS_EMPTY": "Is empty",
      "IS_NOT_EMPTY": "Is not empty"
    }
  },
  "mobilePreview": {
    "placeholder": "{addMessage} to see the  content preview.",
    "addMessage": "Add message"
  },
  "composeCampaign": {
    "hero": {
      "title": "New text message",
      "subtitle": "Any changes will be saved as draft",
      "subtitleDate": "Draft saved {date}",
      "clearAll": "Clear all",
      "classic": "Switch to classic view",
      "classicMobile": "Classic view"
    },
    "to": {
      "title": "To",
      "subtitle": "Who will receive your text message?",
      "completedSubtitle": "This text message campaign will sent to {contacts}.",
      "recipients": "Send campaign to",
      "add": "Add recipients",
      "edit": "Edit recipients"
    },
    "from": {
      "title": "From",
      "subtitle": "Add your campaign recipients to enable this setting.",
      "from": "Sender settings",
      "edit": "Edit numbers",
      "completedSubtitle": "Text messages will be sent from {numbers}.",
      "numbers": "{count} number | {count} numbers"
    },
    "message": {
      "title": "Message",
      "subtitle": "What will be the text message content?",
      "completedSubtitle": "Your message includes {chars}, which results in {parts}.",
      "text": "Text message content",
      "plainChars": "{count} plain text character | {count} plain text characters",
      "unicodeChars": "{count} unicode character | {count} unicode characters",
      "parts": "{count} message part | {count} message parts",
      "add": "Add message",
      "edit": "Edit message",
      "alert": {
        "header": "Tips for better delivery rates ",
        "text": [
          "Personalize messages with tags like &#123;First name&#125; to boost engagement.",
          "Always provide a clear opt-out option, such as “Text STOP to opt out.”",
          "Use full URLs instead of shorteners for transparency and trust.",
          "Ensure all recipients have opted in to receive messages."
        ]
      }
    },
    "sendTime": {
      "title": "Send time",
      "subtitle": "When do you want to send your campaign?",
      "sent": "The campaign will be sent {how}.",
      "next": "Next send:",
      "immediately": "immediately",
      "add": "Add send time",
      "edit": "Edit send time",
      "now": {
        "title": "Send now",
        "text": "Send your text message campaign now. You will still be able to review everything."
      },
      "later": {
        "title": "Schedule for later",
        "text": "Choose a specific day and time to send in the future, or set up recurring messages."
      }
    },
    "preview": {
      "title": "Preview {index} of {total}"
    },
    "footerBar": {
      "incomplete": "Add {list} to continue.",
      "to": "recipients",
      "message": "message",
      "sendTime": "send time",
      "complete": "Estimated cost for this campaign: {cost}",
      "submit": "Send {count} message | Send {count} messages"
    }
  },
  "recipientBadgeMore": "+ {count} more",
  "rRuleTextFormatterService": {
    "hourly": {
      "interval": "{hourly} every {hours} hour | {hourly} every {hours} hours",
      "hourly": "hourly"
    },
    "daily": {
      "interval": "daily | every {count} days"
    },
    "weekly": {
      "interval": "every week | every {count} weeks",
      "weekday": "on {weekdays}"
    },
    "monthly": {
      "interval": "monthly | every {count} months",
      "monthday": "on the {day} of the month",
      "weekday": "on the {day}",
      "day": "{day} day"
    },
    "yearly": {
      "yearly": "yearly",
      "monthday": "on {day}",
      "weekday": "on the {day}",
      "value": "{pos} {day} of {month}"
    },
    "pos": {
      "1": "first",
      "2": "second",
      "3": "third",
      "4": "fourth",
      "-1": "last"
    }
  },
  "composeScheduledTextFormatterService": {
    "once": "once",
    "on": "{once} on {date}",
    "timeSuffix": " at {time}",
    "timezone": " (UTC {offset}: {timezone})"
  },
  "privacyPolicy": {
    "title": "Updated Terms of Service and Privacy Policy",
    "subtitle": "We've updated our Terms of Service and Privacy Policy",
    "line1": "To continue using Textmagic services, you need to review and accept our updated {link1} and {link2}. These updates include important changes related to AI-powered features and third-party service providers, ensuring transparency in how we manage your data and deliver our services.",
    "line2": "You need to accept the new terms to continue using your account. If you don’t, you’ll be logged out.",
    "read": "Read",
    "termsOfService": "Terms of Service",
    "privacyPolicy": "Privacy Policy",
    "button1": "Read Terms of Service",
    "button2": "Read Privacy Policy",
    "checkbox": "I have read and agree to the updated Terms of Service and Privacy Policy"
  },
  "sidebarForms": {
    "contact": {
      "title": "Contact",
      "notAdded": {
        "title": "Number is not in your contacts",
        "text": "Add the number to contacts to properly manage related data."
      },
      "isBlocked": {
        "title": "This contact is blocked",
        "text": "To be able to exchange messages with this contact, unblock it first."
      },
      "isUnsubscribed": {
        "title": "This contact is unsubscribed",
        "text": "This contact has unsubscribed from your lists. No messages can be sent to this contact."
      },
      "actions": {
        "unblockContact": "Unblock contact",
        "resubscribeContact": "Resubscribe contact"
      }
    }
  },
  "campaigns": {
    "breadcrumbs": {
      "campaigns": "Campaigns",
      "all": "All campaigns"
    },
    "renameModal": {
      "title": "Rename campaign",
      "name": {
        "label": "Campaign name",
        "placeholder": "Enter campaign name here",
        "required": "Please enter a valid campaign name."
      }
    },
    "details": {
      "hero": {
        "saveAsTemplate": "Save as template",
        "archive": "Archive"
      }
    },
    "all": {
      "title": "All campaigns",
      "sms": "SMS",
      "email": "Email"
    },
    "viewSwitcher": {
      "cardsView": "Card view",
      "tableView": "Table view"
    },
    "sent": "Sent",
    "archived": "Archived",
    "email": {
      "details": {
        "tabs": {
          "overview": "Overview",
          "messages": "Messages",
          "opens": "Opens",
          "clicks": "Clicks",
          "unsubscribers": "Unsubscribers",
          "spam": "Spam reports",
          "recipients": "Recipients"
        },
        "delivery": {
          "title": "Delivery",
          "subtitle": "{delivered} emails were successfully delivered, with {rejected} rejected and {bounced} bounced.",
          "total": {
            "title": "Sent",
            "tooltip": "Total number of emails sent."
          },
          "delivered": {
            "title": "Delivered",
            "tooltip": "Total number of emails delivered."
          },
          "rejected": {
            "title": "Rejected",
            "tooltip": "Total number of emails rejected by Textmagic."
          },
          "bounced": {
            "title": "Bounced",
            "tooltip": "Total number of emails that bounced from recipients' inboxes."
          }
        },
        "engagement": {
          "title": "Engagement",
          "subtitle": "Your email was delivered to {delivered} recipients, opened by {opened} and clicked by {clicked} recipients.",
          "opened": {
            "title": "Opened",
            "tooltip": "Total number of unique recipients who opened the email."
          },
          "clicked": {
            "title": "Clicked",
            "tooltip": "Total number of unique recipients who clicked a link in the email."
          },
          "unsubscribed": {
            "title": "Unsubscribed",
            "tooltip": "Total number of unique recipients who unsubscribed."
          },
          "spam": {
            "title": "Spam reports",
            "tooltip": "Total number of recipients who marked the email as spam."
          }
        },
        "details": {
          "title": "Details",
          "subtitle": "An overview of the campaign’s details, content and costs.",
          "from": "From",
          "reply": "“Reply to” email",
          "sendTime": "Send time",
          "cost": "Total campaign cost",
          "author": "Created by",
          "subject": "Subject",
          "id": "ID",
          "sentTo": "Sent to",
          "sendDate": "Send date"
        }
      }
    },
    "overviewLoading": {
      "title": "Loading campaign information...",
      "description": "Please wait while we are loading your information."
    },
    "scheduled": {
      "tabs": {
        "sms": "SMS",
        "email": "Email"
      },
      "table": {
        "newCampaign": "New campaign",
        "recipients": "Recipients"
      },
      "scheduled": {
        "title": "Upcoming",
        "empty": {
          "title": "No upcoming SMS campaigns",
          "description": "Plan ahead and schedule campaigns to send them at the perfect time for your audience.",
          "button": "Compose text message"
        }
      },
      "paused": {
        "title": "Paused",
        "empty": {
          "title": "No paused SMS campaigns",
          "description": "Paused campaigns will be shown here. You can pause scheduled campaigns to skip sends.",
          "button": "View upcoming campaigns"
        }
      },
      "notSent": {
        "title": "Not sent",
        "empty": {
          "title": "No failed SMS campaign send attempts",
          "description": "If any send attempts fail (e.g., due to insufficient balance), they will appear here.",
          "button": "View upcoming campaigns"
        }
      },
      "completed": {
        "title": "Completed",
        "empty": {
          "title": "No completed SMS campaigns yet",
          "description": "Campaigns appear here once all scheduled occurrences have been sent.",
          "button": "View upcoming campaigns"
        }
      },
      "details": {
        "title": "Scheduled text message details",
        "id": "Scheduled ID",
        "type": "Schedule",
        "start": "First send date",
        "end": "End date",
        "next": "Next send time",
        "from": "From",
        "to": "To",
        "messageContent": "Message content",
        "countries": "Destination countries",
        "content": "Message content",
        "encoding": "Encoding",
        "cost": "Cost per send",
        "createdBy": "Created by",
        "createdAt": "Date created",
        "defaultFrom": "Default sender settings"
      }
    }
  },
  "tmFilterLastMessageSource": {
    "label": "Last reply status",
    "options": {
      "all": "All",
      "customer": "No agent reply",
      "agent": "No customer reply"
    }
  },
  "myContactsCreatedNotification": {
    "sendSms": "Send SMS"
  },
  "sidebarAlsoHere": {
    "alsoHere": "Also here:"
  },
  "tmSidebarFormContactEmailFieldPreview": {
    "resubscribe": "Resubscribe email"
  },
  "deletedEntity": {
    "contact": "Deleted contact",
    "segment": "Deleted segment",
    "list": "Deleted list"
  }
}
